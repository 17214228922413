@charset "utf-8";
/* inter-100 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-100.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-200 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-300 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-regular - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-500 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-600 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-700 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-800 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* inter-900 - cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-100 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-100.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-100italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-100italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-200 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-200italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-200italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-300 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-300italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-regular - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-500italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-500italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-600 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-600italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-600italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-700italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-800italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-800italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-900italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/***
    The new CSS reset - version 1.8.6 (last updated 15.6.2023)
    GitHub page: https://github.com/elad2412/the-new-css-reset
***/
/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
    - The "html" element is exclude, because otherwise a bug in Chrome breaks the CSS hyphens property (https://github.com/elad2412/the-new-css-reset/issues/36)
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}
/* Preferred box-sizing value */
*,
*::before,
*::after {
  box-sizing: border-box;
}
/* Reapply the pointer cursor for anchor tags */
a,
button {
  cursor: revert;
}
/* Remove list styles (bullets/numbers) */
ol,
ul,
menu {
  list-style: none;
}
/* For images to not be able to exceed their container */
img {
  max-inline-size: 100%;
  max-block-size: 100%;
}
/* removes spacing between cells in tables */
table {
  border-collapse: collapse;
}
/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input,
textarea {
  -webkit-user-select: auto;
}
/* revert the 'white-space' property for textarea elements on Safari */
textarea {
  white-space: revert;
}
/* minimum style to allow to style meter element */
meter {
  -webkit-appearance: revert;
  appearance: revert;
}
/* preformatted text - use only for this feature */
:where(pre) {
  all: revert;
}
/* reset default text opacity of input placeholder */
::placeholder {
  color: unset;
}
/* remove default dot (•) sign */
::marker {
  content: initial;
}
/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
  display: none;
}
/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}
/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
  -webkit-user-drag: element;
}
/* Revert Modal native behavior */
:where(dialog:modal) {
  all: revert;
}
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
html,
body {
  font-family: Inter;
  text-align: left;
  color: #282828;
}
@media (min-width: 481px) {
  html,
  body {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 480px) {
  html,
  body {
    font-size: 14px;
    line-height: 22px;
  }
}
.heading-common {
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h1 {
  font-size: 40px;
  line-height: 46px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
@media (max-width: 480px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
  }
}
h2 {
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h3 {
  font-size: 28px;
  line-height: 34px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h4 {
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h5 {
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
h6 {
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  font-weight: 700;
  font-style: normal;
}
.mark {
  color: #282828;
  font-size: 14px;
  line-height: 2;
}
.underline {
  text-decoration: underline;
  font-size: 16px;
  line-height: 1.75;
  color: #282828;
}
.hint {
  font-size: 12px;
  color: #282828;
}
.bolderline {
  font-size: 14px;
  line-height: 2;
  color: #282828;
  font-weight: 700;
}
.italic {
  font-size: 14px;
  line-height: 2;
  color: #282828;
  font-style: italic;
}
.action-color {
  color: #348cf9;
}
form .form-row-halves {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  form .form-row-halves {
    flex-direction: column;
  }
  form .form-row-halves.mobile-halves {
    flex-direction: row;
  }
  form .form-row-halves.mobile-halves .form-group {
    width: calc(50% - 10px);
  }
}
@media (min-width: 481px) {
  form .form-row-halves .form-group {
    width: calc(50% - 10px);
  }
}
form .form-group {
  margin-bottom: 16px;
}
form .form-group.radio-row {
  display: flex;
  gap: 20px;
  margin-bottom: 0;
  padding: 10px 0;
}
form .form-group .input-container {
  border-radius: 6px;
}
form .form-group .input-container.multiselect > div {
  width: 100%;
}
form .form-group[validation-state='error'] label {
  color: #dc3545;
}
form .form-group[validation-state='error'] input:not(.searchBox) {
  color: #dc3545;
  border: 1px solid #dc3545;
}
form .form-group[validation-state='error'] input:not(.searchBox)::placeholder {
  color: #dc3545;
}
form .form-group[validation-state='error'] input:not(.searchBox):focus {
  border: 1px solid #dc3545;
  box-shadow: 0px 0px 0px 3px #f2d2d6;
}
form .form-group[validation-state='error'] .multiSelectContainer input.searchBox {
  color: #dc3545;
}
form .form-group[validation-state='error'] .multiSelectContainer input.searchBox::placeholder {
  color: #dc3545;
}
form .form-group[validation-state='error'] .multiSelectContainer .searchWrapper {
  border: 1px solid #dc3545;
}
form .form-group[validation-state='error'] .error-msg-input {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 480px) {
  form .form-group[validation-state='error'] .error-msg-input {
    font-size: 16px;
    line-height: 18px;
  }
}
form .form-group[validation-state='error'] .input-error-group .error-msg-input {
  margin-left: 16px;
  margin-top: 0;
  font-size: 12px;
  line-height: 20px;
}
form .form-group label {
  font-size: 12px;
  line-height: 20px;
  padding-left: 16px;
  color: #000000;
}
form .form-group label.no-padding {
  padding-left: 0;
}
form .form-group label.field-tos {
  display: flex;
  align-items: flex-start;
}
form .form-group label.field-tos a {
  text-decoration: underline;
}
@media (max-width: 480px) {
  form .form-group label.checkbox-font {
    font-size: 14px;
    line-height: 18px;
  }
}
form .form-group .error-msg-checkbox {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 480px) {
  form .form-group .error-msg-checkbox {
    font-size: 14px;
    line-height: 18px;
  }
}
form .form-group .checkbox-container.invalid-checkbox {
  border-radius: 4px;
  border: 1px solid #dc3545;
  padding: 8px 0;
}
form .form-group .input-container {
  display: flex;
  align-items: center;
}
form input.tos {
  width: 16px;
  height: 16px;
  margin: 8px;
  flex-shrink: 0;
}
form .recaptcha {
  margin-bottom: 16px;
}
form .error {
  color: #dc3545;
  padding-left: 20px;
  background: url('../public/icons/x-circle.svg') no-repeat left center;
  margin-top: 8px;
  text-align: left;
}
form .success {
  color: #28a745;
  padding-left: 20px;
  background: url('../public/icons/check-circle-green.svg') no-repeat left center;
  margin-top: 8px;
  text-align: left;
}
textarea {
  white-space: normal !important;
  overflow-wrap: break-word;
}
textarea::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
textarea::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 9px;
}
textarea::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.form-group[validation-state='error'] label {
  color: #dc3545;
}
.form-group[validation-state='error'] textarea,
.form-group[validation-state='error'] input:not(.searchBox),
.form-group[validation-state='error'] select {
  color: #dc3545;
  border: 1px solid #dc3545;
}
.form-group[validation-state='error'] textarea::placeholder,
.form-group[validation-state='error'] input:not(.searchBox)::placeholder,
.form-group[validation-state='error'] select::placeholder {
  color: #dc3545;
}
.form-group[validation-state='error'] textarea:focus,
.form-group[validation-state='error'] input:not(.searchBox):focus,
.form-group[validation-state='error'] select:focus {
  border: 1px solid #dc3545;
  box-shadow: 0px 0px 0px 3px #f2d2d6;
}
.form-group[validation-state='error'] .multiSelectContainer input.searchBox {
  color: #dc3545;
}
.form-group[validation-state='error'] .multiSelectContainer input.searchBox::placeholder {
  color: #dc3545;
}
.form-group[validation-state='error'] .multiSelectContainer .searchWrapper {
  border: 1px solid #dc3545;
}
.form-group[validation-state='error'] .error-msg-input {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 480px) {
  .form-group[validation-state='error'] .error-msg-input {
    font-size: 16px;
    line-height: 18px;
  }
}
.form-group[validation-state='error'] .input-error-group .error-msg-input {
  margin-left: 16px;
  margin-top: 0;
  font-size: 12px;
  line-height: 20px;
}
.form-group[validation-state='error'] .input-error-group .icon-select-downarrow {
  background-image: url('../public/icons/selectarrow-red.svg');
}
.form-group[validation-state='error'] .error-msg-checkbox {
  margin-top: 8px;
  color: #dc3545;
  font-size: 12px;
  line-height: 12px;
}
@media (max-width: 480px) {
  .form-group[validation-state='error'] .error-msg-checkbox {
    font-size: 14px;
    line-height: 18px;
  }
}
.form-group[validation-state='error'] .checkbox-container.invalid-checkbox {
  border-radius: 4px;
  border: 1px solid #dc3545;
  padding: 8px 0;
}
input[type='checkbox'],
input[type='radio'] {
  all: revert;
}
.form-group.radio-row fieldset {
  display: flex;
  gap: 20px;
}
.form-group.radio-row fieldset label {
  display: flex;
  align-items: center;
}
.form-group.radio-row fieldset label input[type='radio'] {
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: 0 8px;
  border: 2px solid #999;
}
.form-group.radio-row fieldset label input[type='radio']:checked {
  border: 5px solid #348cf9;
  outline: unset !important;
  /* I added this one for Edge (chromium) support */
}
.btn {
  padding: 10px 0;
  width: 100%;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}
.btn.btn-primary {
  background-color: #348cf9;
  color: #ffffff;
}
.btn.btn-primary:hover:not([disabled]) {
  background-color: #0064df;
}
.btn.btn-outline {
  border: 1px solid #000000;
  background-color: #ffffff;
}
.btn.btn-outline:hover:not([disabled]) {
  background-color: #000000;
  color: #ffffff;
}
.btn.btn-danger {
  background-color: #dc3545;
  color: #ffffff;
}
.btn.btn-danger:disabled {
  background-color: #dc3545;
  opacity: 0.6;
}
.btn.btn-outline-light {
  border-radius: 4px;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  padding: 12px 14px;
  font-weight: 400;
}
.btn.btn-transparent {
  border: 1px solid #000000;
  background-color: transparent;
}
.btn.btn-transparent:hover:not([disabled]) {
  background-color: #000000;
  color: #ffffff;
}
.btn.btn-next {
  border: 1px solid #dee2e6;
  background: url('../public/icons/arrow-right.svg') no-repeat calc(100% - 12px) center;
}
.btn.btn-link {
  color: #348cf9;
  font-weight: 400;
  padding: 10px;
}
.btn.btn-link:hover:not([disabled]) {
  text-decoration: underline;
}
.btn.btn-link.disabled,
.btn.btn-link:disabled {
  color: #76b3ff;
}
.btn.btn-link.disabled:hover,
.btn.btn-link:disabled:hover {
  text-decoration: none;
}
.btn.btn-link-light {
  color: #348cf9;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  padding: 3px 18px 3px 0;
  width: fit-content;
}
.btn.btn-link-light:hover:not([disabled]) {
  text-decoration: underline;
}
.btn.btn-link-light.disabled,
.btn.btn-link-light:disabled {
  color: #76b3ff;
}
.btn.btn-link-light.disabled:hover,
.btn.btn-link-light:disabled:hover {
  text-decoration: none;
}
.btn.btn-block {
  display: block;
}
.btn.btn-short {
  padding: 10px;
  width: auto;
  min-width: 101px;
  height: 38px;
}
.btn.btn-col-1 {
  display: block;
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
}
.btn.btn-col-2 {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
@media (max-width: 480px) {
  .btn.btn-col-2 {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.btn:disabled {
  background-color: #adb5bd;
}
.btn.disable-fade-primary {
  background-color: #348cf9;
}
.btn.disable-fade-primary:disabled {
  opacity: 0.5;
}
.btn.disable-fade-transparent {
  background-color: transparent;
}
.btn.disable-fade-transparent:disabled {
  opacity: 0.5;
}
.link {
  color: #348cf9;
  font-size: 12px;
  line-height: 12px;
}
.link:hover:not([disabled]) {
  text-decoration: underline;
}
.link.disabled,
.link:disabled {
  color: #76b3ff;
}
.link.disabled:hover,
.link:disabled:hover {
  text-decoration: none;
}
input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
select {
  height: 38px;
}
input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
textarea,
select {
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 7px 16px;
}
input[type='text'] option,
input[type='number'] option,
input[type='password'] option,
input[type='email'] option,
textarea option,
select option {
  color: #000000;
}
input[type='text'].empty,
input[type='number'].empty,
input[type='password'].empty,
input[type='email'].empty,
textarea.empty,
select.empty,
input[type='text']::placeholder,
input[type='number']::placeholder,
input[type='password']::placeholder,
input[type='email']::placeholder,
textarea::placeholder,
select::placeholder {
  color: #adb5bd;
  font-weight: 400;
}
input[type='text']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='email']:focus,
textarea:focus,
select:focus {
  border: 1px solid #000000;
  box-shadow: 0px 0px 0px 3px #b7b7b799;
}
input[type='text']:focus::placeholder,
input[type='number']:focus::placeholder,
input[type='password']:focus::placeholder,
input[type='email']:focus::placeholder,
textarea:focus::placeholder,
select:focus::placeholder {
  color: #000000;
}
input[type='text']:disabled,
input[type='number']:disabled,
input[type='password']:disabled,
input[type='email']:disabled,
textarea:disabled,
select:disabled {
  border-color: #d1d2d2;
  background-color: #e9ecef;
}
input[type='text'].forceFocusStyle,
input[type='number'].forceFocusStyle,
input[type='password'].forceFocusStyle,
input[type='email'].forceFocusStyle,
textarea.forceFocusStyle,
select.forceFocusStyle {
  border: 1px solid #000000;
  box-shadow: 0px 0px 0px 3px #b7b7b799;
}
input[type='text'].forceFocusStyle::placeholder,
input[type='number'].forceFocusStyle::placeholder,
input[type='password'].forceFocusStyle::placeholder,
input[type='email'].forceFocusStyle::placeholder,
textarea.forceFocusStyle::placeholder,
select.forceFocusStyle::placeholder {
  color: #000000;
}
textarea.form-control {
  height: 114px;
}
.form-group textarea {
  height: 114px;
}
input[type='password'].showable-password {
  padding-right: 32px;
}
.show-password-btn {
  margin-left: -32px;
  width: 16px;
  height: 16px;
}
.show-password-btn.password-visible {
  background: url('../public/icons/eye-fill.svg') no-repeat center;
}
.show-password-btn.password-hidden {
  background: url('../public/icons/eye-empty.svg') no-repeat center;
}
@media (min-width: 481px) {
  .form-row-layout.compact-rows .row .col .form-group {
    align-items: center;
  }
}
@media (min-width: 481px) {
  .form-row-layout .row {
    padding: 48px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    border-bottom: 0.5px solid #adb5bd;
    display: flex;
    justify-content: space-between;
  }
  .form-row-layout .row:last-of-type {
    border-bottom: none;
  }
  .form-row-layout .row .col .form-group {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
  .form-row-layout .row .col .form-group label {
    width: 188px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
  .form-row-layout .row .col .form-group label span {
    display: block;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
  }
  .form-row-layout .row .col .form-group .input-error-group {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
  }
  .form-row-layout .row .col .form-group .input-error-group .input-container {
    background-color: #ffffff;
    border-radius: 6px;
  }
  .form-row-layout .row .col .form-group.contains-textarea {
    align-items: flex-start;
  }
  .form-row-layout .row .col .form-group.contains-textarea .input-container {
    background: none;
  }
  .form-row-layout .row .col .form-group.contains-textarea .input-container textarea {
    background-color: #ffffff;
  }
  .form-row-layout .row .col.range-field {
    display: flex;
    align-items: center;
  }
  .form-row-layout .row .col.range-field .input-error-group {
    width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  }
  .form-row-layout .row .col.range-field .separator {
    width: 10px;
    height: 2px;
    background-color: #adb5bd;
    margin: 0 5px;
  }
  .form-row-layout .row .col.range-field label span {
    margin-top: 5px;
  }
}
@media (min-width: 481px) and (min-width: 481px) {
  .form-row-layout .row .col.range-field {
    flex-wrap: wrap;
    width: calc(188px + 20px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12));
    height: 38px;
  }
}
@media (min-width: 481px) and (min-width: 481px) {
  .form-row-layout .row .col.range-field .range-error-msg-input {
    flex-basis: 100%;
    padding-left: 224px;
  }
}
@media (max-width: 480px) {
  .form-row-layout .row .col {
    margin-top: 20px;
  }
  .form-row-layout .row .col .form-group {
    margin-bottom: 20px;
  }
  .form-row-layout .row .col .form-group label {
    padding-left: 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
  }
  .form-row-layout .row .col .form-group label span {
    display: block;
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
  }
  .form-row-layout .row .col.range-field {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr 16px;
    grid-column-gap: 0px;
    grid-row-gap: 12px;
    grid-template-areas: 'label label label' 'min separator max' 'err err err';
  }
  .form-row-layout .row .col.range-field label {
    grid-area: label;
  }
  .form-row-layout .row .col.range-field label span {
    margin-bottom: 0;
  }
  .form-row-layout .row .col.range-field .form-group {
    display: contents;
  }
  .form-row-layout .row .col.range-field .input-error-group {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .form-row-layout .row .col.range-field .form-group:first-of-type .input-error-group {
    grid-area: min;
  }
  .form-row-layout .row .col.range-field .form-group:last-of-type .input-error-group {
    grid-area: max;
  }
  .form-row-layout .row .col.range-field .range-error-msg-input {
    grid-area: err;
    padding: 0;
  }
  .form-row-layout .row .col.range-field .separator {
    grid-area: separator;
    width: 8px;
    height: 2px;
    background-color: #adb5bd;
    margin: 18px 2px;
  }
}
.input-container.icon-select-downarrow {
  background: url('../public/icons/select-downarrow.svg') #ffffff no-repeat calc(100% - 16px) center;
  background-size: 16px;
}
.multiselect.darker-placeholder input::placeholder {
  color: black;
}
.multiselect-container {
  border-radius: 6px;
}
.multiselect-container .searchWrapper {
  height: 38px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  border-radius: 6px;
  border: 1px solid #dee2e6;
}
.multiselect-container .searchWrapper .chip {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 17px 37px 17px 0px;
  height: 46px;
  position: relative;
}
.multiselect-container .searchWrapper .chip img.icon_cancel {
  position: absolute;
  top: 17px;
  right: 20px;
}
.multiselect-container input[type='text'] {
  margin-top: 0;
  padding-left: 0;
}
@media (max-width: 480px) {
  .multiselect-container input[type='text'] {
    width: 100%;
  }
}
.multiselect-container input[type='text']:focus {
  border: none;
  box-shadow: none;
}
.multiselect-container input[type='text']:focus::placeholder {
  color: #000000;
}
.multiselect-container ul.optionContainer li {
  display: flex;
  align-items: baseline;
}
.multiselect-container.disable_ms {
  background-color: #e9ecef;
  opacity: 1;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.range-field .range-error-msg-input {
  padding-left: 16px;
  font-size: 12px;
  line-height: 20px;
  color: #dc3545;
}
@media (max-width: 480px) {
  .col .form-group label {
    padding-left: 16px;
    font-size: 12px;
    line-height: 12px;
  }
}
@media (min-width: 481px) {
  header {
    height: 80px;
    padding-right: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    padding-left: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  }
}
@media (max-width: 480px) {
  header {
    height: 52px;
    padding: 0 24px;
  }
}
header .container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 481px) {
  header .container .logo {
    height: 24px;
    cursor: pointer;
  }
}
@media (max-width: 480px) {
  header .container {
    width: 100%;
  }
  header .container .logo {
    position: relative;
    height: 24px;
  }
}
@media (min-width: 481px) {
  header .mobile-menu {
    display: none;
  }
}
@media (max-width: 480px) {
  header .mobile-menu {
    display: block;
    width: 35px;
    height: 35px;
    background: url('../public/icons/hamburger.svg') no-repeat center;
  }
  header .mobile-menu.opened {
    background: url('../public/icons/hamburger-close.svg') no-repeat center;
  }
}
header .menu-flex-container {
  display: flex;
  flex-direction: row;
}
header .menu-flex-container .header-menu-button-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60px;
}
@media (max-width: 480px) {
  header .menu-flex-container {
    display: none;
  }
}
@media (min-width: 481px) {
  header .desktop-menu-btn {
    margin-right: -9.5px;
  }
}
@media (max-width: 480px) {
  header .desktop-menu-btn {
    display: none;
  }
}
header .desktop-menu-btn .desktop-menu-closer {
  background-color: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: none;
}
header .desktop-menu-btn.logged-out {
  display: none;
}
header .desktop-menu-btn.opened {
  position: relative;
}
header .desktop-menu-btn.opened .menu-container {
  display: block;
  z-index: 2;
}
header .desktop-menu-btn.opened .desktop-menu-closer {
  display: block;
}
header .desktop-menu-btn .menu-container {
  display: none;
  position: absolute;
  right: 0;
  top: 52px;
  min-width: 137px;
  box-shadow: 0px 5px 20px 0px #00000040;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #348cf9;
}
header .desktop-menu-btn .menu-container .links {
  width: 100%;
}
header .desktop-menu-btn .menu-container .links a {
  display: block;
  padding: 12px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  /* The pseudo element has the same content and hover style, so it pre-sets the width of the element and visibility: hidden hides the pseudo element from actual view. */
}
header .desktop-menu-btn .menu-container .links a.selected {
  background-color: #348cf9;
  color: #ffffff;
}
header .desktop-menu-btn .menu-container .links a.selected:hover {
  color: #ffffff;
}
header .desktop-menu-btn .menu-container .links a:hover {
  color: #348cf9;
  font-weight: 600;
}
header .desktop-menu-btn .menu-container .links a::before {
  display: block;
  content: attr(title);
  font-weight: 600;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
header .desktop-menu-btn .menu-container .links .separator {
  height: 1px;
  background-color: #7b7c7c;
  margin: 0 12px;
}
header .notification {
  padding: 16px;
  border-radius: 6px;
  top: 0;
  position: absolute;
  z-index: 5;
  color: #000000;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  background-color: #ffffff;
  align-items: flex-start;
}
header .notification .msg-icon {
  margin-right: 15px;
}
header .notification.status-success {
  border: 1px solid #28a745;
}
header .notification.status-error {
  border: 1px solid #dc3545;
}
header .notification.status-warning {
  border: 1px solid #ffd041;
}
header .notification.status-info {
  border: 1px solid #28bbfa;
}
@media (min-width: 481px) {
  header .notification {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 480px) {
  header .notification {
    position: fixed;
    width: calc(100dvw - 2 * 24px);
    top: 10px;
  }
}
header .notification.fadeout {
  top: -200px;
  transition: top 0.5s ease-in-out;
}
.app-wrapper {
  background-color: #000000 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 481px) {
  .app-wrapper {
    min-height: 100vh;
  }
}
@media (max-width: 480px) {
  .app-wrapper {
    min-height: 100dvh;
    display: flex;
  }
}
.app-wrapper header {
  flex-shrink: 0;
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background: #ffffff;
}
.app-wrapper .app-body {
  background-color: #ffffff;
  flex-grow: 1;
}
@media (min-width: 481px) {
  .app-wrapper .app-body {
    padding-top: 80px;
  }
}
.app-wrapper .app-body.hidden {
  display: none;
}
.app-wrapper footer {
  flex-shrink: 0;
}
footer {
  background-color: #000000;
  color: #ffffff;
  display: flex;
  align-items: center;
}
@media (min-width: 481px) {
  footer {
    height: 80px;
    padding: 0 40px;
  }
}
@media (max-width: 480px) {
  footer.logged-out {
    height: 44px;
  }
  footer.logged-out .container .logo {
    display: none;
  }
  footer.logged-out .container .links a {
    display: none;
  }
  footer.logged-out .container .links .copyright {
    padding: 0;
  }
  footer.logged-in {
    height: 220px;
  }
  footer.hidden {
    display: none;
  }
}
@media (max-width: 1200px) {
  footer.hidden-mobile-1200 {
    display: none;
  }
}
footer .container {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 481px) {
  footer .container {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 10 + 20px * 9);
    margin: 0 auto;
    align-items: center;
  }
}
@media (max-width: 480px) {
  footer .container {
    padding: 16px 24px;
    height: 100%;
    width: 100%;
    margin: 0;
    flex-direction: column;
    gap: 30px;
  }
  footer .container .links {
    flex-direction: column;
    gap: 16px;
    line-height: 12px;
  }
  footer .container .links .copyright {
    text-align: center;
    padding-top: 14px;
  }
}
footer .logo {
  font-family: Montserrat;
  font-size: 20px;
}
footer .logo strong {
  font-weight: 700;
}
footer .links {
  display: flex;
  gap: 40px;
  font-size: 12px;
}
footer .links a,
footer .links a:visited {
  color: #ffffff;
}
footer .links a:hover:not([disabled]),
footer .links a:visited:hover:not([disabled]) {
  text-decoration: underline;
}
footer .links a .disabled,
footer .links a:visited .disabled,
footer .links a:disabled,
footer .links a:visited:disabled {
  color: #76b3ff;
}
* {
  overscroll-behavior: contain;
}
html {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
  background-color: #ffffff;
}
html body {
  margin: 0;
  background-color: #ffffff;
}
html body .react-tooltip {
  white-space: pre-wrap;
  border: 1px solid #000000;
  border-radius: 4px;
}
@media (min-width: 481px) {
  html body .react-tooltip {
    max-width: calc(100vw - 2 * 40px);
  }
}
@media (max-width: 480px) {
  html body .react-tooltip {
    max-width: calc(100dvw - 2 * 24px);
  }
}
html body .noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
html body .header-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  border: 0.5rem solid #ebe6e6;
}
html body .header-wrapper a {
  font-size: 1.5rem;
  margin-right: 1rem;
}
html body .footer-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 0.5rem solid #e04c2b;
  justify-content: center;
  align-items: center;
}
html body .login-wrapper {
  margin-top: 134px;
}
@media (max-width: 480px) {
  html body .login-wrapper {
    margin-top: 0;
  }
}
html body .login-wrapper .link {
  display: block;
  margin: 16px 0 24px 16px;
}
@media (max-width: 480px) {
  html body .login-wrapper .link {
    margin: 16px 0 24px 0;
  }
}
html body .investor-dashboard-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 0.5rem solid #ace6a8;
  justify-content: center;
  align-items: center;
}
html body .counter-wrapper {
  background-color: #ffffff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 0.5rem solid #ace6a8;
  justify-content: center;
  align-items: center;
}
.signup-layout-initial-form {
  flex-grow: 1;
  display: flex;
  gap: 20px;
  padding: 0 40px 51px 40px;
  justify-content: center;
}
@media (max-width: 480px) {
  .signup-layout-initial-form {
    display: block;
    padding: 0 24px 24px;
  }
}
@media (min-width: 481px) {
  .signup-layout-initial-form .left-item {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 6 + 20px * 5);
    display: flex;
    align-items: center;
  }
}
@media (min-width: 481px) {
  .signup-layout-initial-form .left-item h1 {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 5 + 20px * 4);
    margin-bottom: 48px;
    font-size: 64px;
    line-height: 70px;
  }
}
@media (max-width: 480px) {
  .signup-layout-initial-form .left-item h1 {
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 38px;
  }
}
.signup-layout-initial-form .left-item h2 {
  color: #348cf9;
}
@media (min-width: 481px) {
  .signup-layout-initial-form .left-item h2 {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
    font-size: 32px;
    line-height: 38px;
  }
}
@media (max-width: 480px) {
  .signup-layout-initial-form .left-item h2 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .signup-layout-initial-form .left-item img {
    display: none;
  }
}
.signup-layout-initial-form .middle-item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
}
.signup-layout-initial-form .middle-item .divider {
  height: 100%;
  width: 1px;
  margin: 0 auto;
  border: 0.5px solid #adb5bd;
}
@media (max-width: 480px) {
  .signup-layout-initial-form .middle-item {
    display: none;
  }
}
@media (min-width: 481px) {
  .signup-layout-initial-form .right-item {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .signup-layout-initial-form .right-item .mandatory-text-mobile {
    display: none;
  }
}
@media (max-width: 480px) {
  .signup-layout-initial-form .right-item .mandatory-text-desktop {
    display: none;
  }
  .signup-layout-initial-form .right-item .mandatory-text-mobile {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 20px;
  }
}
.signup-layout-initial-form .right-item .mandatory-text-desktop {
  font-size: 12px;
  line-height: 20px;
  margin-top: 16px;
}
.page-login-layout-forgotten-password,
.page-signup-layout-confirm-code {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password,
  .page-signup-layout-confirm-code {
    height: calc(100dvh - 200px);
  }
}
.page-login-layout-forgotten-password .mobile-app-refresher,
.page-signup-layout-confirm-code .mobile-app-refresher {
  align-self: flex-start;
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password,
  .page-signup-layout-confirm-code {
    display: flex;
    justify-content: unset;
    padding: 0 24px;
  }
}
.page-login-layout-forgotten-password form,
.page-signup-layout-confirm-code form {
  width: 584px;
  box-shadow: 0px 10px 40px 0px #00000026;
  padding: 60px;
  text-align: center;
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password form,
  .page-signup-layout-confirm-code form {
    align-self: center;
    flex-grow: 1;
    box-shadow: none;
    padding: 0;
    height: 312px;
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.page-login-layout-forgotten-password form h4,
.page-signup-layout-confirm-code form h4 {
  margin-bottom: 24px;
  text-align: center;
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password form h4,
  .page-signup-layout-confirm-code form h4 {
    line-height: 24px;
  }
}
.page-login-layout-forgotten-password form .check-text,
.page-signup-layout-confirm-code form .check-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  color: #000000;
  font-weight: 500;
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password form .check-text,
  .page-signup-layout-confirm-code form .check-text {
    margin-bottom: 48px;
  }
}
.page-login-layout-forgotten-password form .check-text .icon-check-circle,
.page-signup-layout-confirm-code form .check-text .icon-check-circle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background: url('../public/icons/check-circle.svg') no-repeat center;
}
.page-login-layout-forgotten-password form .form-group,
.page-signup-layout-confirm-code form .form-group {
  text-align: left;
  margin-bottom: 12px;
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password form .form-group,
  .page-signup-layout-confirm-code form .form-group {
    text-align: left;
  }
}
.page-login-layout-forgotten-password form .form-group label,
.page-signup-layout-confirm-code form .form-group label {
  display: block;
  padding-bottom: 8px;
}
.page-login-layout-forgotten-password form .form-group .verify-input,
.page-signup-layout-confirm-code form .form-group .verify-input {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.page-login-layout-forgotten-password form .form-group .verify-input::placeholder,
.page-signup-layout-confirm-code form .form-group .verify-input::placeholder {
  text-align: left;
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password form .form-group .verify-input,
  .page-signup-layout-confirm-code form .form-group .verify-input {
    font-size: 16px;
  }
}
.page-login-layout-forgotten-password form .subtext,
.page-signup-layout-confirm-code form .subtext {
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password form .subtext,
  .page-signup-layout-confirm-code form .subtext {
    margin-bottom: 16px;
  }
}
.page-login-layout-forgotten-password form .resend,
.page-signup-layout-confirm-code form .resend {
  text-align: center;
}
.page-login-layout-forgotten-password form .resend a,
.page-signup-layout-confirm-code form .resend a,
.page-login-layout-forgotten-password form .resend a:visited,
.page-signup-layout-confirm-code form .resend a:visited {
  color: #000000;
  text-decoration: underline;
  font-size: 12px;
  line-height: 12px;
}
.page-login-layout-forgotten-password form .btn,
.page-signup-layout-confirm-code form .btn {
  width: 222px;
  margin-top: 32px;
}
@media (min-width: 481px) {
  .page-login-layout-forgotten-password form .btn,
  .page-signup-layout-confirm-code form .btn {
    display: inline-block;
  }
}
@media (max-width: 480px) {
  .page-login-layout-forgotten-password form .btn,
  .page-signup-layout-confirm-code form .btn {
    width: 100%;
    margin-bottom: 12px;
  }
}
@media (min-width: 481px) {
  .login-layout-forgotten-password h4,
  .login-layout-forgotten-password .check-text {
    width: 369px;
    margin: 0 auto;
  }
}
.login-layout-forgotten-password form.step-3 {
  width: 593px;
  padding: 60px 32px;
}
.login-layout-forgotten-password form.step-3 h4 {
  line-height: 24px;
  width: fit-content;
}
@media (max-width: 480px) {
  .login-layout-forgotten-password form.step-3 h4 {
    line-height: 30px;
    margin-bottom: 32px;
  }
}
.login-layout-forgotten-password form.step-3 .check-text {
  line-height: 16px;
}
.login-layout-forgotten-password form.step-3 .btn {
  margin: 32px auto 0;
}
.app-body.page-signup-layout-success {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-body.page-signup-layout-success .mobile-app-refresher {
  align-self: flex-start;
}
@media (max-width: 480px) {
  .app-body.page-signup-layout-success {
    display: flex;
    justify-content: unset;
    padding: 0 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-signup-layout-success .title-container {
    align-self: center;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
@media (min-width: 481px) {
  .app-body.page-signup-layout-success h1 {
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .app-body.page-signup-layout-success h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 32px;
  }
}
.app-body.page-signup-layout-success h2 {
  font-weight: 400;
}
@media (min-width: 481px) {
  .app-body.page-signup-layout-success h2 {
    font-size: 24px;
    line-height: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-signup-layout-success h2 {
    font-size: 20px;
    line-height: 26px;
  }
}
.app-body.page-undefined {
  padding-top: 0;
}
@media (max-width: 480px) {
  .app-body.page-undefined {
    padding: 0;
  }
}
.app-body.page-app-error {
  padding-top: 0;
  display: flex;
  justify-content: unset;
}
@media (max-width: 480px) {
  .app-body.page-app-error {
    padding: 0;
  }
}
.app-body.page-app-error .mobile-app-refresher {
  align-self: flex-start;
}
.page-login-layout-verification-progress.app-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-login-layout-verification-progress.app-body .mobile-app-refresher {
  align-self: flex-start;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body {
    display: flex;
    justify-content: unset;
  }
}
.page-login-layout-verification-progress.app-body .container-verification {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 8 + 20px * 7);
  padding: 60px 121px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification {
    width: 100%;
    padding: 0 24px;
    box-shadow: none;
    align-self: center;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .verification-header {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3);
  margin: 0 auto;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification .verification-header {
    width: 100%;
  }
}
.page-login-layout-verification-progress.app-body .container-verification h4 {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification h4 {
    line-height: 30px;
    margin-bottom: 32px;
  }
}
.page-login-layout-verification-progress.app-body .container-verification h6 {
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification h6 {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 20px;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps {
  display: flex;
  gap: 20px;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps {
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps .step {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step .visual {
  width: 100%;
  height: 8px;
  border-radius: 12px;
  margin-bottom: 12px;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps .step .visual {
    height: 100%;
    width: 8px;
    margin: 0;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step .text {
  width: fit-content;
  margin: 0 auto;
  height: 24px;
  padding-left: 36px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification .steps .step .text {
    margin: 0;
  }
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.complete .visual {
  background: #348cf9;
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.complete .text {
  color: #000000;
  background-image: url('../public/icons/check-circle-blue.svg');
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.awaiting .visual {
  background: #dee2e6;
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.awaiting .text {
  color: #000000;
  background-image: url('../public/icons/hourglass-black.svg');
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.unavailable .visual {
  background: #dee2e6;
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.unavailable .text {
  color: #adb5bd;
  background-image: url('../public/icons/loading-gray.svg');
}
.page-login-layout-verification-progress.app-body .container-verification .steps .step.step3.awaiting .text {
  height: fit-content;
  line-height: 14px;
}
.page-login-layout-verification-progress.app-body .container-verification .info {
  margin-top: 32px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .page-login-layout-verification-progress.app-body .container-verification .info {
    margin-top: 32px;
  }
}
.fake-btn {
  cursor: pointer;
  padding: 4px;
  border: 1px solid black;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
}
.linkedin-wrapper {
  padding: 6px;
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 6px;
}
.linkedin-wrapper a {
  width: 100%;
  display: block;
  text-align: center;
}
.linkedin-wrapper a:hover {
  text-decoration: none;
}
.typography-demo .section-divider {
  border-bottom: 1px solid black;
}
.typography-demo .colors-row .color-cell {
  display: inline-block;
  width: 170px;
  height: 60px;
  margin-right: 20px;
}
.typography-demo .colors-row .color-cell .color-box {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  border-radius: 4px;
}
.typography-demo .colors-row .color-cell .color-box.primary-black {
  background-color: #000000;
}
.typography-demo .colors-row .color-cell .color-box.action-blue {
  background-color: #348cf9;
}
.typography-demo .colors-row .color-cell .color-box.gray600 {
  background-color: #7b7c7c;
}
.typography-demo .colors-row .color-cell .color-box.gray500 {
  background-color: #adb5bd;
}
.typography-demo .colors-row .color-cell .color-box.gray400 {
  background-color: #d1d2d2;
}
.typography-demo .colors-row .color-cell .color-box.gray300 {
  background-color: #dee2e6;
}
.typography-demo .colors-row .color-cell .color-box.error {
  background-color: #dc3545;
}
.typography-demo .colors-row .color-cell .color-box.success {
  background-color: #28a745;
}
.typography-demo .colors-row .color-cell .color-box.warning {
  background-color: #ffd041;
}
.typography-demo .colors-row .color-cell .color-box.info {
  background-color: #28bbfa;
}
.typography-demo .colors-row .color-cell .color-box.pink100 {
  background-color: #f2d2d6;
}
nav {
  display: none;
}
nav.opened {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
}
nav.opened ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
nav.opened ul li {
  height: 48px;
  display: block;
  width: 100%;
  padding: 0 24px;
  position: relative;
  background: url('../public/icons/arrow-right.svg') no-repeat calc(100% - 30px) center;
}
nav.opened ul li.hidden {
  display: none;
}
nav.opened ul li.disabled {
  color: #adb5bd;
}
nav.opened ul li .react-tooltip {
  z-index: 1;
  margin-top: -16px;
  border: 1px solid #adb5bd;
}
nav.opened ul li a,
nav.opened ul li a:visited,
nav.opened ul li .pseudo-link {
  font-size: 16px;
  line-height: 48px;
  font-weight: 600;
  width: 100%;
  height: 100;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
nav.opened ul li a .notifications-count,
nav.opened ul li a:visited .notifications-count,
nav.opened ul li .pseudo-link .notifications-count {
  margin-right: 30px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 10px;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  color: #ffffff;
}
nav.opened ul li a .notifications-count.error,
nav.opened ul li a:visited .notifications-count.error,
nav.opened ul li .pseudo-link .notifications-count.error {
  background-color: #dc3545;
}
nav.opened ul li.selected:not(nav.opened ul li.selected.error) {
  color: #ffffff;
  background: url('../public/icons/arrow-right-white.svg') #348cf9 no-repeat calc(100% - 30px) center;
}
nav.opened ul li.selected:not(nav.opened ul li.selected.error) a .notifications-count {
  color: #348cf9;
  background-color: #ffffff;
}
nav.opened .logout a {
  display: block;
  margin: 24px 0 40px 24px;
  padding-left: 32px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  background: url('../public/icons/box-arrow-right.svg') no-repeat left center;
}
nav.opened .confirm-logout {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: unset;
  flex-grow: 1;
  padding: 0 24px 24px 24px;
  gap: 40px;
}
nav.opened .confirm-logout .mobile-app-refresher {
  align-self: flex-start;
}
nav.opened .confirm-logout .confirmation-dialog-wrap {
  flex-grow: 1;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
nav.opened .confirm-logout .confirmation-dialog-wrap .text {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 24px;
}
nav.opened .confirm-logout .confirmation-dialog-wrap .actions {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
nav.opened .confirm-logout .confirmation-dialog-wrap .actions a {
  display: block;
}
.mobile-footer {
  flex-shrink: 0;
  width: 100%;
  height: 44px;
  color: #ffffff;
  background-color: #000000;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 481px) {
  .mobile-footer {
    display: none;
  }
}
.text-divider {
  display: flex;
  align-items: center;
  gap: 13px;
  margin: 24px 0;
}
.text-divider::before,
.text-divider::after {
  content: '';
  height: 1px;
  background-color: silver;
  flex-grow: 1;
}
.nav-footer-waitlist {
  display: none;
}
@media (max-width: 480px) {
  .only-desktop {
    display: none;
  }
}
@media (min-width: 481px) {
  .only-mobile {
    display: none;
  }
}
@media (min-width: 481px) {
  .loader-container {
    height: 100vh;
  }
}
@media (max-width: 480px) {
  .loader-container {
    height: 100dvh;
  }
}
.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background-color: #ffffff;
}
.loader-wrapper .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.pre--ptr {
  background-color: #ffffff;
}
.mobile-app-refresher {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  background-color: #ffffff;
}
.mobile-app-refresher.no-header {
  margin-top: -52px;
}
@media (min-width: 481px) {
  .mobile-app-refresher {
    display: none;
  }
}
.force-default-cursor {
  cursor: auto !important;
}
.static-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-right: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  padding-left: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
}
@media (max-width: 480px) {
  .static-container {
    padding: 0px 24px 40px 24px;
  }
}
.static-container h1 {
  margin: 44px 0 48px 0;
  font-size: 40px;
  line-height: 40px;
}
@media (max-width: 480px) {
  .static-container h1 {
    margin: 32px 0;
    font-size: 32px;
    line-height: 32px;
  }
}
.static-container p {
  max-width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 7 + 20px * 6);
  margin-bottom: 24px;
}
@media (max-width: 480px) {
  .static-container p {
    max-width: 100%;
  }
}
.static-container p strong {
  font-weight: 700;
}
.static-container.page-tos a {
  overflow-wrap: anywhere;
}
@media (min-width: 481px) {
  .static-container.page-tos {
    padding-right: calc(40px + calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3));
  }
}
.static-container.page-tos h1 {
  margin: 40px 0 48px 0;
}
@media (max-width: 480px) {
  .static-container.page-tos h1 {
    margin: 32px 0;
  }
}
.static-container.page-tos .content-header {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 24px;
}
.static-container.page-tos .content-body {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 24px;
}
.static-container.page-tos .content-body strong {
  font-weight: 700;
}
.static-container.page-tos .content-body p {
  margin: 0;
}
.static-container.page-tos .content-body p.section {
  margin: 24px 0 12px;
}
.static-container.page-tos .content-body .content-large {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}
.static-container.page-tos ul {
  list-style: disc;
  list-style-position: outside;
  padding-left: 20px;
}
.static-container.page-tos a {
  text-decoration: underline;
  margin: 0 4px;
}
.static-container.page-tos .content-footer {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 48px;
}
@media (max-width: 480px) {
  .static-container.page-tos .content-footer {
    margin-bottom: 32px;
  }
}
.static-container.page-tos ol {
  list-style: decimal;
  list-style-position: outside;
  padding-left: 20px;
}
@media (max-width: 480px) {
  .signup-layout-initial-form .form-footer {
    bottom: 0;
    position: fixed;
    width: 100vw;
    margin-left: -24px;
    padding: 24px;
    box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    z-index: 2;
  }
  .signup-layout-initial-form .form-footer .mandatory-text-mobile {
    display: none;
  }
  .signup-layout-initial-form .form-footer.mobile-hidden {
    display: none;
  }
}
.app-body.page-company-profile {
  padding-bottom: 40px;
}
@media (max-width: 480px) {
  .app-body.page-company-profile {
    padding: 0px 24px 86px 24px;
  }
}
@media (min-width: 481px) {
  .app-body.page-company-profile form {
    padding-top: 1px;
  }
}
.app-body.page-company-profile .form-header {
  background-color: #f4f5f7;
  border-radius: 16px;
  padding: 36px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  margin-bottom: 20px;
  margin-top: 24px;
}
@media (min-width: 481px) {
  .app-body.page-company-profile .form-header {
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-company-profile .form-header {
    border-radius: 4px;
    padding: 16px;
  }
}
.app-body.page-company-profile .form-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-body.page-company-profile .form-header .title {
  color: #000000;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}
@media (max-width: 480px) {
  .app-body.page-company-profile .form-header .title {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 0;
  }
}
.app-body.page-company-profile .form-header .subtitle {
  color: #000000;
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 480px) {
  .app-body.page-company-profile .form-header .subtitle {
    font-size: 16px;
    line-height: 22px;
  }
}
.app-body.page-company-profile .form-header .actions {
  display: flex;
  gap: 20px;
}
@media (max-width: 480px) {
  .app-body.page-company-profile .form-header .actions {
    display: none;
  }
}
.app-body.page-company-profile .form-body {
  background-color: #f4f5f7;
  border-radius: 16px;
}
@media (max-width: 480px) {
  .app-body.page-company-profile .form-body {
    background-color: #ffffff;
    border-radius: 0;
    padding: 0;
  }
}
.app-body.page-company-profile .form-body label {
  padding: 0;
}
.app-body.page-company-profile .form-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 24px;
  background: #ffffff;
  display: flex;
  gap: 12px;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
}
@media (min-width: 481px) {
  .app-body.page-company-profile .form-footer {
    display: none;
  }
}
.app-body.page-company-profile .progress-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 3;
}
@media (min-width: 481px) {
  .app-body.page-company-profile .progress-container {
    width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3);
    position: fixed;
    top: 36px;
    left: 50vw;
    transform: translateX(-50%);
  }
}
.app-body.page-company-profile .progress-container .bar-container {
  display: flex;
}
.app-body.page-company-profile .progress-container .bar-container .bar {
  width: 50%;
  height: 4px;
}
.app-body.page-company-profile .progress-container .bar-container .bar:first-of-type {
  border-radius: 12px 0 0 12px;
}
.app-body.page-company-profile .progress-container .bar-container .bar:last-of-type {
  border-radius: 0 12px 12px 0;
}
.app-body.page-company-profile .progress-container .bar-container .bar.blue {
  background-color: #348cf9;
}
.app-body.page-company-profile .progress-container .bar-container .bar.gray {
  background-color: #dee2e6;
}
.app-body.page-company-profile .progress-container .icon-text {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 auto;
}
.app-body.page-company-profile .overlay {
  position: fixed;
  top: -200px;
  bottom: -200px;
  left: -200px;
  right: -200px;
  padding: 200px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.app-body.page-company-profile .overlay .container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
}
@media (max-width: 480px) {
  .app-body.page-company-profile .overlay .container {
    margin: 0 24px;
  }
}
@media (min-width: 481px) {
  .app-body.page-company-profile .overlay .container {
    width: 557px;
  }
}
.app-body.page-company-profile .overlay .container .icon-text {
  display: flex;
  align-items: center;
  gap: 12px;
}
.app-body.page-company-profile .overlay .container .header {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.app-body.page-company-profile .overlay .container .body p {
  padding: 0 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.app-body.page-company-profile .overlay .container .body .demo .progress {
  width: 100%;
  height: 4px;
  margin-bottom: 16px;
  background-color: #348cf9;
  border-radius: 12px;
}
.app-body.page-company-profile .overlay .container .body .demo .icon-text {
  justify-content: center;
}
.app-body.page-company-profile .overlay .container .footer {
  text-align: center;
}
.app-body.page-company-profile .overlay .container .footer button {
  width: 96px;
}
.app-body.page-search-form {
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 480px) {
  .app-body.page-search-form {
    padding: 0 0 40px 0;
  }
}
.app-body.page-search-form.component-general {
  background: url('../public/images/search-form-header.png') no-repeat center top;
  background-attachment: local;
  background-color: #ffffff;
}
.app-body.page-search-form .search-container {
  margin: 0 auto;
  max-width: 950px;
  padding-top: 1px;
}
@media (min-width: 481px) {
  .app-body.page-search-form .search-container.by-default {
    width: -moz-available;
    /* For Firefox */
    width: -webkit-fill-available;
    /* For WebKit-based browsers like Chrome, Safari, and Edge */
    width: stretch;
    /* Updated and preferred standard */
  }
}
@media (min-width: 481px) {
  .app-body.page-search-form .search-container.by-name {
    margin-top: 72px;
    width: 100%;
    /* Fallback for browsers that don't support `-webkit-fill-available` */
    width: -moz-available;
    /* For Firefox */
    width: -webkit-fill-available;
    /* For WebKit-based browsers like Chrome, Safari, and Edge */
    width: stretch;
    /* Updated and preferred standard */
  }
  .app-body.page-search-form .search-container.by-name .fields-area {
    width: 100%;
    /* Fallback for browsers that don't support `-webkit-fill-available` */
    width: -moz-available;
    /* For Firefox */
    width: -webkit-fill-available;
    /* For WebKit-based browsers like Chrome, Safari, and Edge */
    width: stretch;
    /* Updated and preferred standard */
  }
  .app-body.page-search-form .search-container.by-name .fields-area .row .form-group {
    width: 62%;
  }
  .app-body.page-search-form .search-container.by-name .separator.profiler.by-name {
    margin: 12px 24px 12px 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container.by-name {
    margin-top: 32px;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-form .search-container.by-bulk {
    margin-top: 72px;
    width: 100%;
    /* Fallback for browsers that don't support `-webkit-fill-available` */
    width: -moz-available;
    /* For Firefox */
    width: -webkit-fill-available;
    /* For WebKit-based browsers like Chrome, Safari, and Edge */
    width: stretch;
    /* Updated and preferred standard */
  }
  .app-body.page-search-form .search-container.by-bulk .fields-area {
    width: 100%;
    /* Fallback for browsers that don't support `-webkit-fill-available` */
    width: -moz-available;
    /* For Firefox */
    width: -webkit-fill-available;
    /* For WebKit-based browsers like Chrome, Safari, and Edge */
    width: stretch;
    /* Updated and preferred standard */
  }
  .app-body.page-search-form .search-container.by-bulk .separator.profiler.by-bulk {
    margin: 12px 24px 12px 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container.by-bulk {
    margin-top: 32px;
  }
}
.app-body.page-search-form .search-container.by-bulk .buttons-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 64px;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container.by-bulk .buttons-row {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 14px;
    margin-bottom: 16px;
  }
}
.app-body.page-search-form .search-container.by-bulk .buttons-row .new-icon-button-wrap {
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}
.app-body.page-search-form .search-container.by-bulk .buttons-row .new-icon-button-wrap .outer-text {
  margin-top: 0;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container {
    margin: 0;
    padding: 0 24px;
  }
}
.app-body.page-search-form .search-container h1 {
  color: #ffffff;
  margin: 60px 0 50px 0;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container h1 {
    margin: 48px 0;
  }
}
.app-body.page-search-form .search-container h2 {
  line-height: 24px;
}
.app-body.page-search-form .search-container .search-fields-container {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 10px 40px 0px #00000026;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container .search-fields-container {
    padding: 32px;
  }
}
.app-body.page-search-form .search-container .onboarding-container {
  margin-top: 80px;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container .onboarding-container {
    margin-top: 64px;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 20px;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container {
    flex-direction: column;
    gap: 24px;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 10px 30px 0px #00000026;
}
@media (min-width: 481px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container .card.empty {
    opacity: 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container .card.empty {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container .card {
    width: 100%;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .icon-onboarding {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-1 {
  background: url('../public/icons/person-plus.svg') #e9ecef no-repeat center center;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-2 {
  background: url('../public/icons/person-bounding-box.svg') #e9ecef no-repeat center center;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .badge.info {
  background-color: #76b3ff;
  color: #ffffff;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .heading .badge.success {
  color: #28a745;
  background-color: #c1edd9;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .body h6 {
  line-height: 24px;
  margin-bottom: 12px;
  font-weight: 600;
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .body h6.title-link {
  cursor: pointer !important;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container .onboarding-container .cards-container .card .body h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }
}
.app-body.page-search-form .search-container .onboarding-container .cards-container .card .body p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}
.app-body.page-search-form .search-container .review-container {
  margin-top: 80px;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container .review-container {
    margin-top: 64px;
  }
}
.app-body.page-search-form .search-container .faq-container {
  margin-top: 80px;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container .faq-container {
    margin-top: 64px;
  }
}
.app-body.page-search-form .search-container form.desktop {
  display: flex;
  align-items: stretch;
}
@media (max-width: 480px) {
  .app-body.page-search-form .search-container form.desktop {
    display: none;
  }
}
.app-body.page-search-form .search-container form.desktop .fields-area-title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}
.app-body.page-search-form .search-container form.desktop .fields-area-title.with-border-bottom {
  border-bottom: 0.5px solid #adb5bd;
  padding-bottom: 24px;
}
.app-body.page-search-form .search-container form.desktop .fields-area-mandatory-row {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.app-body.page-search-form .search-container form.desktop .search-type-selector {
  padding-bottom: 20px;
  border-bottom: 0.5px solid #adb5bd;
  margin-bottom: 20px;
}
.app-body.page-search-form .search-container form.desktop .search-type-selector label {
  padding-left: 0;
}
.app-body.page-search-form .search-container form.desktop .separator {
  background-color: #adb5bd;
  width: 0.5px;
  margin: 98px 24px 44px 24px;
}
.app-body.page-search-form .search-container form.desktop .separator.profiler {
  background-color: #adb5bd;
  margin: 12px 24px 44px 24px;
}
.app-body.page-search-form .search-container form.desktop .submit-area {
  flex: 1 0 145px;
  display: flex;
  align-items: center;
}
.app-body.page-search-form .search-container form.desktop .submit-area button {
  margin-top: 20px;
}
.app-body.page-search-form .search-container form.desktop .row {
  display: flex;
  height: 54px;
  margin-bottom: 20px;
  gap: 20px;
}
.app-body.page-search-form .search-container form.desktop .row:last-of-type {
  margin-bottom: 0;
}
.app-body.page-search-form .search-container form.desktop .row .col {
  flex: 1 1 0px;
  min-width: 28%;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group {
  display: flex;
  gap: 4px;
  align-items: flex-start;
  flex-direction: column;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group label {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group label span {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group .input-error-group {
  width: 100%;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group .input-error-group .input-container {
  background-color: #ffffff;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group .input-error-group .input-container .search-wrapper {
  max-width: 222px;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group.contains-textarea {
  align-items: flex-start;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group.contains-textarea .input-container {
  background: none;
}
.app-body.page-search-form .search-container form.desktop .row .col .form-group.contains-textarea .input-container textarea {
  background-color: #ffffff;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 16px 1fr;
  grid-column-gap: 0px;
  grid-template-areas: 'label label label' 'min separator max' 'err err err';
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field label {
  grid-area: label;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .input-error-group {
  width: 90px;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #adb5bd;
  margin: auto;
}
.app-body.page-search-form .search-container form.desktop .row .col.range-field .range-error-msg-input {
  grid-area: err;
}
@media (min-width: 481px) {
  .app-body.page-search-form .search-container form.mobile {
    display: none;
  }
}
.app-body.page-search-form .search-container form.mobile .search-form-header {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 0.5px solid #adb5bd;
}
.app-body.page-search-form .search-container form.mobile .range-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 16px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 4px;
  grid-template-areas: 'label label label' 'min separator max' 'err err err';
  margin-bottom: 16px;
}
.app-body.page-search-form .search-container form.mobile .range-field label {
  grid-area: label;
}
.app-body.page-search-form .search-container form.mobile .range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-form .search-container form.mobile .range-field .form-group {
  display: contents;
}
.app-body.page-search-form .search-container form.mobile .range-field .input-error-group {
  width: calc((100dvw - 24px - 24px - 32px - 32px - 18px) / 2);
}
.app-body.page-search-form .search-container form.mobile .range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-form .search-container form.mobile .range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-form .search-container form.mobile .range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #adb5bd;
  margin: auto;
}
.app-body.page-search-form .search-container form.mobile .range-field .range-error-msg-input {
  grid-area: err;
}
.app-body.page-search-form .search-container form.mobile .btn-next {
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 38px;
  padding: 0 16px;
  text-align: left;
  font-weight: 400;
}
.app-body.page-search-form .search-container form.mobile .btn-next.error {
  border: 1px solid #dc3545;
  box-shadow: 0px 0px 0px 3px #dc354533;
}
.app-body.page-search-form .backlink {
  width: 100%;
  display: block;
  height: 24px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  padding-left: 36px;
  padding-top: 15px;
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-search-form .backlink {
    width: fit-content;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-form .backlink {
    width: fit-content;
    padding-right: 10px;
    margin-left: 24px;
  }
}
.app-body.page-search-form .mobile-fields {
  padding-left: 24px;
  padding-right: 24px;
}
.app-body.page-search-form .mobile-fields .col {
  margin-bottom: 20px;
}
.app-body.page-search-form .mobile-fields .col.range-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 14px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 4px;
  grid-template-areas: 'label label label' 'min separator max' 'err err err';
}
.app-body.page-search-form .mobile-fields .col.range-field label {
  grid-area: label;
}
.app-body.page-search-form .mobile-fields .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-form .mobile-fields .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-form .mobile-fields .col.range-field .input-error-group {
  width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
}
.app-body.page-search-form .mobile-fields .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-form .mobile-fields .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-form .mobile-fields .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #adb5bd;
  margin: 18px 2px;
}
.app-body.page-search-form .mobile-fields .col.range-field .range-error-msg-input {
  grid-area: err;
}
.app-body.page-search-form .mobile-fields-mandatory-row {
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.app-body.page-investor-default {
  margin-left: 0;
  margin-right: 0;
  padding: 80px 0px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default {
    padding: 0px 0px 40px 0px;
  }
}
.app-body.page-investor-default h1 {
  color: #ffffff;
}
@media (max-width: 480px) {
  .app-body.page-investor-default h1 {
    font-size: 28px;
    line-height: 34px;
  }
}
.app-body.page-investor-default h2 {
  line-height: 24px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default h2 {
    font-size: 28px;
    line-height: 24px;
  }
}
.app-body.page-investor-default .investor-cover {
  display: flex;
  align-items: center;
  height: 220px;
  background: url('../public/images/investor-header-desktop.png') no-repeat center top;
  background-attachment: local;
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-cover {
    padding-left: calc(2 * 20px + 2 * calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 40px);
    padding-right: calc(2 * 20px + 2 * calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 40px);
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-cover {
    background: url('../public/images/investor-header-mobile.png') no-repeat center top;
    padding-left: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-cover .name-break {
    display: block;
  }
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-container {
    padding-left: calc(2 * 20px + 2 * calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 40px);
    padding-right: calc(2 * 20px + 2 * calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 40px);
  }
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-container .error-wrapper {
    margin-top: 80px;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .error-wrapper {
    padding: 0;
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container {
    max-width: 100%;
    margin: 0;
    padding: 0 24px;
  }
}
.app-body.page-investor-default .investor-container .funds-container {
  margin-top: 80px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container {
    margin-top: 64px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .title-container {
    margin-bottom: 24px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .title-container .title-num {
  display: flex;
  align-items: baseline;
  gap: 12px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .title-container .title-num {
    flex-direction: column;
  }
}
.app-body.page-investor-default .investor-container .funds-container .title-container .title-num .num {
  color: #7b7c7c;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel {
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 32px;
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel {
    justify-content: space-between;
    gap: 16px;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel {
    padding: 24px;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel .text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel .text {
    font-size: 20px;
    line-height: 26px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel .smaller-text {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  margin-top: 12px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel .smaller-text {
    font-size: 14px;
    line-height: 20px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .gray-panel .btn {
  width: 180px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .gray-panel .btn {
    width: 100%;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing {
    flex-direction: column;
    gap: 20px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card {
  padding: 20px;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card {
    border-radius: 4px;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 14px;
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .value {
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .value {
    width: 150px;
    text-align: left;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .label,
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card .row .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card.empty {
    padding: 32px 0;
  }
}
.app-body.page-investor-default .investor-container .funds-container .funds-listing .fund-card.empty .central-row {
  padding-left: 36px;
  background: url('../public/icons/file-plus.svg') no-repeat left center;
  font-size: 14px;
}
.app-body.page-investor-default .investor-container .onboarding-container {
  margin-top: 80px;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .onboarding-container {
    margin-top: 64px;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container h2.gray-out {
  opacity: 0.5;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
  position: relative;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container {
    flex-direction: column;
    gap: 24px;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .react-tooltip {
  z-index: 1;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card {
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 10px 30px 0px #00000026;
  transition: all 0.2s ease-in-out;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card.gray-out {
  opacity: 0.5;
  cursor: default !important;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card:hover:not(.no-hover) {
  background-color: #f4f5f7;
  box-shadow: 0px 8px 20px 0px #00000026;
  cursor: pointer;
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container .card.empty {
    opacity: 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container .card.empty {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container .card {
    width: 100%;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-1 {
  background: url('../public/icons/person-plus.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.step-2 {
  background: url('../public/icons/person-bounding-box.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.my-funds {
  background: url('../public/icons/home-my-funds.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.my-portfolios {
  background: url('../public/icons/home-my-portfolios.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.my-profiles {
  background: url('../public/icons/home-my-profiles.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.profiler {
  background: url('../public/icons/home-profiler.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.profiler-projects {
  background: url('../public/icons/home-profiler-projects.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .icon-onboarding.profiler-watch-list {
  background: url('../public/icons/home-profiler-watch-list.svg') #e9ecef no-repeat center center;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .badge.info {
  background-color: #76b3ff;
  color: #ffffff;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .heading .badge.success {
  color: #28a745;
  background-color: #c1edd9;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body h6 {
  line-height: 24px;
  margin-bottom: 12px;
  font-weight: 600;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body h6.title-link {
  cursor: pointer !important;
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body h6.no-margin-bottom {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body h6 {
    font-size: 20px;
    margin-bottom: 8px;
  }
  .app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body h6.no-margin-bottom {
    margin-bottom: 0;
  }
}
.app-body.page-investor-default .investor-container .onboarding-container .cards-container .card .body p {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}
.app-body.page-investor-funds-listing.component-portfolio-companies,
.app-body.page-investor-funds-listing.component-default {
  padding-bottom: 40px;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies,
  .app-body.page-investor-funds-listing.component-default {
    padding: 0px 24px 24px 24px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tooltip-delete-list-fund,
.app-body.page-investor-funds-listing.component-default .tooltip-delete-list-fund {
  z-index: 1;
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tooltip-delete-list-fund,
  .app-body.page-investor-funds-listing.component-default .tooltip-delete-list-fund {
    max-width: 246px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies h4,
.app-body.page-investor-funds-listing.component-default h4 {
  font-weight: 500;
}
.app-body.page-investor-funds-listing.component-portfolio-companies h6,
.app-body.page-investor-funds-listing.component-default h6 {
  font-weight: 400;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .gray-title-panel,
.app-body.page-investor-funds-listing.component-default .gray-title-panel {
  border-radius: 16px;
  padding: 36px 32px;
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .gray-title-panel,
  .app-body.page-investor-funds-listing.component-default .gray-title-panel {
    padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-bottom: 32px;
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .gray-title-panel,
  .app-body.page-investor-funds-listing.component-default .gray-title-panel {
    margin-top: 24px;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .header,
.app-body.page-investor-funds-listing.component-default .table-responsive .header {
  padding: 19px 36px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .header,
  .app-body.page-investor-funds-listing.component-default .table-responsive .header {
    display: none;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .header .item,
.app-body.page-investor-funds-listing.component-default .table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row,
.app-body.page-investor-funds-listing.component-default .table-responsive .row {
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: grid;
  align-items: center;
  gap: 20px;
  padding: 19px 36px;
  grid-template-columns: repeat(8, 1fr);
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item.strong .value,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .item.strong .value {
  font-weight: 600;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .label,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .label,
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .value,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .value,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .value {
    font-weight: 600;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .value.fund-name,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .value.fund-name {
    padding-right: 20px;
  }
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .item .label,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .actions {
  display: flex;
  gap: 16px;
  align-items: center;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .loader-wrapper,
.app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .loader-wrapper {
  background-color: transparent;
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions {
    grid-column: span 2;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .listing-action-text-btn,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .listing-action-text-btn {
    width: 70px;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .listing-action-text-btn .new-icon-button,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .listing-action-text-btn .new-icon-button {
    width: 70px;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .btn,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .btn {
    display: block;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .listing-action-text-btn,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .listing-action-text-btn {
    width: 100%;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .listing-action-text-btn .new-icon-button,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .listing-action-text-btn .new-icon-button {
    width: 100%;
    justify-content: center;
  }
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .row .actions .listing-action-delete-btn,
  .app-body.page-investor-funds-listing.component-default .table-responsive .row .actions .listing-action-delete-btn {
    position: absolute;
    top: 11px;
    right: 11px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .outreach-deletion-row,
.app-body.page-investor-funds-listing.component-default .table-responsive .outreach-deletion-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: -16px;
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .outreach-deletion-row,
  .app-body.page-investor-funds-listing.component-default .table-responsive .outreach-deletion-row {
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .outreach-deletion-row .outreach-deletion-row-text,
.app-body.page-investor-funds-listing.component-default .table-responsive .outreach-deletion-row .outreach-deletion-row-text {
  color: #dc3545;
  font-size: 14px;
  font-weight: 500;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .table-responsive .outreach-deletion-row .new-icon-button-wrap,
.app-body.page-investor-funds-listing.component-default .table-responsive .outreach-deletion-row .new-icon-button-wrap {
  cursor: default;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel,
.app-body.page-investor-funds-listing.component-default .create-fund-panel {
  margin-top: 16px;
  border-radius: 4px;
  background-color: #f4f5f7;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel:hover:not(.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel:hover.disabled),
.app-body.page-investor-funds-listing.component-default .create-fund-panel:hover:not(.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel:hover.disabled),
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel:hover:not(.app-body.page-investor-funds-listing.component-default .create-fund-panel:hover.disabled),
.app-body.page-investor-funds-listing.component-default .create-fund-panel:hover:not(.app-body.page-investor-funds-listing.component-default .create-fund-panel:hover.disabled) {
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel.disabled .icon-add,
.app-body.page-investor-funds-listing.component-default .create-fund-panel.disabled .icon-add,
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel.disabled .text,
.app-body.page-investor-funds-listing.component-default .create-fund-panel.disabled .text {
  opacity: 0.5;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel .icon-add,
.app-body.page-investor-funds-listing.component-default .create-fund-panel .icon-add {
  width: 24px;
  height: 24px;
  background: url('../public/icons/file-plus.svg') no-repeat center center;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel .text,
.app-body.page-investor-funds-listing.component-default .create-fund-panel .text {
  font-size: 14px;
  line-height: 14px;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .create-fund-panel,
  .app-body.page-investor-funds-listing.component-default .create-fund-panel {
    margin-top: 24px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs,
.app-body.page-investor-funds-listing.component-default .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs,
  .app-body.page-investor-funds-listing.component-default .tabs {
    margin-bottom: 24px;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab,
.app-body.page-investor-funds-listing.component-default .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab.investo-profile,
.app-body.page-investor-funds-listing.component-default .tabs .tab.investo-profile {
  text-align: start;
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab.no-cursor,
.app-body.page-investor-funds-listing.component-default .tabs .tab.no-cursor {
  cursor: default !important;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab,
  .app-body.page-investor-funds-listing.component-default .tabs .tab {
    width: 100%;
    text-align: center;
  }
}
.app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab.active,
.app-body.page-investor-funds-listing.component-default .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab .desktop-text,
  .app-body.page-investor-funds-listing.component-default .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-portfolio-companies .tabs .tab .mobile-text,
  .app-body.page-investor-funds-listing.component-default .tabs .tab .mobile-text {
    display: none;
  }
}
.app-body.page-investor-funds-listing.component-create,
.app-body.page-investor-funds-listing.component-edit {
  padding-bottom: 40px;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create,
  .app-body.page-investor-funds-listing.component-edit {
    padding-bottom: 86px;
    padding-top: 0px;
  }
}
.app-body.page-investor-funds-listing.component-create h4,
.app-body.page-investor-funds-listing.component-edit h4 {
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 12px;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
}
.app-body.page-investor-funds-listing.component-create h4.empty,
.app-body.page-investor-funds-listing.component-edit h4.empty {
  color: #7b7c7c;
}
.app-body.page-investor-funds-listing.component-create h6,
.app-body.page-investor-funds-listing.component-edit h6 {
  font-weight: 400;
  display: none;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .header-backlink,
  .app-body.page-investor-funds-listing.component-edit .header-backlink {
    display: flex;
    flex-direction: column-reverse;
  }
}
.app-body.page-investor-funds-listing.component-create .header-backlink .gray-title-panel,
.app-body.page-investor-funds-listing.component-edit .header-backlink .gray-title-panel {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  padding: 36px 32px;
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-create .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-edit .header-backlink .gray-title-panel {
    padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-bottom: 16px;
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .header-backlink .gray-title-panel,
  .app-body.page-investor-funds-listing.component-edit .header-backlink .gray-title-panel {
    margin-top: 24px;
    border-radius: 4px;
    padding: 16px;
    flex-direction: column;
    gap: 12px;
  }
}
.app-body.page-investor-funds-listing.component-create .header-backlink .gray-title-panel .title-section,
.app-body.page-investor-funds-listing.component-edit .header-backlink .gray-title-panel .title-section {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-create .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-edit .header-backlink .gray-title-panel .actions {
    display: flex;
    gap: 20px;
    align-items: center;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .header-backlink .gray-title-panel .actions,
  .app-body.page-investor-funds-listing.component-edit .header-backlink .gray-title-panel .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px 24px;
    background: #ffffff;
    display: flex;
    gap: 12px;
    box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
  .app-body.page-investor-funds-listing.component-create .header-backlink .gray-title-panel .actions .btn,
  .app-body.page-investor-funds-listing.component-edit .header-backlink .gray-title-panel .actions .btn {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.app-body.page-investor-funds-listing.component-create .backlink,
.app-body.page-investor-funds-listing.component-edit .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 32px 0 24px 0;
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-create .backlink,
  .app-body.page-investor-funds-listing.component-edit .backlink {
    width: fit-content;
  }
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .backlink,
  .app-body.page-investor-funds-listing.component-edit .backlink {
    margin: 27px 0 3px 0;
  }
}
.app-body.page-investor-funds-listing.component-create .tabs,
.app-body.page-investor-funds-listing.component-edit .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .tabs,
  .app-body.page-investor-funds-listing.component-edit .tabs {
    margin: 24px 0;
    overflow: auto;
  }
}
.app-body.page-investor-funds-listing.component-create .tabs .tab,
.app-body.page-investor-funds-listing.component-edit .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .tabs .tab,
  .app-body.page-investor-funds-listing.component-edit .tabs .tab {
    width: 100%;
    white-space: nowrap;
  }
}
.app-body.page-investor-funds-listing.component-create .tabs .tab.active,
.app-body.page-investor-funds-listing.component-edit .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
.app-body.page-investor-funds-listing.component-create .tabs .tab.error-tab,
.app-body.page-investor-funds-listing.component-edit .tabs .tab.error-tab {
  color: #dc3545;
  padding-left: 24px;
  background: url('../public/icons/exclamation-circle.svg') no-repeat left center;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .tabs .tab .desktop-text,
  .app-body.page-investor-funds-listing.component-edit .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-investor-funds-listing.component-create .tabs .tab .mobile-text,
  .app-body.page-investor-funds-listing.component-edit .tabs .tab .mobile-text {
    display: none;
  }
}
.app-body.page-investor-funds-listing.component-create .form-body,
.app-body.page-investor-funds-listing.component-edit .form-body {
  background-color: #f4f5f7;
  border-radius: 16px;
}
@media (max-width: 480px) {
  .app-body.page-investor-funds-listing.component-create .form-body,
  .app-body.page-investor-funds-listing.component-edit .form-body {
    background-color: #ffffff;
    border-radius: 0;
    padding: 0;
  }
}
.app-body.page-user-profile {
  padding-bottom: 40px;
}
@media (max-width: 480px) {
  .app-body.page-user-profile {
    padding: 0px 24px 86px 24px;
  }
}
.app-body.page-user-profile h4 {
  font-weight: 500;
}
@media (min-width: 481px) {
  .app-body.page-user-profile h4 {
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 16px;
  }
}
.app-body.page-user-profile h6 {
  font-weight: 400;
}
@media (min-width: 481px) {
  .app-body.page-user-profile h6 {
    font-size: 14px;
    line-height: 14px;
  }
}
@media (min-width: 481px) {
  .app-body.page-user-profile .title-container {
    padding-top: 1px;
  }
}
.app-body.page-user-profile .gray-title-panel {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  padding: 36px 32px;
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-user-profile .gray-title-panel {
    padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-bottom: 32px;
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-user-profile .gray-title-panel {
    margin-top: 24px;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
  }
}
.app-body.page-user-profile .gray-title-panel .title-section h4 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-user-profile .gray-title-panel .title-section h4 {
    font-size: 24px;
    line-height: 24px;
  }
}
.app-body.page-user-profile .gray-title-panel .title-section h6 {
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 480px) {
  .app-body.page-user-profile .gray-title-panel .title-section h6 {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (min-width: 481px) {
  .app-body.page-user-profile .gray-title-panel .actions {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
@media (max-width: 480px) {
  .app-body.page-user-profile .gray-title-panel .actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px 24px;
    background: #ffffff;
    display: flex;
    gap: 12px;
    z-index: 1;
    box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
  }
  .app-body.page-user-profile .gray-title-panel .actions .btn {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.app-body.page-user-profile .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .app-body.page-user-profile .tabs {
    margin: 0 0 24px 0;
    overflow: auto;
  }
}
.app-body.page-user-profile .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  display: flex;
  gap: 8px;
  align-items: center;
}
@media (max-width: 480px) {
  .app-body.page-user-profile .tabs .tab {
    width: 100%;
    white-space: nowrap;
    justify-content: center;
  }
}
.app-body.page-user-profile .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 480px) {
  .app-body.page-user-profile .tabs .tab.only-desktop {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-user-profile .tabs .tab.only-mobile {
    display: none;
  }
}
@media (max-width: 480px) {
  .app-body.page-user-profile .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-user-profile .tabs .tab .mobile-text {
    display: none;
  }
}
.app-body.page-user-profile .tabs .tab .icon {
  width: 16px;
  height: 16px;
}
.app-body.page-user-profile .tabs .tab .icon.icon-user {
  background: url('../public/icons/grid.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-user.active {
  filter: invert(1);
}
.app-body.page-user-profile .tabs .tab .icon.icon-change-password {
  background: url('../public/icons/key.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-change-password.active {
  filter: invert(1);
}
.app-body.page-user-profile .tabs .tab .icon.icon-deactivate-account {
  background: url('../public/icons/person-slash.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-deactivate-account.active {
  filter: invert(1);
}
.app-body.page-user-profile .tabs .tab .icon.icon-settings {
  background: url('../public/icons/sliders-black.svg') no-repeat center center;
}
.app-body.page-user-profile .tabs .tab .icon.icon-settings.active {
  filter: invert(1);
}
.app-body.page-user-profile .form-body {
  background-color: #f4f5f7;
  border-radius: 16px;
}
@media (max-width: 480px) {
  .app-body.page-user-profile .form-body {
    background-color: #ffffff;
    border-radius: 0;
    padding: 0;
  }
}
.app-body.page-user-profile .form-body label {
  padding-left: 0;
}
.app-body.page-user-profile .mobile-settings-container {
  width: 100%;
}
.app-body.page-user-profile .mobile-settings-container .item {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  background: url('../public/icons/arrow-right.svg') no-repeat right center;
}
.app-body.page-user-profile .mobile-settings-container .item a {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 48px;
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-user-profile .confirm-container {
    padding: 32px 117px;
  }
  .app-body.page-user-profile .confirm-container .backlink {
    display: none;
  }
  .app-body.page-user-profile .confirm-container .title {
    height: 24px;
    padding-left: 42px;
    background: url('../public/icons/info-circle.svg') no-repeat left center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }
  .app-body.page-user-profile .confirm-container .body {
    margin: 20px 0 0 34px;
    width: 550px;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 480px) {
  .app-body.page-user-profile .confirm-container .backlink {
    width: 100%;
    display: block;
    height: 24px;
    padding-left: 36px;
    background: url('../public/icons/arrow-left.svg') no-repeat left center;
  }
  .app-body.page-user-profile .confirm-container .title {
    margin-top: 20px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
  .app-body.page-user-profile .confirm-container .body {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (min-width: 481px) {
  .app-body.page-user-profile .mobile-tab-header {
    display: none;
  }
}
.app-body.page-user-profile .mobile-tab-header .backlink {
  width: 100%;
  display: block;
  height: 24px;
  margin-bottom: 20px;
  padding-left: 36px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
}
.app-body.page-user-profile .mobile-tab-header .title {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.app-body.page-user-profile .mobile-tab-header p {
  line-height: 20px;
  font-size: 14px;
}
.app-body.page-search-listing {
  padding-bottom: 16px;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .mobile-only {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .desktop-only {
    display: none !important;
  }
}
.app-body.page-search-listing .mobile-filters-container {
  flex-grow: 1;
  padding-bottom: 66px;
}
.app-body.page-search-listing .mobile-filters-container .backlink {
  font-size: 16px;
  font-weight: 600;
  padding-left: 36px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 12px 0 32px 0;
  width: 100%;
}
.app-body.page-search-listing .mobile-filters-container .col {
  margin-bottom: 20px;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 14px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 4px;
  grid-template-areas: 'label label label' 'min separator max' 'err err err';
}
.app-body.page-search-listing .mobile-filters-container .col.range-field label {
  grid-area: label;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .input-error-group {
  width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #adb5bd;
  margin: 18px 2px;
}
.app-body.page-search-listing .mobile-filters-container .col.range-field .range-error-msg-input {
  grid-area: err;
}
.app-body.page-search-listing .form-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 24px;
  background: #ffffff;
  display: flex;
  gap: 12px;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
}
@media (min-width: 481px) {
  .app-body.page-search-listing .form-footer {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout {
    margin-top: 43px;
    display: flex;
    gap: 20px;
  }
}
.app-body.page-search-listing .listing-layout .desktop-filters {
  align-self: flex-start;
  position: sticky;
  top: 80px;
  max-height: calc(100vh - 80px - 43px - 16px - 80px);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #f4f5f7;
  flex: 0 0 calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
  padding: 0px 0px 20px 20px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .desktop-filters {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .desktop-filters .fade-top {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 8px;
  width: calc(100% - 16px);
  height: 8px;
  background-image: linear-gradient(to bottom, #f4f5f7, rgba(244, 245, 247, 0));
}
.app-body.page-search-listing .listing-layout .desktop-filters .fade-bottom {
  z-index: 1;
  position: absolute;
  bottom: 58px;
  left: 8px;
  width: calc(100% - 16px);
  height: 8px;
  background-image: linear-gradient(to top, #f4f5f7, rgba(244, 245, 247, 0));
}
.app-body.page-search-listing .listing-layout .desktop-filters .scrollable-items {
  overflow-y: scroll;
  padding-top: 20px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .scrollable-items::-webkit-scrollbar {
  width: 20px;
  background-color: #f4f5f7;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .scrollable-items::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 18px;
  border: 8px solid #f4f5f7;
}
.app-body.page-search-listing .listing-layout .desktop-filters .scrollable-items::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.app-body.page-search-listing .listing-layout .desktop-filters #desktop-filters-search-btn-wrap {
  margin-right: 20px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category {
  border-top: 0.5px solid #7b7c7c;
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category.no-border {
  border-top: none;
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category .col-wrapper {
  transform-origin: top center;
  animation: expand-search-results-filter-category-buttons 100ms ease;
  -webkit-animation: expand-search-results-filter-category-buttons 100ms ease;
}
@keyframes expand-search-results-filter-category-buttons {
  0% {
    transform: scaleY(0);
    opacity: 0.5;
  }
  100% {
    transform: scaleY(1);
    opacity: 1;
  }
}
@-webkit-keyframes expand-search-results-filter-category-buttons {
  0% {
    -webkit-transform: scaleY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scaleY(1);
    opacity: 1;
  }
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category button {
  padding: 14px 14px;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category button .expanded {
  transform: rotate(180deg);
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category button:hover {
  background-color: #e9ecef;
}
.app-body.page-search-listing .listing-layout .desktop-filters .filter-category label {
  font-size: 12px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .input-container {
  background: #ffffff;
  border-radius: 6px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .input-container.icon-select-downarrow.dropdownToggled {
  background: url('../public/icons/chevron-up.svg') #ffffff no-repeat calc(100% - 16px) center;
}
.app-body.page-search-listing .listing-layout .desktop-filters .input-container.icon-select-downarrow.dropdownUntoggled {
  background: url('../public/icons/chevron-down.svg') #ffffff no-repeat calc(100% - 16px) center;
}
.app-body.page-search-listing .listing-layout .desktop-filters .input-container .multiselect-container {
  max-width: calc(calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px) - 40px);
}
.app-body.page-search-listing .listing-layout .desktop-filters .col {
  margin-bottom: 20px;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col label {
  padding-left: 16px;
  display: block;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field {
  display: grid;
  margin-bottom: 4px;
  grid-template-columns: 1fr 24px 1fr;
  grid-template-rows: 24px 1fr 16px;
  grid-column-gap: 0px;
  grid-template-areas: 'label label label' 'min separator max' 'err err err';
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field label {
  grid-area: label;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field label span {
  margin-bottom: 0;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .form-group {
  display: contents;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .input-error-group {
  width: 100%;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .form-group:first-of-type .input-error-group {
  grid-area: min;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .form-group:last-of-type .input-error-group {
  grid-area: max;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .range-error-msg-input {
  grid-area: err;
}
.app-body.page-search-listing .listing-layout .desktop-filters .col.range-field .separator {
  grid-area: separator;
  width: 8px;
  height: 2px;
  background-color: #adb5bd;
  margin: 18px 7px;
}
.app-body.page-search-listing .listing-layout .results {
  flex-grow: 1;
}
.app-body.page-search-listing .listing-layout .results .loader-wrapper {
  flex-grow: 0;
  height: auto;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .loader-wrapper {
    margin-top: 32px;
  }
}
.app-body.page-search-listing .listing-layout .results .error-wrapper {
  flex-grow: 0;
  height: auto;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .error-wrapper {
    margin-top: 78px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .error-wrapper {
    padding: 0;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results {
    margin-top: -20px;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .mobile-filters {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .results-header {
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  padding-left: 32px;
  border-bottom: 1px solid #348cf9;
  justify-content: space-between;
  gap: 16px;
  position: sticky;
  top: 80px;
  background-color: #ffffff;
  z-index: 2;
}
.app-body.page-search-listing .listing-layout .results .results-header::before {
  display: block;
  content: ' ';
  width: 20px;
  height: 100%;
  position: absolute;
  left: -20px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
}
.app-body.page-search-listing .listing-layout .results .results-header::after {
  display: block;
  content: ' ';
  width: 20px;
  height: 100%;
  position: absolute;
  right: -20px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .results-header {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .results-header .cols {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  line-height: 14px;
  gap: 8px;
}
.app-body.page-search-listing .listing-layout .results .results-header .cols .col {
  width: 20%;
}
.app-body.page-search-listing .listing-layout .results .results-header .cols .col.right-align {
  text-align: end;
}
.app-body.page-search-listing .listing-layout .results .results-header .cols .col.profiler-company-name {
  min-width: 132px;
}
.app-body.page-search-listing .listing-layout .results .results-header .cols .col.profiler-revenue {
  max-width: 94px;
  min-width: 94px;
  width: 94px;
}
.app-body.page-search-listing .listing-layout .results .results-header .cols .col.profiler-employees {
  max-width: 76px;
  min-width: 76px;
  width: 76px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions {
  min-width: 132px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions.default-search-buttons {
  width: 203px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .total-num {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  margin-bottom: 8px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #348cf9;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  right: 0;
  top: 38px;
  border-radius: 4px;
  box-shadow: 0px 5px 20px 0px #00000040;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: max-content;
  max-height: calc(100dvh - 80px - 80px - 65px);
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 2px;
  border-radius: 9px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap .new-icon-button-wrap {
  width: 100%;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap .new-icon-button-wrap .new-icon-button {
  width: 100%;
  border-radius: 0;
  padding: 12px 24px;
  column-gap: 12px;
  justify-content: space-between;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap .new-icon-button-wrap .new-icon-button .img-wrap {
  width: 20px;
  height: 20px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap .new-icon-button-wrap .new-icon-button .img-wrap img {
  width: 20px;
  height: 20px;
}
.app-body.page-search-listing .listing-layout .results .results-header .actions .buttons .sort-menu-wrap .react-tooltip {
  z-index: 1;
}
.app-body.page-search-listing .listing-layout .results .item {
  border-radius: 8px;
  margin-top: 16px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 16px;
  background-color: #ffffff;
}
.app-body.page-search-listing .listing-layout .results .item.no-shadow {
  box-shadow: none;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item {
    flex-direction: column;
    padding: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .item .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}
.app-body.page-search-listing .listing-layout .results .item .cols * {
  overflow-wrap: break-word;
  word-break: break-word;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item .cols {
    flex-direction: column;
  }
  .app-body.page-search-listing .listing-layout .results .item .cols .col {
    align-items: center;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .item .cols .col {
    width: 20%;
  }
  .app-body.page-search-listing .listing-layout .results .item .cols .col .value.right-align-text-desktop {
    justify-content: flex-end;
  }
  .app-body.page-search-listing .listing-layout .results .item .cols .col.profiler-company-name {
    min-width: 132px;
  }
  .app-body.page-search-listing .listing-layout .results .item .cols .col.profiler-revenue {
    max-width: 94px;
    min-width: 94px;
    width: 94px;
  }
  .app-body.page-search-listing .listing-layout .results .item .cols .col.profiler-employees {
    max-width: 76px;
    min-width: 76px;
    width: 76px;
  }
}
.app-body.page-search-listing .listing-layout .results .item .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px;
  gap: 16px;
}
.app-body.page-search-listing .listing-layout .results .item .actions.default-search-actions {
  min-width: 171px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item .actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }
  .app-body.page-search-listing .listing-layout .results .item .actions .col {
    padding: 0;
    width: fit-content;
  }
  .app-body.page-search-listing .listing-layout .results .item .actions .col.view-details-btn,
  .app-body.page-search-listing .listing-layout .results .item .actions .col.restore-btn {
    flex-grow: 1;
    flex: 1;
  }
  .app-body.page-search-listing .listing-layout .results .item .actions .col.view-details-btn .new-icon-button-wrap,
  .app-body.page-search-listing .listing-layout .results .item .actions .col.restore-btn .new-icon-button-wrap {
    width: 100%;
  }
  .app-body.page-search-listing .listing-layout .results .item .actions .col.view-details-btn .new-icon-button-wrap .new-icon-button,
  .app-body.page-search-listing .listing-layout .results .item .actions .col.restore-btn .new-icon-button-wrap .new-icon-button {
    width: 100%;
    justify-content: center;
  }
}
.app-body.page-search-listing .listing-layout .results .item:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item .col.no-mobile {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .item .col.no-desktop {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .item .col.mobile-badge {
  padding: 0;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item .col {
    width: 100%;
    border-bottom: 0.5px solid #adb5bd;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item .col.no-border {
    border-bottom: 0;
  }
}
.app-body.page-search-listing .listing-layout .results .item .col .btn {
  padding: 10px;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .item .label {
    display: none;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item .label {
    font-weight: 500;
    color: #000000;
  }
}
.app-body.page-search-listing .listing-layout .results .item .value {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .item .value.right-align-desktop {
    justify-content: flex-end;
  }
}
.app-body.page-search-listing .listing-layout .results .item .value .year {
  font-size: 10px;
  line-height: 10px;
  margin-top: -2px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .item .value {
    width: 50%;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results {
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .no-results {
    padding: 104px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .no-results {
    padding: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results h5 {
  line-height: 20px;
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .no-results h5 {
    line-height: 26px;
    margin-bottom: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results .text-help {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .no-results .text-help {
    margin-bottom: 24px;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results ul {
  list-style: disc;
  padding-left: 16px;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .listing-layout .results .no-results ul li {
    margin-bottom: 16px;
  }
  .app-body.page-search-listing .listing-layout .results .no-results ul li:last-of-type {
    margin-bottom: 0;
  }
}
.app-body.page-search-listing .listing-layout .results .no-results a {
  color: #348cf9;
  text-decoration: underline;
  font-weight: 600;
}
.app-body.page-search-listing .listing-layout .results .badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}
.app-body.page-search-listing .listing-layout .results .badge.success {
  color: #28a745;
  background-color: #c1edd9;
}
.app-body.page-search-listing .listing-layout .results .badge.warning {
  color: #ed7200;
  background-color: #ffe79e;
}
.app-body.page-search-listing .listing-layout .results .badge.info {
  color: #7b7c7c;
  background-color: #e9ecef;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters {
  height: 51px;
  margin-top: 12px;
  margin-bottom: 32px;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .listing-layout .results .mobile-filters {
    display: none;
  }
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .total-number {
  text-align: right;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 12px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container {
  display: flex;
  justify-content: space-between;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .filters-btn {
  background: url('../public/icons/sliders-black.svg') no-repeat left center;
  padding-left: 24px;
  width: 76px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions {
  display: flex;
  gap: 12px;
  position: relative;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #348cf9;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  right: 0;
  top: 38px;
  border-radius: 4px;
  box-shadow: 0px 5px 20px 0px #00000040;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: max-content;
  max-height: calc(100dvh - 52px - 51px - 50px);
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 2px;
  border-radius: 9px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap .new-icon-button-wrap {
  width: 100%;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap .new-icon-button-wrap .new-icon-button {
  width: 100%;
  border-radius: 0;
  padding: 12px 24px;
  column-gap: 12px;
  justify-content: space-between;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap .new-icon-button-wrap .new-icon-button .img-wrap {
  width: 20px;
  height: 20px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap .new-icon-button-wrap .new-icon-button .img-wrap img {
  width: 20px;
  height: 20px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .sort-menu-wrap .react-tooltip {
  z-index: 1;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .btn {
  width: 22px;
  height: 22px;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .btn.icon-save-search {
  background: url('../public/icons/save-floppy.svg') no-repeat center center;
}
.app-body.page-search-listing .listing-layout .results .mobile-filters .filters-download-container .actions .btn.icon-download-search {
  background: url('../public/icons/download.svg') no-repeat center center;
}
.app-body.page-search-listing .listing-layout .results .excluded-results-wrap {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #000000;
}
.app-body.page-search-listing .listing-layout .results .excluded-results-box {
  background-color: #f4f5f7;
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}
.app-body.page-search-listing .listing-layout .results .excluded-results-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
}
.app-body.page-search-listing .listing-layout .results .excluded-results-header.expanded {
  border-bottom: 1px solid #adb5bd;
}
.app-body.page-search-listing .listing-layout .results .excluded-results-header-text {
  font-size: 12px;
  font-weight: 600;
}
.app-body.page-search-listing .listing-layout .paging {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 32px;
}
.app-body.page-search-listing .listing-layout .paging .btn {
  width: 62px;
  color: #348cf9;
}
.app-body.page-search-listing .listing-layout .paging .btn:disabled {
  color: #adb5bd;
  background: white;
}
.app-body.page-search-listing .overlay {
  position: fixed;
  top: -1000px;
  left: -200px;
  width: calc(100dvw + 400px);
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  display: flex;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay {
    padding: 1000px 200px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay {
    padding: calc(1000px + 24px) calc(200px + 24px);
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay.popup-details-overlay {
    display: block;
  }
}
.app-body.page-search-listing .overlay .container {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container {
    padding: 24px 0;
    width: 100%;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container {
    width: 712px;
  }
}
.app-body.page-search-listing .overlay .container .close {
  background: url('../public/icons/x.svg') no-repeat right center;
  padding-right: 20px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container .close {
    text-align: right;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
  }
}
.app-body.page-search-listing .overlay .container .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container .header {
    flex-direction: column-reverse;
    gap: 12px;
    padding: 0 24px;
  }
}
.app-body.page-search-listing .overlay .container .errors {
  height: 20px;
  line-height: 20px;
  margin-top: 8px;
  padding-left: 28px;
  color: #dc3545;
  font-size: 12px;
  background: url('../public/icons/exclamation-circle.svg') no-repeat left center;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container .errors {
    margin-top: 16px;
  }
}
.app-body.page-search-listing .overlay .container.popup-details {
  display: flex;
  flex-direction: column;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-details {
    min-height: 520px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-details {
    max-height: calc(100dvh - 24px - 40px);
  }
}
.app-body.page-search-listing .overlay .container.popup-details .header {
  border-bottom: 0.5px solid #348cf9;
}
.app-body.page-search-listing .overlay .container.popup-details .content {
  flex: 1;
  overflow-y: auto;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-details .content::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
  }
  .app-body.page-search-listing .overlay .container.popup-details .content::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
    border-radius: 2px;
    border-radius: 9px;
  }
  .app-body.page-search-listing .overlay .container.popup-details .content::-webkit-scrollbar-thumb:hover {
    background-color: #7b7c7c;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .tabs,
.app-body.page-search-listing .overlay .container.popup-details .header .tabs {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .tabs,
  .app-body.page-search-listing .overlay .container.popup-details .header .tabs {
    justify-content: space-around;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .tabs .tab,
.app-body.page-search-listing .overlay .container.popup-details .header .tabs .tab {
  padding: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #000000;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .tabs .tab,
  .app-body.page-search-listing .overlay .container.popup-details .header .tabs .tab {
    font-size: 14px;
    line-height: 14px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .tabs .tab.active,
.app-body.page-search-listing .overlay .container.popup-details .header .tabs .tab.active {
  border-bottom: 2px solid #348cf9;
  color: #ffffff;
  background-color: #348cf9;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .content,
  .app-body.page-search-listing .overlay .container.popup-details .content {
    padding: 0 24px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .row,
.app-body.page-search-listing .overlay .container.popup-details .content .row {
  padding: 8px 0;
  border-bottom: 0.5px solid #adb5bd;
  display: flex;
  align-items: center;
}
.app-body.page-search-listing .overlay .container.popup-message .content .row:last-of-type,
.app-body.page-search-listing .overlay .container.popup-details .content .row:last-of-type {
  border-bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row,
  .app-body.page-search-listing .overlay .container.popup-details .content .row {
    padding: 16px 0;
    height: fit-content;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .row .bullet,
.app-body.page-search-listing .overlay .container.popup-details .content .row .bullet {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row .bullet,
  .app-body.page-search-listing .overlay .container.popup-details .content .row .bullet {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row .bullet,
  .app-body.page-search-listing .overlay .container.popup-details .content .row .bullet {
    display: none;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .row .value,
.app-body.page-search-listing .overlay .container.popup-details .content .row .value {
  white-space: pre-wrap;
}
.app-body.page-search-listing .overlay .container.popup-message .content .row .value,
.app-body.page-search-listing .overlay .container.popup-details .content .row .value,
.app-body.page-search-listing .overlay .container.popup-message .content .row .label,
.app-body.page-search-listing .overlay .container.popup-details .content .row .label {
  font-size: 12px;
  line-height: 12px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .row .label,
.app-body.page-search-listing .overlay .container.popup-details .content .row .label {
  font-weight: 600;
  padding-left: 12px;
  width: 230px;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row .label,
  .app-body.page-search-listing .overlay .container.popup-details .content .row .label {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .row .label,
  .app-body.page-search-listing .overlay .container.popup-details .content .row .label {
    flex: 0 0 50%;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .field,
.app-body.page-search-listing .overlay .container.popup-details .content .field {
  margin-bottom: 20px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .field.no-margin-bottom,
.app-body.page-search-listing .overlay .container.popup-details .content .field.no-margin-bottom {
  margin-bottom: 0;
}
.app-body.page-search-listing .overlay .container.popup-message .content .field.readonly,
.app-body.page-search-listing .overlay .container.popup-details .content .field.readonly {
  display: flex;
}
.app-body.page-search-listing .overlay .container.popup-message .content .field.readonly label,
.app-body.page-search-listing .overlay .container.popup-details .content .field.readonly label {
  width: 120px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .field label,
.app-body.page-search-listing .overlay .container.popup-details .content .field label {
  padding-left: 16px;
}
.app-body.page-search-listing .overlay .container.popup-message .content .actions,
.app-body.page-search-listing .overlay .container.popup-details .content .actions {
  display: flex;
  justify-content: flex-end;
}
.app-body.page-search-listing .overlay .container.popup-message .content .actions.outreach,
.app-body.page-search-listing .overlay .container.popup-details .content .actions.outreach {
  justify-content: space-between;
}
.app-body.page-search-listing .overlay .container.popup-message .content .actions.outreach .pdf-action,
.app-body.page-search-listing .overlay .container.popup-details .content .actions.outreach .pdf-action {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
.app-body.page-search-listing .overlay .container.popup-message .content .actions.outreach .pdf-action .pdf-action-info,
.app-body.page-search-listing .overlay .container.popup-details .content .actions.outreach .pdf-action .pdf-action-info {
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
  line-height: 10px;
  color: #000000;
}
.app-body.page-search-listing .overlay .container.popup-message .header .header-info {
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  margin-top: 2px;
  margin-bottom: 24px;
  padding-right: 140px;
  color: #28bbfa;
  padding-left: 28px;
  background: url('../public/icons/exclamation-circle-info.svg') no-repeat left top;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .header-info {
    padding-right: 20px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .header.message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 8px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header.message.single .text .value .save-custom-contact {
  animation: beep-save-custom-contact 0.5s infinite;
}
@keyframes beep-save-custom-contact {
  0% {
    color: #000000;
    /* Start color */
    transform: scale(1);
    /* Initial scale */
  }
  100% {
    color: #28a745;
    /* Change to green */
    transform: scale(1.1);
    /* Scale up */
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header.message.single .text .value .delete-custom-contact {
  animation: beep-delete-custom-contact 0.5s infinite;
}
@keyframes beep-delete-custom-contact {
  0% {
    color: #000000;
    /* Start color */
    transform: scale(1);
    /* Initial scale */
  }
  100% {
    color: #dc3545;
    /* Change to green */
    transform: scale(0.9);
    /* Scale up */
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .header.message.single {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 14px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .header.message.single .close {
    align-self: flex-end;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .text {
    justify-content: space-between;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .text label {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .header .text label {
    padding-top: 4px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .header .text .value {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow: hidden;
  animation: expand-custom-contact-wrap 0.5s ease-in-out forwards;
}
@keyframes expand-custom-contact-wrap {
  from {
    opacity: 0.3;
    max-height: 0;
    /* Start from collapsed */
  }
  to {
    opacity: 1;
    max-height: 10000px;
    /* Set an arbitrary large max height */
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap {
    padding-left: 24px;
    padding-right: 24px;
    row-gap: 8px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .instructions {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap {
    flex-direction: column;
    align-items: unset;
    row-gap: 8px;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap.email-wrap {
    column-gap: 12px;
  }
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap.email-wrap .left {
    width: 45%;
  }
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap.email-wrap .right {
    width: 55%;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .left .form-group,
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .form-group {
  width: 100%;
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .left .form-group label,
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .form-group label {
  padding-left: 16px;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .left {
    width: 50%;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 8px;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right {
    flex-direction: column;
    align-items: unset;
    row-gap: 4px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .email-domain {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .email-domain {
    margin-top: 20.5px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .email-domain {
    text-align: right;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap {
    column-gap: 8px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .left .new-icon-button-wrap .new-icon-button .inner-text {
    display: none;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right {
    column-gap: 8px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right .new-icon-button-wrap:first-child .new-icon-button .inner-text {
  color: #dc3545;
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right .new-icon-button-wrap:first-child:hover .new-icon-button .inner-text {
  color: #ffffff;
}
.app-body.page-search-listing .overlay .container.popup-message .custom-contact-wrap .contacts-error {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #dc3545;
}
.app-body.page-search-listing .overlay .container.popup-message .separator {
  border-top: 0.5px solid #adb5bd;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .separator {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .info {
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  margin-bottom: 24px;
  margin-top: 20px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .info {
    line-height: 16px;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .info .terms-link {
  text-decoration: underline;
}
.app-body.page-search-listing .overlay .container.popup-message .content .success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
  height: 380px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .success {
    flex-direction: column;
    padding: 0 14px;
    gap: 12px;
  }
}
@media (min-width: 481px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .success {
    width: 332px;
    margin: 0 auto;
  }
}
.app-body.page-search-listing .overlay .container.popup-message .content .success img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .content .success .names {
    display: block;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-message .actions .btn {
    width: 100%;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search {
  min-height: 160px;
  margin: auto;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-save-search {
    min-height: 210px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .header {
    gap: 36px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 306px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .success-text {
    flex-direction: column;
    gap: 4px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text h4 {
  font-size: 20px;
  line-height: 26px;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .success-text h4 {
    max-width: 250px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text img {
  width: 24px;
  height: 24px;
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-text .loader-wrapper {
  height: 50px;
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-actions {
  display: flex;
  justify-content: center;
}
.app-body.page-search-listing .overlay .container.popup-save-search .success-actions a {
  display: block;
  width: auto;
}
.app-body.page-search-listing .overlay .container.popup-save-search h5 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.app-body.page-search-listing .overlay .container.popup-save-search p {
  margin: 0;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-save-search p {
    padding: 0 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .form-group {
    padding: 0 24px;
  }
}
.app-body.page-search-listing .overlay .container.popup-save-search .actions {
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-search-listing .overlay .container.popup-save-search .errors {
    padding: 0 24px;
  }
}
.transparent-overlay {
  position: fixed;
  top: -200px;
  bottom: -200px;
  left: -200px;
  right: -200px;
  padding: 200px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.transparent-overlay .container {
  padding: 60px 32px;
  background-color: #ffffff;
  border-radius: 8px;
}
@media (max-width: 480px) {
  .transparent-overlay .container {
    margin: 0 24px;
    padding: 24px 24px;
    width: calc(100% - 400px - 24px * 2);
    position: fixed;
    top: 33%;
  }
}
@media (min-width: 481px) {
  .transparent-overlay .container {
    width: 589px;
    box-shadow: 0px 10px 40px 0px #00000026;
  }
}
.transparent-overlay .container .title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: center;
}
@media (max-width: 480px) {
  .transparent-overlay .container .title {
    line-height: 30px;
    margin-bottom: 40px;
  }
}
.transparent-overlay .container .actions {
  display: flex;
  gap: 32px;
}
@media (max-width: 480px) {
  .transparent-overlay .container .actions {
    flex-direction: column;
    gap: 24px;
  }
}
.transparent-overlay .container .delete-projects-list {
  padding: 0 20px;
}
.transparent-overlay .container .delete-projects-list .content {
  overflow-y: auto;
  height: 176px;
  padding: 10px 0;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.transparent-overlay .container .delete-projects-list .content label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.transparent-overlay .container .delete-projects-list .content label input {
  height: 18px;
  width: 18px;
}
.transparent-overlay .container .delete-projects-list .content label:hover {
  background-color: #F4F5F7;
}
.transparent-overlay .container .validation-content {
  display: flex;
  gap: 10px;
  font-size: 10px;
  line-height: 12px;
  padding: 10px 20px 25px 20px;
}
.transparent-overlay .container .validation-content input {
  height: 18px;
  width: 18px;
}
.app-body.page-saved-search-listing .results-header {
  display: flex;
  width: 100%;
  padding: 0 0 0 calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  justify-content: space-between;
  gap: 16px;
}
.app-body.page-saved-search-listing .results-header .cols {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  line-height: 14px;
  gap: 8px;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .results-header .cols {
    display: none;
  }
}
.app-body.page-saved-search-listing .results-header .cols .col {
  width: 30%;
}
.app-body.page-saved-search-listing .results-header .actions {
  min-width: 223px;
  text-align: right;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .results-header .actions {
    width: 100%;
    padding-top: 12px;
  }
}
.app-body.page-saved-search-listing .item {
  margin-top: 16px;
  border-radius: 4px;
  background-color: #e9ecef;
  padding: 16px 0 16px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .item {
    flex-direction: column;
    padding: 20px;
  }
}
.app-body.page-saved-search-listing .item .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .item .cols {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .app-body.page-saved-search-listing .item .cols .col {
    align-items: center;
  }
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .item .cols .col {
    width: 30%;
  }
  .app-body.page-saved-search-listing .item .cols .label {
    display: none;
  }
}
.app-body.page-saved-search-listing .item .cols .search-name {
  font-size: 16px;
  line-height: 16px;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .item .cols .search-name {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
  }
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .item .cols .mobile-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    line-height: 14px;
  }
  .app-body.page-saved-search-listing .item .cols .mobile-row .label {
    font-weight: 600;
  }
}
.app-body.page-saved-search-listing .item .actions {
  min-width: 223px;
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .item .actions {
    display: flex;
    justify-content: flex-end;
    padding-right: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  }
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .item .actions {
    width: 100%;
  }
}
.app-body.page-saved-search-listing .item .actions .btn {
  display: block;
  font-size: 14px;
  line-height: 16px;
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .item .actions .btn {
    width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  }
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .item .actions .btn {
    display: block;
  }
}
.app-body.page-saved-search-listing .paging {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 32px;
}
.app-body.page-saved-search-listing .paging .btn {
  width: 62px;
  color: #348cf9;
}
.app-body.page-saved-search-listing .paging .btn:disabled {
  color: #adb5bd;
  background: white;
}
.app-body.page-saved-search-listing .details .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 32px 0 24px 0;
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .details .backlink {
    width: fit-content;
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details .backlink {
    margin: 27px 0;
  }
}
.app-body.page-saved-search-listing .details .title {
  padding: 0 0 32px 0;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .details .title {
    border-bottom: 0.5px solid #000000;
  }
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details .title {
    font-size: 32px;
    line-height: 32px;
  }
}
.app-body.page-saved-search-listing .details .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 20px;
  overflow: auto;
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .details .tabs {
    display: none;
  }
}
.app-body.page-saved-search-listing .details .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
}
.app-body.page-saved-search-listing .details .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details section.mobile-hidden {
    display: none;
  }
}
.app-body.page-saved-search-listing .details section.mandatory-fields .section-title {
  color: #348cf9;
}
.app-body.page-saved-search-listing .details section.optional-fields .section-title {
  margin-bottom: 0;
}
.app-body.page-saved-search-listing .details section .section-title {
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
  margin-top: 32px;
  margin-bottom: 24px;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details section .section-title {
    display: none;
  }
}
.app-body.page-saved-search-listing .details section .group-title {
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
  color: #348cf9;
  padding: 20px 0;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details section .group-title {
    font-size: 16px;
  }
}
.app-body.page-saved-search-listing .details section .group {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #adb5bd;
  gap: 80px;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details section .group {
    flex-direction: column;
    gap: 16px;
  }
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .details section .group.mobile-only {
    display: none;
  }
}
.app-body.page-saved-search-listing .details section .group .field {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
  display: flex;
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details section .group .field {
    width: 100%;
    justify-content: space-between;
  }
  .app-body.page-saved-search-listing .details section .group .field.empty {
    display: none;
  }
}
.app-body.page-saved-search-listing .details section .group .field .label {
  height: 24px;
  padding-left: 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background: url('../public/icons/justify.svg') no-repeat left top;
}
@media (min-width: 481px) {
  .app-body.page-saved-search-listing .details section .group .field .label {
    min-width: 190px;
    flex: 0 1 190px;
    height: fit-content;
  }
}
@media (max-width: 480px) {
  .app-body.page-saved-search-listing .details section .group .field .value {
    width: 129px;
  }
}
.pagination-container {
  display: flex;
  flex-direction: row;
  color: whitesmoke;
  justify-content: center;
  margin-top: 16px;
  gap: 4px;
  font-size: 16px;
}
.pagination-container .page {
  width: 38px;
  height: 40px;
}
.pagination-container .prev,
.pagination-container .next {
  width: 62px;
  height: 40px;
}
.pagination-container .page,
.pagination-container .prev,
.pagination-container .next {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.pagination-container .page.selected,
.pagination-container .prev.selected,
.pagination-container .next.selected {
  cursor: default;
  background-color: #348cf9;
}
.pagination-container .page.selected .pageLink,
.pagination-container .prev.selected .pageLink,
.pagination-container .next.selected .pageLink {
  color: #ffffff;
}
.pagination-container .page.selected .pageLink:hover,
.pagination-container .prev.selected .pageLink:hover,
.pagination-container .next.selected .pageLink:hover {
  box-shadow: none;
}
.pagination-container .page .pageLink,
.pagination-container .prev .pageLink,
.pagination-container .next .pageLink {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #348cf9;
}
.pagination-container .page .pageLink:hover,
.pagination-container .prev .pageLink:hover,
.pagination-container .next .pageLink:hover {
  box-shadow: 0px 1px 10px 0px #00000026;
  transition: all 0.1s ease-in-out;
}
.pagination-container .next.disabled,
.pagination-container .prev.disabled {
  cursor: not-allowed;
}
.pagination-container .next.disabled .prevLink,
.pagination-container .prev.disabled .prevLink,
.pagination-container .next.disabled .nextLink,
.pagination-container .prev.disabled .nextLink {
  color: #adb5bd;
}
.pagination-container .next.disabled:hover,
.pagination-container .prev.disabled:hover {
  box-shadow: none;
}
.pagination-container .next .prevLink,
.pagination-container .prev .prevLink,
.pagination-container .next .nextLink,
.pagination-container .prev .nextLink {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #348cf9;
}
.pagination-container .next:hover,
.pagination-container .prev:hover {
  box-shadow: 0px 1px 10px 0px #00000026;
  transition: all 0.1s ease-in-out;
}
.pagination-container .break {
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.pagination-container .break .breakLink {
  color: #adb5bd;
}
.error-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  flex-grow: 1;
}
@media (max-width: 480px) {
  .error-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.error-wrapper .general-error {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 48px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  background-color: #f4f5f7;
  border-radius: 9px;
  text-align: center;
  gap: 24px;
}
.error-wrapper .general-error .big-text {
  font-size: 20px;
  line-height: 22px;
}
.error-wrapper .general-error .small-text {
  font-size: 16px;
  line-height: 14px;
}
.error-wrapper .general-error .actions {
  color: #348cf9;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  line-height: 18px;
}
@media (max-width: 480px) {
  .error-wrapper .general-error .actions {
    gap: 24px;
  }
}
.error-wrapper .general-error .actions .backlink {
  padding-left: 32px;
  background: url('../public/icons/arrow-left-blue.svg') no-repeat left center;
}
.error-wrapper .general-error .actions .refresh {
  padding-left: 24px;
  background: url('../public/icons/arrow-clockwise.svg') no-repeat left center;
}
.error-wrapper .general-error .actions .logout {
  padding-left: 24px;
  background: url('../public/icons/box-arrow-right-blue.svg') no-repeat left center;
}
@keyframes slideInDisconnect {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes slideInDisconnect {
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes slideInDisconnect {
  to {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes slideInDisconnect {
  to {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes slideInReconnect {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes slideInReconnect {
  to {
    opacity: 1;
  }
}
@-moz-keyframes slideInReconnect {
  to {
    opacity: 1;
  }
}
@-o-keyframes slideInReconnect {
  to {
    opacity: 1;
  }
}
@keyframes slideOutReconnect {
  to {
    transform: translateY(-12px);
  }
}
@-webkit-keyframes slideOutReconnect {
  to {
    -webkit-transform: translateY(-12px);
  }
}
@-moz-keyframes slideOutReconnect {
  to {
    -moz-transform: translateY(-12px);
  }
}
@-o-keyframes slideOutReconnect {
  to {
    -o-transform: translateY(-12px);
  }
}
.connection-error-disconnected {
  position: fixed;
  top: 2px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  z-index: 10001;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(-12px);
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  -o-transform: translateY(-12px);
  animation: slideInDisconnect 200ms ease-out forwards;
  -webkit-animation: slideInDisconnect 200ms ease-out forwards;
  -moz-animation: slideInDisconnect 200ms ease-out forwards;
  -o-animation: slideInDisconnect 200ms ease-out forwards;
}
.connection-error-disconnected img {
  width: 10px;
  height: 10px;
  filter: brightness(0) saturate(100%) invert(29%) sepia(94%) saturate(2152%) hue-rotate(335deg) brightness(88%) contrast(94%);
  opacity: 0.8;
}
.connection-error-disconnected .connection-message {
  font-size: 10px;
  line-height: 10px;
  color: #dc3545;
}
.connection-error-reconnected {
  position: fixed;
  top: 2px;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  z-index: 10001;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: slideInReconnect 200ms ease-out forwards, slideOutReconnect 200ms ease-in 3000ms forwards;
  -webkit-animation: slideInReconnect 200ms ease-out forwards, slideOutReconnect 200ms ease-in 3000ms forwards;
  -moz-animation: slideInReconnect 200ms ease-out forwards, slideOutReconnect 200ms ease-in 3000ms forwards;
  -o-animation: slideInReconnect 200ms ease-out forwards, slideOutReconnect 200ms ease-in 3000ms forwards;
}
.connection-error-reconnected img {
  width: 10px;
  height: 10px;
  filter: brightness(0) saturate(100%) invert(49%) sepia(79%) saturate(442%) hue-rotate(81deg) brightness(92%) contrast(92%);
}
.connection-error-reconnected .connection-message {
  font-size: 10px;
  line-height: 10px;
  color: #28a745;
}
.connection-error-icon-preloader {
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}
.multiselect-container > div.optionListContainer {
  overscroll-behavior: auto;
}
.multiselect-container > div.optionListContainer ::-webkit-scrollbar {
  width: 13px;
  background-color: #f4f5f7;
}
.multiselect-container > div.optionListContainer ::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 2px;
  border-right: 8px solid #f4f5f7;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.multiselect-container > div.optionListContainer ::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.multiselect-container > div.optionListContainer ::after {
  content: '';
  width: 8px;
  background-color: white;
  position: absolute;
  border-top: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  bottom: 0;
  right: 0;
}
.multiselect-container > div.optionListContainer ul {
  border-color: #dee2e6;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
}
.multiselect-container > div.optionListContainer ul li.option {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 2px 5px 10px;
  overflow-wrap: break-word;
  word-break: break-word;
}
.multiselect-container > div.optionListContainer ul li.option input[type='checkbox'] {
  margin-top: 5px;
  margin-right: 8px;
}
.multiselect-container > div.optionListContainer ul li.option.searched-keyword-multiselect {
  background-color: #348cf9 !important;
  color: white;
}
.multiselect-container > div.optionListContainer ul li.option:hover {
  background-color: #348cf9 !important;
}
.multiselect-container > div.optionListContainer .multiselect-clear-btn-wrap {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-left: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
}
.multiselect-container > div.optionListContainer .multiselect-clear-btn {
  cursor: pointer !important;
  color: #348cf9 !important;
  padding: 4px !important;
  margin: 0 !important;
  margin-right: 4px !important;
  background-color: #ffffff !important;
  width: fit-content !important;
  height: fit-content !important;
  font-size: 16px !important;
}
.input-container.icon-select-downarrow.dropdownToggled {
  background: url('../public/icons/chevron-up.svg') #ffffff no-repeat calc(100% - 16px) center;
}
.input-container.icon-select-downarrow.dropdownUntoggled {
  background: url('../public/icons/chevron-down.svg') #ffffff no-repeat calc(100% - 16px) center;
}
ul.optionContainer li.option.disabled-option {
  pointer-events: none !important;
  color: #adb5bd !important;
}
ul.optionContainer li.option.disabled-option input {
  pointer-events: none !important;
}
.singleCustom ul.optionContainer {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-top: 1px solid #dee2e6 !important;
}
.singleCustom ul.optionContainer li.option {
  padding: 5px 2px 5px 15px !important;
}
.singleCustom ul.optionContainer li.option:not(.singleCustom ul.optionContainer li.option.disabled-option):hover {
  background-color: #348cf9;
}
.singleCustom ul.optionContainer li.option.disabled-option {
  pointer-events: none !important;
  color: #adb5bd !important;
}
.singleCustom ul.optionContainer li.option.disabled-option input {
  pointer-events: none !important;
}
.singleCustom div.search-wrapper span.chip img.icon_cancel {
  cursor: pointer;
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
  margin-top: 2px;
}
div.search-wrapper.searchWrapper.singleSelect span.chip img.icon_cancel {
  cursor: pointer;
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
  margin-top: 2px;
}
.input-container.without-single-select-clear img.icon_cancel.closeIcon {
  opacity: 0 !important;
  pointer-events: none !important;
}
.multiselect-container div.search-wrapper img.icon_down_dir {
  opacity: 0 !important;
  pointer-events: none !important;
}
.multiselect-container div.search-wrapper .multiselect-arrow-hover {
  width: 37px;
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.input-container.disabled div.search-wrapper.searchWrapper.singleSelect span.chip img.icon_cancel {
  opacity: 0;
  pointer-events: none;
}
.new-icon-button-wrap {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.new-icon-button-wrap.disabled {
  cursor: default;
}
.new-icon-button-wrap.disabled .new-icon-button {
  opacity: 0.4;
}
.new-icon-button-wrap.disabled .outer-text {
  opacity: 0.4;
}
.new-icon-button-wrap.icon-right .new-icon-button {
  flex-direction: row-reverse;
}
.new-icon-button-wrap .new-icon-button {
  padding: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 6px;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, color 0.1s ease-in-out;
}
.new-icon-button-wrap .new-icon-button .img-wrap {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  position: relative;
}
.new-icon-button-wrap .new-icon-button img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}
.new-icon-button-wrap .new-icon-button .inner-text {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
  transition: color 0.1s ease-in-out;
}
.new-icon-button-wrap .new-icon-button .notif-dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #348cf9;
  position: absolute;
  right: -6px;
  top: -6px;
  transition: background-color 0.1s ease-in-out;
}
.new-icon-button-wrap .new-icon-button .notif-dot.error-dot {
  background-color: #dc3545;
}
@media (max-width: 480px) {
  .new-icon-button-wrap.feedback .new-icon-button {
    padding-right: 20px;
  }
}
.new-icon-button-wrap.bordered {
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
}
.new-icon-button-wrap.bordered.default .new-icon-button {
  border-color: #000000;
}
.new-icon-button-wrap.bordered.blue .new-icon-button {
  border-color: #348cf9;
}
.new-icon-button-wrap.bordered.red .new-icon-button {
  border-color: #dc3545;
}
.new-icon-button-wrap.bordered.yellow .new-icon-button {
  border-color: #ffd041;
}
.new-icon-button-wrap.bordered.green .new-icon-button {
  border-color: #28a745;
}
.new-icon-button-wrap .outer-text {
  font-size: 10px;
  color: #7b7c7c;
  margin-top: 4px;
  line-height: 10px;
  font-weight: 600;
  transition: color 0.1s ease-in-out;
}
.new-icon-button-wrap.hover-type-default.filled .new-icon-button,
.new-icon-button-wrap.hover-type-default:hover .new-icon-button {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.new-icon-button-wrap.hover-type-blue.filled .new-icon-button,
.new-icon-button-wrap.hover-type-blue:hover .new-icon-button {
  background-color: #348cf9 !important;
  border-color: #348cf9 !important;
}
.new-icon-button-wrap.hover-type-red.filled .new-icon-button,
.new-icon-button-wrap.hover-type-red:hover .new-icon-button {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.new-icon-button-wrap.hover-type-yellow.filled .new-icon-button,
.new-icon-button-wrap.hover-type-yellow:hover .new-icon-button {
  background-color: #ffd041 !important;
  border-color: #ffd041 !important;
}
.new-icon-button-wrap.hover-type-green.filled .new-icon-button,
.new-icon-button-wrap.hover-type-green:hover .new-icon-button {
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.new-icon-button-wrap.default.filled .new-icon-button,
.new-icon-button-wrap.default.with-fill:hover .new-icon-button {
  background-color: #000000;
  border-color: #000000;
}
.new-icon-button-wrap.default .new-icon-button img {
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
}
.new-icon-button-wrap.blue.disabled-pointer-events {
  pointer-events: none;
}
.new-icon-button-wrap.blue.filled .new-icon-button,
.new-icon-button-wrap.blue.with-fill:hover .new-icon-button {
  background-color: #348cf9;
  border-color: #348cf9;
}
.new-icon-button-wrap.blue .new-icon-button img {
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
}
.new-icon-button-wrap.red.filled .new-icon-button,
.new-icon-button-wrap.red.with-fill:hover .new-icon-button {
  background-color: #dc3545;
  border-color: #dc3545;
}
.new-icon-button-wrap.red .new-icon-button img {
  filter: invert(35%) sepia(34%) saturate(7269%) hue-rotate(335deg) brightness(89%) contrast(93%);
}
.new-icon-button-wrap.yellow.disabled-pointer-events {
  pointer-events: none;
}
.new-icon-button-wrap.yellow.filled .new-icon-button,
.new-icon-button-wrap.yellow.with-fill:hover .new-icon-button {
  background-color: #ffd041;
  border-color: #ffd041;
}
.new-icon-button-wrap.yellow .new-icon-button img {
  filter: invert(100%) sepia(65%) saturate(6008%) hue-rotate(321deg) brightness(101%) contrast(101%);
}
.new-icon-button-wrap.green.filled .new-icon-button,
.new-icon-button-wrap.green.with-fill:hover .new-icon-button {
  background-color: #28a745;
  border-color: #28a745;
}
.new-icon-button-wrap.green .new-icon-button img {
  filter: invert(55%) sepia(11%) saturate(3554%) hue-rotate(82deg) brightness(93%) contrast(75%);
}
.new-icon-button-wrap.filled .new-icon-button img,
.new-icon-button-wrap.with-fill:hover .new-icon-button img {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
}
.new-icon-button-wrap.filled .new-icon-button .inner-text,
.new-icon-button-wrap.with-fill:hover .new-icon-button .inner-text {
  color: #ffffff;
}
.new-icon-button-wrap.filled.default .outer-text,
.new-icon-button-wrap.with-fill:hover.default .outer-text {
  color: #000000;
}
.new-icon-button-wrap.filled.hover-type-default .outer-text,
.new-icon-button-wrap.with-fill:hover.hover-type-default .outer-text {
  color: #000000;
}
.new-icon-button-wrap.filled.blue .outer-text,
.new-icon-button-wrap.with-fill:hover.blue .outer-text {
  color: #348cf9;
}
.new-icon-button-wrap.filled.hover-type-blue .outer-text,
.new-icon-button-wrap.with-fill:hover.hover-type-blue .outer-text {
  color: #348cf9;
}
.new-icon-button-wrap.filled.red .outer-text,
.new-icon-button-wrap.with-fill:hover.red .outer-text {
  color: #dc3545;
}
.new-icon-button-wrap.filled.hover-type-red .outer-text,
.new-icon-button-wrap.with-fill:hover.hover-type-red .outer-text {
  color: #dc3545;
}
.new-icon-button-wrap.filled.yellow .outer-text,
.new-icon-button-wrap.with-fill:hover.yellow .outer-text {
  color: #ffd041;
}
.new-icon-button-wrap.filled.hover-type-yellow .outer-text,
.new-icon-button-wrap.with-fill:hover.hover-type-yellow .outer-text {
  color: #ffd041;
}
.new-icon-button-wrap.filled.green .outer-text,
.new-icon-button-wrap.with-fill:hover.green .outer-text {
  color: #28a745;
}
.new-icon-button-wrap.filled.hover-type-green .outer-text,
.new-icon-button-wrap.with-fill:hover.hover-type-green .outer-text {
  color: #28a745;
}
.new-icon-button-wrap:hover.default .outer-text {
  color: #000000;
}
.new-icon-button-wrap:hover.hover-type-default .outer-text {
  color: #000000;
}
.new-icon-button-wrap:hover.blue .outer-text {
  color: #348cf9;
}
.new-icon-button-wrap:hover.hover-type-blue .outer-text {
  color: #348cf9;
}
.new-icon-button-wrap:hover.red .outer-text {
  color: #dc3545;
}
.new-icon-button-wrap:hover.hover-type-red .outer-text {
  color: #dc3545;
}
.new-icon-button-wrap:hover.yellow .outer-text {
  color: #ffd041;
}
.new-icon-button-wrap:hover.hover-type-yellow .outer-text {
  color: #ffd041;
}
.new-icon-button-wrap:hover.green .outer-text {
  color: #28a745;
}
.new-icon-button-wrap:hover.hover-type-green .outer-text {
  color: #28a745;
}
@media (min-width: 481px) {
  .icon-button-tooltip {
    max-width: 246px;
  }
}
.icon-button-tooltip.tooltip-type-blue {
  border-color: #348cf9;
}
.icon-button-tooltip.tooltip-type-red {
  border-color: #dc3545;
}
.icon-button-tooltip.tooltip-type-yellow {
  border-color: #ffd041;
}
.icon-button-tooltip.tooltip-type-green {
  border-color: #28a745;
}
.shared-popup {
  position: fixed;
  top: -200px;
  bottom: -200px;
  left: -200px;
  right: -200px;
  padding: 200px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  backdrop-filter: blur(5px);
}
@media (min-width: 481px) {
  .shared-popup {
    padding: 200px;
  }
}
@media (max-width: 480px) {
  .shared-popup {
    padding: 200px calc(200px + 24px);
  }
}
.shared-fund-details-popup-body {
  margin: auto;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body {
    margin-top: 24px;
    padding: 24px 0;
    width: 100%;
    max-height: calc(100dvh - 24px - 24px);
  }
}
@media (min-width: 481px) {
  .shared-fund-details-popup-body {
    width: 712px;
    min-height: 520px;
  }
}
.shared-fund-details-popup-body .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #348cf9;
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .header {
    flex-direction: column-reverse;
    gap: 12px;
    padding: 0 24px;
  }
}
.shared-fund-details-popup-body .header .tabs {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .header .tabs {
    justify-content: space-around;
    overflow-x: auto;
  }
}
.shared-fund-details-popup-body .header .tabs .tab {
  padding: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #000000;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .header .tabs .tab {
    font-size: 14px;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.shared-fund-details-popup-body .header .tabs .tab.active {
  color: #ffffff;
  background-color: #348cf9;
}
.shared-fund-details-popup-body .header .close {
  background: url('../public/icons/x.svg') no-repeat right center;
  padding-right: 20px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .header .close {
    text-align: right;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
  }
}
.shared-fund-details-popup-body .content {
  overflow-y: auto;
  flex: 1;
}
@media (min-width: 481px) {
  .shared-fund-details-popup-body .content::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
  }
  .shared-fund-details-popup-body .content::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
    border-radius: 2px;
    border-radius: 9px;
  }
  .shared-fund-details-popup-body .content::-webkit-scrollbar-thumb:hover {
    background-color: #7b7c7c;
  }
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .content {
    padding: 0 24px;
  }
}
.shared-fund-details-popup-body .content .row {
  padding: 8px 0;
  border-bottom: 0.5px solid #adb5bd;
  display: flex;
  align-items: center;
}
.shared-fund-details-popup-body .content .row:last-of-type {
  border-bottom: 0;
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .content .row {
    padding: 16px 0;
    height: fit-content;
  }
}
.shared-fund-details-popup-body .content .row .bullet {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .shared-fund-details-popup-body .content .row .bullet {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .content .row .bullet {
    display: none;
  }
}
.shared-fund-details-popup-body .content .row .value {
  white-space: pre-wrap;
}
.shared-fund-details-popup-body .content .row .value,
.shared-fund-details-popup-body .content .row .label {
  font-size: 12px;
  line-height: 12px;
}
.shared-fund-details-popup-body .content .row .label {
  font-weight: 600;
  padding-left: 12px;
  width: 230px;
}
@media (min-width: 481px) {
  .shared-fund-details-popup-body .content .row .label {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .shared-fund-details-popup-body .content .row .label {
    flex: 0 0 50%;
  }
}
.shared-fund-details-popup-body .content .field {
  margin-bottom: 20px;
}
.shared-fund-details-popup-body .content .field.readonly {
  display: flex;
}
.shared-fund-details-popup-body .content .field.readonly label {
  width: 120px;
}
.shared-fund-details-popup-body .content .field label {
  padding-left: 16px;
}
.shared-fund-details-popup-body .content .actions {
  display: flex;
  justify-content: flex-end;
}
.shared-fund-details-popup-body .content .s-dct-info-wrap {
  display: flex;
  flex-direction: column;
}
.shared-fund-details-popup-body .content .s-dct-info-wrap .s-dct-user-name {
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  font-weight: 600;
}
.shared-fund-details-popup-body .content .s-dct-info-wrap .s-dct-company-name {
  font-size: 20px;
  line-height: 28px;
  color: #000000;
  font-weight: 400;
}
.shared-fund-details-popup-body .content .s-dct-info-wrap .s-dct-separator {
  width: 100%;
  height: 0.5px;
  background-color: #7b7c7c;
  margin-top: 20px;
  margin-bottom: 30px;
}
.shared-fund-details-popup-body .content .s-dct-info-wrap .s-dct-rows-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}
.shared-fund-details-popup-body .content .s-dct-info-wrap .s-dct-rows-wrap .s-dct-row {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
.shared-fund-details-popup-body .content .s-dct-info-wrap .s-dct-rows-wrap .s-dct-row .s-dct-row-icon {
  width: 16px;
  height: 16px;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
}
.shared-fund-details-popup-body .content .s-dct-info-wrap .s-dct-rows-wrap .s-dct-row .s-dct-row-text {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.existing-outreach-popup-closer {
  position: fixed;
  top: -200px;
  bottom: -200px;
  left: -200px;
  right: -200px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.existing-outreach-popup {
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 60px 32px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 480px) {
  .existing-outreach-popup {
    max-width: 90dvw;
  }
}
.existing-outreach-popup .title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: #000000;
}
.existing-outreach-popup .input-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
}
.existing-outreach-popup .input-wrap .new-icon-button-wrap {
  width: 222px;
}
.existing-outreach-popup .input-wrap .new-icon-button-wrap .new-icon-button {
  width: 100%;
  justify-content: center;
  border-radius: 6px;
  padding: 10px 0px;
}
.existing-outreach-popup .input-wrap .new-icon-button-wrap .new-icon-button .inner-text {
  font-size: 16px;
}
.app-body.page-outreach {
  padding-bottom: 40px;
  padding-top: 120px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .desktop-only {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  .app-body.page-outreach .mobile-only {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .app-body.page-outreach {
    padding: 80px 24px 40px 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-outreach {
    padding: 0px 24px 40px 24px;
  }
}
.app-body.page-outreach #mobile-outreach-list-no-pointer-events {
  pointer-events: none;
}
.app-body.page-outreach .mobile-header {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #ffffff;
  z-index: 1;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .mobile-header {
    top: 80px;
  }
}
@media (max-width: 480px) {
  .app-body.page-outreach .mobile-header {
    top: 52px;
  }
}
.app-body.page-outreach .mobile-header .tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #348cf9;
}
.app-body.page-outreach .mobile-header .tabs.agent {
  column-gap: 10px;
}
.app-body.page-outreach .mobile-header .tabs.investor {
  justify-content: space-between;
}
.app-body.page-outreach .mobile-header .tabs.investor .tab:not(.active) {
  flex-grow: 1;
}
.app-body.page-outreach .mobile-header .tabs .tab {
  cursor: pointer;
  position: relative;
  height: 55px;
  padding: 6px 8px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.app-body.page-outreach .mobile-header .tabs .tab.team-feedback .img-wrap .count {
  background-color: #76b3ff;
  background-image: linear-gradient(160deg, #76b3ff 0%, #28bbfa 100%);
}
.app-body.page-outreach .mobile-header .tabs .tab.team-feedback .img-wrap img {
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
}
.app-body.page-outreach .mobile-header .tabs .tab.active {
  background-color: #348cf9;
  cursor: default;
}
.app-body.page-outreach .mobile-header .tabs .tab.active.team-feedback {
  background-color: #76b3ff;
  background-image: linear-gradient(160deg, #76b3ff 0%, #28bbfa 100%);
}
.app-body.page-outreach .mobile-header .tabs .tab.active.clicked .img-wrap {
  animation: show-outreach-tab-icon 400ms 67ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  opacity: 0;
  transform: translateY(-50%) rotateX(-90deg);
  transform-origin: bottom center;
}
@keyframes show-outreach-tab-icon {
  100% {
    opacity: 1;
    transform: none;
  }
}
.app-body.page-outreach .mobile-header .tabs .tab.active.clicked .text {
  animation: show-outreach-tab-text 300ms linear alternate;
}
@keyframes show-outreach-tab-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.app-body.page-outreach .mobile-header .tabs .tab.active .img-wrap img {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
}
.app-body.page-outreach .mobile-header .tabs .tab.active .text {
  display: block;
  white-space: nowrap;
}
.app-body.page-outreach .mobile-header .tabs .tab.clicked:not(.active) .img-wrap {
  animation: collapse-outreach-tab-icon 400ms 67ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  opacity: 0;
  transform: rotateX(90deg);
  transform-origin: top center;
}
@keyframes collapse-outreach-tab-icon {
  100% {
    opacity: 1;
    transform: none;
  }
}
.app-body.page-outreach .mobile-header .tabs .tab .img-wrap {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  position: relative;
}
.app-body.page-outreach .mobile-header .tabs .tab .img-wrap .count {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 16px;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px;
  background-color: #348cf9;
  border-radius: 8px;
  font-size: 9px;
  line-height: 16px;
  font-weight: 400;
  color: #ffffff;
  transform: translate(50%, 50%);
}
.app-body.page-outreach .mobile-header .tabs .tab .img-wrap img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
}
.app-body.page-outreach .mobile-header .tabs .tab .text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #ffffff;
  display: none;
}
.app-body.page-outreach .mobile-header .subtabs {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  padding: 11px 8px 14px 8px;
  margin-left: -8px;
  margin-right: -8px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .mobile-header .subtabs::-webkit-scrollbar {
    height: 5px;
    background-color: #f4f5f7;
  }
  .app-body.page-outreach .mobile-header .subtabs::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
    border-radius: 9px;
  }
  .app-body.page-outreach .mobile-header .subtabs::-webkit-scrollbar-thumb:hover {
    background-color: #7b7c7c;
  }
}
.app-body.page-outreach .mobile-header .subtabs .subtab {
  min-width: 78px;
  max-width: 78px;
  width: 78px;
  height: 88px;
  border-radius: 8px;
  background-color: #e9ecef;
  position: relative;
  outline: 3px solid transparent;
  cursor: pointer;
  animation: popout-subheader-outreach 300ms ease;
  -webkit-animation: popout-subheader-outreach 300ms ease;
}
@keyframes popout-subheader-outreach {
  from {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes popout-subheader-outreach {
  from {
    -webkit-transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(1);
  }
}
.app-body.page-outreach .mobile-header .subtabs .subtab.active {
  cursor: default;
  outline: 3px solid rgba(118, 179, 255, 0.5);
  background-color: #348cf9;
}
.app-body.page-outreach .mobile-header .subtabs .subtab.active .text {
  color: #ffffff;
}
.app-body.page-outreach .mobile-header .subtabs .subtab.active .img-wrap img {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
}
.app-body.page-outreach .mobile-header .subtabs .subtab.active .cout {
  display: none;
}
.app-body.page-outreach .mobile-header .subtabs .subtab .text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #000000;
  max-width: 60px;
  top: 14px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@supports (-webkit-line-clamp: 2) {
  .app-body.page-outreach .mobile-header .subtabs .subtab .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.app-body.page-outreach .mobile-header .subtabs .subtab .img-wrap {
  width: 16px;
  height: 16px;
  top: 58px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}
.app-body.page-outreach .mobile-header .subtabs .subtab .img-wrap img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
}
.app-body.page-outreach .mobile-header .subtabs .subtab .count {
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 9px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 8px;
  transform: translate(50%, 50%);
}
.app-body.page-outreach .mobile-header .fade-left {
  position: absolute;
  bottom: 5px;
  left: calc(24px - 8px);
  width: 8px;
  height: 119px;
  background-color: transparent;
  background-image: linear-gradient(90deg, #ffffff 0%, transparent 100%);
}
.app-body.page-outreach .mobile-header .fade-right {
  position: absolute;
  bottom: 5px;
  right: calc(24px - 8px);
  width: 8px;
  height: 119px;
  background-color: transparent;
  background-image: linear-gradient(90deg, transparent 0%, #ffffff 100%);
}
.app-body.page-outreach .layout-wrap {
  width: 100%;
  height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: stretch;
}
.app-body.page-outreach .sections-wrap {
  background-color: #f4f5f7;
  width: 222px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.app-body.page-outreach .sections-wrap .fade-top {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: 7px;
  width: 208px;
  height: 12px;
  background-image: linear-gradient(to bottom, #f4f5f7, rgba(244, 245, 247, 0));
}
.app-body.page-outreach .sections-wrap .fade-bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 7px;
  width: 208px;
  height: 12px;
  background-image: linear-gradient(to top, #f4f5f7, rgba(244, 245, 247, 0));
}
.app-body.page-outreach .sections-wrap .title {
  height: 50px;
  font-size: 20px;
  color: #348cf9;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border-bottom: 0.5px solid #7b7c7c;
}
.app-body.page-outreach .sections-wrap .scrollable-body {
  overflow-y: auto;
  overflow-x: clip;
  flex: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: inherit;
}
.app-body.page-outreach .sections-wrap .scrollable-body::-webkit-scrollbar {
  width: 5px;
  background-color: #f4f5f7;
}
.app-body.page-outreach .sections-wrap .scrollable-body::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 9px;
}
.app-body.page-outreach .sections-wrap .scrollable-body::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.app-body.page-outreach .sections-wrap .scrollable-body .separator {
  width: 190px;
  height: 0.5px;
  margin-left: 16px;
  background-color: #7b7c7c;
  margin-top: 12px;
}
.app-body.page-outreach .sections-wrap .scrollable-body .separator:last-of-type {
  margin-bottom: 12px;
}
.app-body.page-outreach .collapsable-section-head {
  background-color: transparent;
  width: 206px;
  margin: 12px 0px 0px 8px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.app-body.page-outreach .collapsable-section-head:last-child {
  margin-bottom: 12px;
}
.app-body.page-outreach .collapsable-section-head:hover {
  background-color: #dee2e6;
}
.app-body.page-outreach .collapsable-section-head .icon-title {
  margin-right: 8px;
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
  width: 16px;
  height: 16px;
}
.app-body.page-outreach .collapsable-section-head .head-title {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.app-body.page-outreach .collapsable-section-head .head-count {
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 9px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 8px;
}
.app-body.page-outreach .collapsable-section-head .icon-collapse {
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
  width: 16px;
  height: 16px;
  margin-left: 8px;
}
.app-body.page-outreach .agent-project-button {
  background-color: transparent;
  width: 206px;
  margin: 8px 0px 0px 8px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.app-body.page-outreach .agent-project-button:hover {
  background-color: #dee2e6;
}
.app-body.page-outreach .agent-project-button.selected {
  background-color: #348cf9;
}
.app-body.page-outreach .agent-project-button.selected:hover {
  cursor: default;
  background-color: #348cf9;
}
.app-body.page-outreach .agent-project-button.selected .icon-title {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
}
.app-body.page-outreach .agent-project-button.selected .head-title {
  color: #ffffff;
}
.app-body.page-outreach .agent-project-button.selected .head-count {
  color: #348cf9;
  background-color: #ffffff;
}
.app-body.page-outreach .agent-project-button .icon-title {
  margin-right: 8px;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
  width: 16px;
  height: 16px;
}
.app-body.page-outreach .agent-project-button .head-title {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.app-body.page-outreach .agent-project-button .head-count {
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 9px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 8px;
}
.app-body.page-outreach .recent-outreach-button {
  background-color: transparent;
  width: 206px;
  margin: 8px 0px 0px 8px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.app-body.page-outreach .recent-outreach-button:hover {
  background-color: #dee2e6;
}
.app-body.page-outreach .recent-outreach-button.selected {
  background-color: #348cf9;
}
.app-body.page-outreach .recent-outreach-button.selected:hover {
  cursor: default;
  background-color: #348cf9;
}
.app-body.page-outreach .recent-outreach-button.selected .name-user {
  color: #ffffff;
}
.app-body.page-outreach .recent-outreach-button.selected .msg-count {
  color: #348cf9;
  background-color: #ffffff;
}
.app-body.page-outreach .recent-outreach-button.selected .date {
  color: #ffffff;
}
.app-body.page-outreach .recent-outreach-button .icon-user {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}
.app-body.page-outreach .recent-outreach-button .name-user {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.app-body.page-outreach .recent-outreach-button .msg-count {
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 9px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
}
.app-body.page-outreach .recent-outreach-button .date {
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #7b7c7c;
}
.app-body.page-outreach .team-feedback-button {
  border-top: 0.5px solid #7b7c7c;
  height: 50px;
  background-color: transparent;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
  transition: all 0.1s ease-in-out;
}
.app-body.page-outreach .team-feedback-button.selected {
  background-color: #348cf9;
}
.app-body.page-outreach .team-feedback-button.selected:hover {
  cursor: default;
  background-color: #348cf9;
}
.app-body.page-outreach .team-feedback-button.selected .tfb-left-icon {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
}
.app-body.page-outreach .team-feedback-button.selected .tfb-title {
  color: #ffffff;
}
.app-body.page-outreach .team-feedback-button.selected .tfb-count {
  color: #348cf9;
  background-color: #ffffff;
}
.app-body.page-outreach .team-feedback-button.selected .tfb-right-icon {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
}
.app-body.page-outreach .team-feedback-button:hover {
  cursor: pointer;
  background-color: #dee2e6;
}
.app-body.page-outreach .team-feedback-button .tbf-content-wrap {
  flex: 1;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.app-body.page-outreach .team-feedback-button .tfb-left-icon {
  width: 16px;
  height: 16px;
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
}
.app-body.page-outreach .team-feedback-button .tfb-title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #000000;
}
.app-body.page-outreach .team-feedback-button .tfb-count {
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 9px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
}
.app-body.page-outreach .team-feedback-button .tfb-right-icon {
  width: 16px;
  height: 16px;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
}
.app-body.page-outreach .right-container {
  background-color: #ffffff;
  border: 0.5px solid #adb5bd;
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.app-body.page-outreach .outreaches-container-wrap {
  width: 236px;
  border-right: 0.5px solid #adb5bd;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.app-body.page-outreach .outreaches-container-wrap .fade-top {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 11px;
  width: 213px;
  height: 12px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
}
.app-body.page-outreach .outreaches-container-wrap .fade-bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 11px;
  width: 213px;
  height: 12px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
}
.app-body.page-outreach .outreaches-container-wrap .tfb-header {
  width: 236px;
  height: 50px;
  border-bottom: 0.5px solid #adb5bd;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}
.app-body.page-outreach .outreaches-container-wrap .tfb-header .tfb-header-icon {
  width: 16px;
  height: 16px;
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
}
.app-body.page-outreach .outreaches-container-wrap .tfb-header .tfb-header-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #000000;
}
.app-body.page-outreach .outreaches-container {
  width: 236px;
  flex: 1;
  overflow-y: auto;
  overflow-x: clip;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  position: relative;
}
.app-body.page-outreach .outreaches-container::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  border-right: 0.5px solid #adb5bd;
}
.app-body.page-outreach .outreaches-container::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 2px;
  border-radius: 9px;
}
.app-body.page-outreach .outreaches-container::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.app-body.page-outreach .outreaches-container .collapsable-section-head {
  background-color: transparent;
  width: 211px;
  margin: 12px 0px 0px 12px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.app-body.page-outreach .outreaches-container .collapsable-section-head:hover {
  background-color: #f4f5f7;
}
.app-body.page-outreach .outreaches-container .separator {
  width: 211px;
  height: 0.5px;
  margin-left: 12px;
  background-color: #adb5bd;
  margin-top: 12px;
}
.app-body.page-outreach .outreaches-container .separator:last-of-type {
  margin-bottom: 12px;
}
.app-body.page-outreach .outreach-box {
  width: 211px;
  margin-top: 12px;
  margin-left: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  background-color: transparent;
  transition: all 0.1s ease-in-out;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box {
    margin-top: 16px;
    box-shadow: 0px 5px 20px 0px #0000001a;
    border-radius: 8px;
    background-color: #ffffff;
    margin-left: 0;
    border: none !important;
    width: calc(100dvw - 24px - 24px);
    animation: show-outreach-box-mobile 200ms linear;
  }
  @keyframes show-outreach-box-mobile {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.app-body.page-outreach .outreach-box:last-child {
  margin-bottom: 12px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box:last-child {
    margin-bottom: 16px;
  }
}
.app-body.page-outreach .outreach-box.selected {
  border: 1px solid #348cf9;
  background-color: #f4f5f7;
}
.app-body.page-outreach .outreach-box.selected:hover {
  cursor: default;
  border: 1px solid #348cf9;
  background-color: #f4f5f7;
}
.app-body.page-outreach .outreach-box:hover {
  background-color: #f4f5f7;
  border: 1px solid #f4f5f7;
  cursor: pointer;
}
.app-body.page-outreach .outreach-box .type {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .type {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 24px;
    margin-top: 5px;
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box {
  width: 145px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box {
    width: calc(100dvw - 24px - 24px - 8px - 8px - 50px - 8px);
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .top-info {
  width: inherit;
  display: flex;
  flex-direction: row;
  margin-top: -4px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .top-info {
    margin-top: 0;
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .top-info .title {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .top-info .title {
    font-size: 16px;
    line-height: 24px;
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .top-info .date {
  margin-left: 4px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #7b7c7c;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .top-info .date {
    font-size: 16px;
    line-height: 24px;
    margin-top: -4px;
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info {
  display: flex;
  flex-direction: row;
  width: inherit;
  align-items: flex-end;
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap {
  width: 123px;
  margin-right: 4px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap {
    width: calc(100dvw - 24px - 24px - 8px - 8px - 50px - 8px - 4px - 24px);
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap.double-digit {
  width: 116px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap.double-digit {
    width: calc(100dvw - 24px - 24px - 8px - 8px - 50px - 8px - 4px - 29px);
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap.triple-digit {
  width: 109px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap.triple-digit {
    width: calc(100dvw - 24px - 24px - 8px - 8px - 50px - 8px - 4px - 34px);
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap.quadruple-digit {
  width: 102px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap.quadruple-digit {
    width: calc(100dvw - 24px - 24px - 8px - 8px - 50px - 8px - 4px - 39px);
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap .text-top {
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #7b7c7c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap .text-top {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0;
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap .text-bottom {
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .text-wrap .text-bottom {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0;
  }
}
.app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .unread-count {
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 9px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .outreach-box .outreach-info-box .bottom-info .unread-count {
    height: 24px;
    min-width: 24px;
    padding: 0 8px;
    border-radius: 12px;
  }
}
.app-body.page-outreach .chat-wrap {
  flex: 1;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap {
    position: fixed;
    border-radius: 0 !important;
    width: 100dvw;
    top: 80px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background-color: #ffffff;
  }
  .app-body.page-outreach .chat-wrap.safariui {
    position: absolute !important;
  }
}
@media (max-width: 480px) {
  .app-body.page-outreach .chat-wrap {
    position: fixed;
    top: 52px;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .app-body.page-outreach .chat-wrap.safariui {
    position: absolute !important;
  }
}
.app-body.page-outreach .chat-wrap .chat-head-mobile {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-left: 20px;
  border-bottom: 0.5px solid #adb5bd;
  width: calc(100dvw - 20px - 20px);
  height: 76px;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile #mobile-chat-header-back-arrow {
  margin-right: 10px;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  background-color: #f2d2d6;
  margin-right: 5px;
  flex-shrink: 0;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info {
  width: calc(100dvw - 20px - 20px - 38px - 28px - 55px);
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info .top-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info .top-info #mobile-chat-header-info-icon-button .new-icon-button {
  padding-left: 0;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info .top-info .text {
  width: calc(100dvw - 20px - 20px - 38px - 28px - 55px - 28px);
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info .top-info .text .extra-info {
  font-weight: 400;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info .middle-info {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info .middle-separator {
  height: 4px;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile .mobile-chat-info .bottom-info {
  width: calc(100dvw - 20px - 20px - 38px - 28px - 55px);
  color: #7b7c7c;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-body.page-outreach .chat-wrap .chat-head-mobile #mobile-chat-header-dots {
  margin-left: 5px;
}
.app-body.page-outreach .chat-wrap .chat-head .head-menu-closer,
.app-body.page-outreach .chat-wrap .chat-head-mobile .head-menu-closer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 3;
}
.app-body.page-outreach .chat-wrap .chat-head .head-menu-wrap,
.app-body.page-outreach .chat-wrap .chat-head-mobile .head-menu-wrap {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #348cf9;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 4;
  right: 10px;
  top: 50px;
  border-radius: 4px;
  box-shadow: 0px 5px 20px 0px #00000040;
  display: flex;
  flex-direction: column;
  width: 224px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-head .head-menu-wrap,
  .app-body.page-outreach .chat-wrap .chat-head-mobile .head-menu-wrap {
    top: 60px;
    right: 15px;
  }
}
.app-body.page-outreach .chat-wrap .chat-head .head-menu-wrap .new-icon-button-wrap,
.app-body.page-outreach .chat-wrap .chat-head-mobile .head-menu-wrap .new-icon-button-wrap {
  width: 100%;
}
.app-body.page-outreach .chat-wrap .chat-head .head-menu-wrap .new-icon-button-wrap .new-icon-button,
.app-body.page-outreach .chat-wrap .chat-head-mobile .head-menu-wrap .new-icon-button-wrap .new-icon-button {
  width: 100%;
  border-radius: 0;
  padding: 12px 24px;
  column-gap: 12px;
}
.app-body.page-outreach .chat-wrap .chat-head .head-menu-wrap .react-tooltip,
.app-body.page-outreach .chat-wrap .chat-head-mobile .head-menu-wrap .react-tooltip {
  z-index: 1;
}
.app-body.page-outreach .chat-wrap .chat-head {
  height: 68px;
  border-bottom: 0.5px solid #adb5bd;
  display: flex;
  flex-direction: row;
  padding: 8px 10px 8px 16px;
  align-items: center;
  position: relative;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback {
  flex: 1;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .top {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 20px - 28px - 28px);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .top .extra-info {
  font-weight: 400;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .top-multiuser {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 20px - 28px - 28px);
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .top-multiuser .extra-info {
  font-weight: 400;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .top-multiuser .user-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .top-multiuser .group-chat-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
  margin-left: 4px;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .bottom .new-icon-button-wrap {
  margin-left: -5px;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-feedback .bottom .object-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 20px - 28px - 28px - 20px);
  font-size: 12px;
  line-height: 28px;
  font-weight: 400;
  color: #7b7c7c;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-investor {
  flex: 1;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-investor .top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-investor .top .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 20px - 28px - 28px - 20px);
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #000000;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-investor .top .title .extra-info {
  font-weight: 400;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-investor .bottom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #7b7c7c;
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 20px - 28px - 28px);
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-agent {
  flex: 1;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-agent .top {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-agent .top .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 20px - 28px - 28px - 20px);
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #000000;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-agent .top .title .extra-info {
  font-weight: 400;
}
.app-body.page-outreach .chat-wrap .chat-head .info-wrap-agent .bottom {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #7b7c7c;
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 20px - 28px - 28px);
}
.app-body.page-outreach .chat-wrap .mobile-rating-wrap {
  background-color: #f4f5f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: -1px;
  padding: 16px 15px 16px 24px;
  border-bottom: 0.5px solid #adb5bd;
}
.app-body.page-outreach .chat-wrap .mobile-rating-wrap .text {
  color: #348cf9;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap {
  background-color: #f4f5f7;
  padding: 8px 10px 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-rating-wrap {
    flex-direction: column;
    margin-top: -1px;
    row-gap: 12px;
    padding: 16px 0 11px 0;
    border-bottom: 0.5px solid #adb5bd;
  }
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .mobile-rating-toggle {
  padding-right: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -5px;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap,
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap {
  display: flex;
  flex-direction: column;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .title,
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap .title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #000000;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap,
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap .rating-inputs-wrap {
  display: flex;
  flex-direction: row;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap {
  row-gap: 6px;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap {
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap #chat_rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap #chat_rating label {
  margin-right: 26px;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap #chat_rating label:last-child {
  margin-right: 0;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap #chat_rating label:first-child {
  margin-left: 8px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap #chat_rating label:first-child {
    margin-left: -2px;
  }
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-wrap .rating-inputs-wrap #chat_rating input {
  cursor: pointer;
  margin-left: 0;
  margin-right: 12px;
  margin-top: 0;
  width: 16px;
  height: 16px;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap {
  row-gap: 0px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap {
    align-items: center;
  }
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap .rating-inputs-wrap {
  column-gap: 8px;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap .rating-inputs-wrap .new-icon-button-wrap.selected-chat-rating-number .outer-text {
  color: #348cf9 !important;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap .rating-number-wrap .rating-inputs-wrap .new-icon-button-wrap .outer-text {
  text-align: center;
  font-size: 8px;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap #send-rate-button:hover .new-icon-button .inner-text {
  color: #ffffff;
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap #send-rate-button .new-icon-button {
  padding: 5px 20px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-rating-wrap #send-rate-button .new-icon-button {
    padding: 5px 0;
    width: 230px;
    justify-content: center;
  }
}
.app-body.page-outreach .chat-wrap .chat-rating-wrap #send-rate-button .new-icon-button .inner-text {
  color: #348cf9;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body-wrap {
  flex: 1;
  position: relative;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body-wrap .fade-top {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 30px;
  width: calc(100% - 15px - 15px - 15px - 15px);
  height: 10px;
  background: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 480px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body-wrap .fade-top.safariui {
    left: 20px;
    width: calc(100% - 15px - 15px - 5px);
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body-wrap .fade-bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 30px;
  width: calc(100% - 15px - 15px - 15px - 15px);
  height: 10px;
  background: linear-gradient(to top, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 480px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body-wrap .fade-bottom.safariui {
    left: 20px;
    width: calc(100% - 15px - 15px - 5px);
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body {
  overflow-y: scroll;
  overflow-x: clip;
  padding-left: 10px;
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 20px;
}
@media (max-width: 480px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body.safariui {
    padding-left: 0;
    right: 20px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body::-webkit-scrollbar {
  width: 15px;
  background: #ffffff;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body::-webkit-scrollbar-thumb {
  background: #adb5bd;
  border: 5px solid #ffffff;
  border-radius: 8px;
  overflow: hidden;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body::-webkit-scrollbar-thumb:hover {
  background: #7b7c7c;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .loader-wrapper {
  width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 20px - 236px - 20px - 15px - 10px - 15px - 19px);
  height: fit-content;
  margin-top: 10px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .loader-wrapper {
    width: 100%;
    margin-top: 20px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .system-message {
  width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 20px - 236px - 20px - 15px - 10px - 15px - 19px);
  margin-top: 10px;
  text-align: center;
  overflow-wrap: break-word;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #7b7c7c;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .system-message {
    width: 100%;
    margin-top: 20px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .system-message:last-child {
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .system-message:last-child {
    margin-bottom: 20px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .system-message .message-info-notif-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #348cf9;
  display: inline-block;
  margin-right: 5px;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .load-more-btn-large-height-display-fix {
  width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 20px - 236px - 20px - 15px - 10px - 15px - 19px);
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #348cf9;
  cursor: pointer;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message {
  width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 20px - 236px - 20px - 15px - 10px - 15px - 19px);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message {
    width: 100%;
    margin-top: 20px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message:last-child {
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message:last-child {
    margin-bottom: 20px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.right {
  align-items: flex-end;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.right .message-info {
  flex-direction: row-reverse;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.right.pdf-message .message-text-wrap {
  flex-direction: row-reverse;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.right .message-text-wrap {
  margin-right: calc(24px + 5px);
  border-top-right-radius: 0px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.right .message-text-wrap {
    margin-right: calc(50px + 5px);
    margin-top: -25px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.left {
  align-items: flex-start;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.left .message-text-wrap {
  margin-left: calc(24px + 5px);
  border-top-left-radius: 0px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.left .message-text-wrap {
    margin-left: calc(50px + 5px);
    margin-top: -25px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.pdf-message .message-text-wrap {
  padding: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.pdf-message .message-text-wrap .message-info-notif-dot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #348cf9;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.pdf-no-message-info {
  margin-top: 5px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message.pdf-no-message-info {
    margin-top: 29px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  align-items: flex-start;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-icon {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-icon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-size: 24px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-names {
  overflow-wrap: break-word;
  word-break: break-word;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-names {
    display: none;
  }
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-dot {
    display: none;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-names,
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-dot,
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-date {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  color: #7b7c7c;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-names,
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-dot,
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-date {
    font-size: 14px;
    line-height: 21px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-notif-dot {
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: #348cf9;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-info .message-info-notif-dot {
    margin-top: 8px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap {
  max-width: calc((100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 20px - 236px - 20px - 20px - 6px - 6px - 19px) / 4 * 3);
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap {
    max-width: calc(100dvw - 20px - 10px - 20px - 10px - 50px - 5px);
    padding: 8px 12px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .message-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  white-space: normal !important;
  overflow-wrap: break-word;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .message-text {
    font-size: 14px;
    line-height: 21px;
  }
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap {
  min-width: 142px;
  max-width: 142px;
  width: 142px;
  height: 33px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  background-color: #f4f5f7;
  border-radius: 4px;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .pdf-icon {
  width: 16px;
  height: 16px;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  flex: 1;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-top {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  max-width: 108px;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-top .doc-name {
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  cursor: pointer;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-top .doc-name:hover {
  color: #348cf9;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-top .doc-size {
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #7b7c7c;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-bottom .doc-status {
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #7b7c7c;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-bottom .new-icon-button-wrap .new-icon-button {
  padding: 2px;
}
.app-body.page-outreach .chat-wrap .chat-scrollable-body .chat-message .message-text-wrap .doc-wrap .doc-info-wrap .doc-info-bottom .new-icon-button-wrap .new-icon-button img {
  width: 9px;
  height: 9px;
}
.app-body.page-outreach .chat-wrap .inputs-wrap {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 12px 20px;
}
.app-body.page-outreach .chat-wrap .inputs-wrap #outreach_send_message {
  font-size: 12px;
  line-height: 20px;
}
@media (max-width: 1200px) {
  .app-body.page-outreach .chat-wrap .inputs-wrap #outreach_send_message {
    font-size: 16px;
    line-height: 24px;
  }
}
.app-body.page-outreach .chat-wrap .inputs-wrap #outreach_send_message:focus {
  box-shadow: none !important;
}
.app-body.page-outreach .chat-wrap .inputs-wrap #outreach_send_message.forceFocusStyle {
  box-shadow: none !important;
}
.app-body.page-outreach .chat-wrap .inputs-wrap .pdf-list {
  max-width: calc(100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 222px - 26px - 236px - 40px - 13px);
  max-width: calc(100dvw - 20px - 20px);
}
.app-body.page-outreach .chat-wrap .inputs-wrap .bottom-inputs-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .inputs-wrap .bottom-inputs-row #send_message_button .new-icon-button {
  padding: 5px 16px;
}
.app-body.page-outreach .chat-wrap .inputs-wrap .bottom-inputs-row .pdf-action {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
.app-body.page-outreach .chat-wrap .inputs-wrap .bottom-inputs-row .pdf-action .pdf-action-info {
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
  line-height: 10px;
  color: #000000;
}
.desktop-chat-members-popup-closer {
  position: fixed;
  top: -200px;
  bottom: -200px;
  left: -200px;
  right: -200px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.desktop-chat-members-popup {
  position: fixed;
  z-index: 1000;
  width: 482px;
  height: 446px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  .desktop-chat-members-popup {
    width: calc(100dvw - 24px - 24px);
  }
}
.desktop-chat-members-popup .dcmp-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.desktop-chat-members-popup .dcmp-head .dcmp-head-text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.desktop-chat-members-popup .dcmp-head .new-icon-button > img {
  width: 10px;
  height: 10px;
}
.desktop-chat-members-popup .dcmp-members-count {
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  color: #000000;
  margin-top: 6px;
}
.desktop-chat-members-popup .dcmp-separator {
  width: 100%;
  height: 1px;
  background-color: #adb5bd;
  margin-top: 10px;
}
.desktop-chat-members-popup .fade-top {
  z-index: 1;
  position: absolute;
  top: 84px;
  left: 24px;
  width: calc(482px - 24px - 24px - 5px);
  height: 10px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
}
@media (max-width: 480px) {
  .desktop-chat-members-popup .fade-top {
    width: calc(100dvw - 24px - 24px - 24px - 24px - 5px);
  }
}
.desktop-chat-members-popup .fade-bottom {
  z-index: 1;
  position: absolute;
  bottom: 24px;
  left: 24px;
  width: calc(482px - 24px - 24px - 5px);
  height: 10px;
  background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
}
@media (max-width: 480px) {
  .desktop-chat-members-popup .fade-bottom {
    width: calc(100dvw - 24px - 24px - 24px - 24px - 5px);
  }
}
.desktop-chat-members-popup .dcmp-members-scroll-body {
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: clip;
}
.desktop-chat-members-popup .dcmp-members-scroll-body::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
}
.desktop-chat-members-popup .dcmp-members-scroll-body::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 2px;
  border-radius: 9px;
}
.desktop-chat-members-popup .dcmp-members-scroll-body::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap {
  width: calc(482px - 24px - 24px - 5px);
  height: 52px;
  margin-top: 10px;
  padding: 8px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 480px) {
  .desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap {
    width: calc(100dvw - 24px - 24px - 24px - 24px - 5px);
  }
}
.desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap:last-child {
  margin-bottom: 10px;
}
.desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap .member-icon {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap .member-info-wrap {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  justify-content: center;
}
.desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap .member-info-wrap .member-name {
  max-width: calc(482px - 24px - 24px - 5px - 40px - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
}
@media (max-width: 480px) {
  .desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap .member-info-wrap .member-name {
    max-width: calc(100dvw - 24px - 24px - 24px - 24px - 5px - 40px - 16px);
  }
}
.desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap .member-info-wrap .member-job-position {
  max-width: calc(482px - 24px - 24px - 5px - 40px - 16px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 18px;
  color: #7b7c7c;
}
@media (max-width: 480px) {
  .desktop-chat-members-popup .dcmp-members-scroll-body .member-wrap .member-info-wrap .member-job-position {
    max-width: calc(100dvw - 24px - 24px - 24px - 24px - 5px - 40px - 16px);
  }
}
.desktop-forward-outreach-popup-wrap {
  position: fixed;
  top: -1000px;
  left: -200px;
  height: calc(100dvh + 2000px);
  width: calc(100dvw + 400px);
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  overflow-y: auto;
  display: flex;
}
@media (min-width: 481px) {
  .desktop-forward-outreach-popup-wrap {
    padding: 1000px 200px;
  }
}
@media (max-width: 480px) {
  .desktop-forward-outreach-popup-wrap {
    padding: calc(1000px + 24px) calc(200px + 24px);
  }
}
.desktop-forward-outreach-popup {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
@media (min-width: 481px) {
  .desktop-forward-outreach-popup {
    min-width: 548px;
    min-height: 446px;
  }
}
@media (max-width: 480px) {
  .desktop-forward-outreach-popup {
    width: calc(100dvw - 24px - 24px);
  }
}
.desktop-forward-outreach-popup .dfop-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.desktop-forward-outreach-popup .dfop-head .dfop-head-text {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}
.desktop-forward-outreach-popup .dfop-head .new-icon-button > img {
  width: 10px;
  height: 10px;
}
.desktop-forward-outreach-popup .dfop-head-info {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  color: #000000;
}
.desktop-forward-outreach-popup .dfop-separator {
  width: 100%;
  height: 0.5px;
  background-color: #adb5bd;
  margin-top: 20px;
  margin-bottom: 20px;
}
.desktop-forward-outreach-popup .field {
  margin-bottom: 20px;
}
.desktop-forward-outreach-popup .field.no-margin-bottom {
  margin-bottom: 0;
}
.desktop-forward-outreach-popup .field.readonly {
  display: flex;
}
@media (min-width: 481px) {
  .desktop-forward-outreach-popup .field.readonly label {
    width: 120px;
  }
}
.desktop-forward-outreach-popup .field label {
  padding-left: 16px;
}
@media (min-width: 481px) {
  .desktop-forward-outreach-popup .field .singleSelect > span.chip {
    width: 440px;
  }
}
.desktop-forward-outreach-popup .error {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #dc3545;
}
.desktop-forward-outreach-popup .forward-button-wrap {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}
.desktop-forward-outreach-popup .forward-button-wrap .buttons-right-wrap {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}
.desktop-forward-outreach-popup .forward-button-wrap .buttons-right-wrap #request-feedback-button .new-icon-button .inner-text {
  color: #348cf9 !important;
}
.desktop-forward-outreach-popup .dfop-success-wrap {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.desktop-forward-outreach-popup .dfop-success-wrap .dfop-success-icon {
  width: 32px;
  height: 32px;
  filter: invert(46%) sepia(61%) saturate(2451%) hue-rotate(195deg) brightness(98%) contrast(98%);
}
.desktop-forward-outreach-popup .dfop-success-wrap .dfop-success-text {
  width: 355px;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  font-weight: 600;
  color: #000000;
  display: flex;
  flex-direction: column;
}
.desktop-forward-outreach-popup .dfop-success-wrap .dfop-success-text .subtext {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  font-weight: 400;
  color: #000000;
  margin-top: 8px;
}
.app-body.page-projects-listing {
  padding-bottom: 40px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing {
    padding: 0px 24px 24px 24px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .mobile-only {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .desktop-only {
    display: none !important;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .text-align-right-desktop {
    text-align: right;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .tooltip-delete-list-fund {
    max-width: 246px;
  }
}
.app-body.page-projects-listing h4 {
  font-weight: 500;
}
.app-body.page-projects-listing h6 {
  font-weight: 400;
}
.app-body.page-projects-listing .gray-title-panel {
  border-radius: 16px;
  padding: 36px 32px;
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .gray-title-panel {
    padding-left: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-bottom: 32px;
    margin-top: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .gray-title-panel {
    margin-top: 24px;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 24px;
  }
}
.app-body.page-projects-listing .delete-text {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  padding-right: 24px;
  background: url('../public/icons/trash.svg') no-repeat right center;
  cursor: pointer;
}
.app-body.page-projects-listing .table-responsive {
  position: relative;
}
.app-body.page-projects-listing .table-responsive .react-tooltip {
  z-index: 1;
}
.app-body.page-projects-listing .table-responsive .no-data {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .table-responsive .no-data {
    font-size: 18px;
  }
}
.app-body.page-projects-listing .table-responsive .header {
  position: relative;
  padding: 19px 36px;
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr) 2fr;
  gap: 20px;
}
.app-body.page-projects-listing .table-responsive .header.profiler-header {
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
}
.app-body.page-projects-listing .table-responsive .header.profiler-header .profiler-num-results {
  min-width: 119px;
}
.app-body.page-projects-listing .table-responsive .header.profiler-header .profiler-product {
  min-width: 112px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .table-responsive .header {
    display: none;
  }
}
.app-body.page-projects-listing .table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-projects-listing .table-responsive .header .item.project-name {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
.app-body.page-projects-listing .table-responsive .header .test {
  width: 100%;
}
.app-body.page-projects-listing .table-responsive .project-sort-wrapper {
  position: absolute;
  right: 0;
  top: 8px;
}
.app-body.page-projects-listing .table-responsive .plt-sort-mobile {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.app-body.page-projects-listing .table-responsive .plt-sort-mobile .search-wrapper.searchWrapper {
  padding-right: 13px;
  padding-left: 13px;
}
.app-body.page-projects-listing .table-responsive .plt-sort-mobile .chip.singleChip {
  width: 160px;
}
.app-body.page-projects-listing .table-responsive .row {
  position: relative;
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: grid;
  align-items: center;
  gap: 20px;
  padding: 19px 36px;
  grid-template-columns: 1.5fr repeat(3, 1fr) 2fr;
}
.app-body.page-projects-listing .table-responsive .row.profiler-row {
  grid-template-columns: repeat(6, 1fr);
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .table-responsive .row.profiler-row .profiler-num-results {
    min-width: 119px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .table-responsive .row.profiler-row .profiler-product {
    min-width: 112px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .table-responsive .row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-projects-listing .table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .table-responsive .row .item .value {
    font-weight: 600;
  }
  .app-body.page-projects-listing .table-responsive .row .item .value.text-align-right-desktop {
    justify-content: flex-end;
  }
}
.app-body.page-projects-listing .table-responsive .row .item.strong .value {
  font-weight: 600;
}
.app-body.page-projects-listing .table-responsive .row .item.project-name {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
.app-body.page-projects-listing .table-responsive .row .item.project-name .value {
  display: flex;
  align-items: center;
}
.app-body.page-projects-listing .table-responsive .row .item.project-name .value .new-icon-button-wrap {
  margin-left: 5px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .table-responsive .row .item.project-name {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .app-body.page-projects-listing .table-responsive .row .item.project-name .label,
  .app-body.page-projects-listing .table-responsive .row .item.project-name .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-projects-listing .table-responsive .row .item.project-name .value {
    font-weight: 600;
  }
}
.app-body.page-projects-listing .table-responsive .row .item.project-change-name {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
  display: flex;
  gap: 5px;
  align-items: center;
}
.app-body.page-projects-listing .table-responsive .row .item.project-change-name .icons {
  display: flex;
  align-items: center;
  gap: 5px;
}
.app-body.page-projects-listing .table-responsive .row .item.project-change-name .icons .new-icon-button-wrap {
  width: 30px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .table-responsive .row .item.project-change-name {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-projects-listing .table-responsive .row .item .label,
  .app-body.page-projects-listing .table-responsive .row .item .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
}
.app-body.page-projects-listing .table-responsive .row .item .label {
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-projects-listing .table-responsive .row .actions {
  display: flex;
  flex-direction: row;
  column-gap: 16px !important;
  align-items: center;
}
.app-body.page-projects-listing .table-responsive .row .actions .react-tooltip {
  z-index: 1;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .table-responsive .row .actions {
    flex-grow: 1;
    justify-content: flex-end;
  }
  .app-body.page-projects-listing .table-responsive .row .actions.profiler-project {
    min-width: 222px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .table-responsive .row .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .app-body.page-projects-listing .table-responsive .row .actions .btn {
    display: block;
  }
}
.app-body.page-projects-listing .table-responsive .row .actions-mobile {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  width: 100%;
}
.app-body.page-projects-listing .table-responsive .row .actions-mobile .actions-stretch {
  flex: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  justify-content: space-evenly;
}
.app-body.page-projects-listing .table-responsive .row .actions-mobile .actions-stretch .new-icon-button-wrap {
  width: 100%;
}
.app-body.page-projects-listing .table-responsive .row .actions-mobile .actions-stretch .new-icon-button-wrap .new-icon-button {
  width: 100%;
  justify-content: center;
}
.app-body.page-projects-listing .table-responsive .row .project-notif-dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #348cf9;
  position: absolute;
  right: -6px;
  top: -6px;
}
.app-body.page-projects-listing .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .tabs {
    margin-bottom: 12px;
    gap: 0px;
  }
}
.app-body.page-projects-listing .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
.app-body.page-projects-listing .tabs .tab.no-cursor {
  cursor: default !important;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .tabs .tab {
    width: 100%;
    text-align: center;
    padding: 10px 8px;
  }
}
.app-body.page-projects-listing .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .tabs .tab .mobile-text {
    display: none;
  }
}
.app-body.page-projects-listing .project-details .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 14px 0 24px 0;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .project-details .backlink {
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .project-details .backlink {
    margin: 27px 0;
  }
}
.app-body.page-projects-listing .project-details .title {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .project-details .title {
    font-size: 32px;
    line-height: 32px;
  }
}
.app-body.page-projects-listing .project-details .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 20px;
  overflow: auto;
}
.app-body.page-projects-listing .project-details .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.app-body.page-projects-listing .project-details .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
.app-body.page-projects-listing .project-details .actions-bottom {
  position: fixed;
  right: -200px;
  bottom: -200px;
  left: -200px;
  padding: 24px 200px 224px 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  background-color: #ffffff;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
}
.app-body.page-projects-listing .project-view-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 0.5px solid #000000;
  margin-bottom: 20px;
}
.app-body.page-projects-listing .project-view-header .actions {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists {
    padding-bottom: 76px;
  }
}
.app-body.page-projects-listing section.lists .table-responsive {
  position: relative;
}
.app-body.page-projects-listing section.lists .table-responsive .react-tooltip {
  z-index: 1;
}
.app-body.page-projects-listing section.lists .table-responsive .header {
  position: relative;
  padding: 19px 36px;
  display: grid;
  grid-template-columns: repeat(5, 1fr) 2fr 2fr;
  gap: 20px;
}
.app-body.page-projects-listing section.lists .table-responsive .header.profiler-search-results-tab-view-header {
  grid-template-columns: repeat(9, 1fr);
}
.app-body.page-projects-listing section.lists .table-responsive .header.profiler-search-results-tab-view-header .company-name-item {
  min-width: 132px;
}
.app-body.page-projects-listing section.lists .table-responsive .header.profiler-search-results-tab-view-header .revenue-item {
  width: 94px;
}
.app-body.page-projects-listing section.lists .table-responsive .header.profiler-search-results-tab-view-header .employees-item {
  width: 76px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .table-responsive .header {
    display: none;
  }
}
.app-body.page-projects-listing section.lists .table-responsive .header .project-sort-wrapper {
  position: absolute;
  right: 0;
  top: 8px;
}
.app-body.page-projects-listing section.lists .table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-projects-listing section.lists .table-responsive .header .item.feedback-item {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
.app-body.page-projects-listing section.lists .table-responsive .header .item.feedback-item-profiler {
  min-width: 146.05px;
}
.app-body.page-projects-listing section.lists .table-responsive .header .item.actions {
  min-width: 238.6px;
}
.app-body.page-projects-listing section.lists .table-responsive .header .item.actions-profiler {
  min-width: 96.11px;
}
.app-body.page-projects-listing section.lists .table-responsive .srtv-wrap {
  width: 240px;
  margin-bottom: 20px;
  justify-self: flex-end;
}
.app-body.page-projects-listing section.lists .table-responsive .row {
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: grid;
  align-items: center;
  gap: 20px;
  padding: 19px 36px;
  grid-template-columns: repeat(5, 1fr) 2fr 2fr;
}
.app-body.page-projects-listing section.lists .table-responsive .row * {
  overflow-wrap: break-word;
  word-break: break-word;
}
.app-body.page-projects-listing section.lists .table-responsive .row.profiler-search-results-tab-entry-row {
  grid-template-columns: repeat(9, 1fr);
}
.app-body.page-projects-listing section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.feedback-item.desktop-only {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.profiler-company-name-item {
    width: 132px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.profiler-revenue-item {
    width: 94px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.profiler-employees-item {
    width: 76px;
  }
}
.app-body.page-projects-listing section.lists .table-responsive .row.profiler-search-results-tab-entry-row .actions.desktop-only {
  width: auto;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .table-responsive .row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-projects-listing section.lists .table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.strong .value {
  font-weight: 600;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-projects-listing section.lists .table-responsive .row .item .label,
  .app-body.page-projects-listing section.lists .table-responsive .row .item .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-projects-listing section.lists .table-responsive .row .item .value {
    font-weight: 600;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-projects-listing section.lists .table-responsive .row .item .value {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .item .value {
    padding-top: 4px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .item .value.right-align-desktop {
    justify-content: flex-end;
  }
}
.app-body.page-projects-listing section.lists .table-responsive .row .item .value .year {
  font-size: 10px;
  line-height: 10px;
  margin-top: -2px;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item {
    width: 100%;
    justify-content: flex-end;
  }
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback {
  border-radius: 4px;
  padding: 0;
  width: 180px;
  height: unset;
  max-height: 245px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback.popup-feedback-up {
  border: 1px solid #28a745;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback.popup-feedback-question {
  border: 1px solid #ffd041;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback.popup-feedback-down {
  border: 1px solid #dc3545;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback .ppfp-scrollable-body {
  margin-left: 16px;
  margin-right: 5.5px;
  overflow-y: auto;
  flex: 1;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback .ppfp-scrollable-body::-webkit-scrollbar {
  width: 5px;
  background-color: #f4f5f7;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback .ppfp-scrollable-body::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 9px;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback .ppfp-scrollable-body::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback .ppfp-scrollable-body .ppfp-item {
  width: calc(180px - 2px - 16px - 16px);
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #adb5bd;
  color: #000000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-projects-listing section.lists .table-responsive .row .item.feedback-item .popup-feedback .ppfp-scrollable-body .ppfp-item:last-child {
  border-bottom: none;
}
.app-body.page-projects-listing section.lists .table-responsive .row .actions {
  display: flex;
  gap: 20px;
  align-items: center;
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .actions {
    flex-grow: 1;
    justify-content: flex-end;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.app-body.page-projects-listing section.lists .table-responsive .row .actions-mobile {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  width: 100%;
}
.app-body.page-projects-listing section.lists .table-responsive .row .actions-mobile .view-details-search-results {
  flex: 1;
  width: 100%;
}
.app-body.page-projects-listing section.lists .table-responsive .row .actions-mobile .view-details-search-results .new-icon-button {
  width: 100%;
  justify-content: center;
}
.app-body.page-projects-listing section.lists .table-responsive .row .icon-trash {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../public/icons/trash.svg') no-repeat center center;
}
.app-body.page-projects-listing section.lists .table-responsive .row .icon-trash.disabled {
  cursor: not-allowed;
  background: url('../public/icons/trash-faded.svg') no-repeat center center;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .table-responsive .row .icon-trash {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
.app-body.page-projects-listing section.lists .comment-table-responsive .no-data {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .no-data {
    font-size: 18px;
  }
}
.app-body.page-projects-listing section.lists .comment-table-responsive .header {
  padding: 19px 36px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 4fr;
  gap: 20px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .header {
    display: none;
  }
}
.app-body.page-projects-listing section.lists .comment-table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-projects-listing section.lists .comment-table-responsive .header .item.comment-item {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3);
}
.app-body.page-projects-listing section.lists .comment-table-responsive .header .item.name-item {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 1.5 + 20px);
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row-mobile-comment {
  background-color: #f4f5f7;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row-mobile-comment .name-date {
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row-mobile-comment .comment {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row {
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: grid;
  align-items: center;
  gap: 20px;
  padding: 19px 36px;
  grid-template-columns: 1.5fr 1fr 4fr;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .item.comment-item {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 4 + 20px * 3);
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .item.name-item {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 1.5 + 20px);
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .item .project-name {
  grid-column: span 3;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .item .project-name .new-icon-button-wrap {
  margin-left: 5px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item .label,
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item .value {
    font-weight: 600;
  }
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item .value.project-name {
    padding-right: 20px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .item.name-item,
.app-body.page-projects-listing section.lists .comment-table-responsive .row .item.comment-item {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item.name-item,
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .item.comment-item {
    justify-content: center;
  }
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .actions {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .actions {
    flex-grow: 1;
    justify-content: flex-end;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .icon-trash {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../public/icons/trash.svg') no-repeat center center;
}
.app-body.page-projects-listing section.lists .comment-table-responsive .row .icon-trash.disabled {
  cursor: not-allowed;
  background: url('../public/icons/trash-faded.svg') no-repeat center center;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing section.lists .comment-table-responsive .row .icon-trash {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
.app-body.page-projects-listing h5 {
  color: #348cf9;
  font-size: 18px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing h5 {
    display: none;
  }
}
.app-body.page-projects-listing .mandatory-container {
  padding-top: 32px;
  margin-bottom: 40px;
}
.app-body.page-projects-listing .mandatory-container .mandatory-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-body.page-projects-listing .mandatory-container .fields {
  margin-top: 20px;
}
.app-body.page-projects-listing .mandatory-container .section-title {
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
  color: #348cf9;
}
.app-body.page-projects-listing .mandatory-container .fields .group-title {
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
  color: #348cf9;
  padding: 20px 0;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .mandatory-container .fields .group-title {
    font-size: 16px;
  }
}
.app-body.page-projects-listing .mandatory-container .fields .group {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #adb5bd;
  gap: 80px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .mandatory-container .fields .group {
    flex-direction: column;
    gap: 16px;
  }
}
.app-body.page-projects-listing .mandatory-container .fields .group .field {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
  display: flex;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .mandatory-container .fields .group .field {
    width: 100%;
    justify-content: space-between;
    column-gap: 10px;
  }
  .app-body.page-projects-listing .mandatory-container .fields .group .field.empty {
    display: none;
  }
}
.app-body.page-projects-listing .mandatory-container .fields .group .field .label {
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .mandatory-container .fields .group .field .label {
    min-width: 158px;
    flex: 0 1 158px;
    height: fit-content;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .mandatory-container .fields .group .field .label {
    width: calc(45% - 5px);
    height: auto;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .mandatory-container .fields .group .field .value {
    width: calc(55% - 5px);
    padding-top: 2px;
  }
}
.app-body.page-projects-listing .overlay {
  position: fixed;
  top: -1000px;
  left: -200px;
  width: calc(100dvw + 400px);
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  overflow-y: auto;
  display: flex;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay {
    padding: 1000px 200px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay {
    padding: calc(1000px + 24px) calc(200px + 24px);
  }
}
.app-body.page-projects-listing .overlay .container {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: auto;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container {
    padding: 24px 0;
    width: 100%;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container {
    width: 712px;
  }
}
.app-body.page-projects-listing .overlay .container .close {
  background: url('../public/icons/x.svg') no-repeat right center;
  padding-right: 20px;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  cursor: pointer;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container .close {
    text-align: right;
    font-size: 16px;
    height: 20px;
    line-height: 20px;
  }
}
.app-body.page-projects-listing .overlay .container .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container .header {
    flex-direction: column-reverse;
    gap: 12px;
    padding: 0 24px;
  }
}
.app-body.page-projects-listing .overlay .container .errors {
  height: 20px;
  line-height: 20px;
  margin-top: 8px;
  padding-left: 28px;
  color: #dc3545;
  font-size: 12px;
  background: url('../public/icons/exclamation-circle.svg') no-repeat left center;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container .errors {
    margin-top: 16px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-details .header {
  border-bottom: 0.5px solid #348cf9;
}
.app-body.page-projects-listing .overlay .container.popup-feedback .separator {
  border-top: 0.5px solid #adb5bd;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-feedback .separator {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-feedback .header.feedback {
  flex-direction: row;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-feedback .content {
    padding: 0 24px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-feedback .content .info {
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  margin-bottom: 24px;
  margin-top: 20px;
}
.app-body.page-projects-listing .overlay .container.popup-feedback .content .info.colleagues {
  margin-top: -12px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-feedback .content .info {
    line-height: 16px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-feedback .content .actions {
  display: flex;
  justify-content: flex-end;
}
.app-body.page-projects-listing .overlay .container.popup-feedback .field {
  margin-bottom: 20px;
}
.app-body.page-projects-listing .overlay .container.popup-feedback .field.no-margin-bottom {
  margin-bottom: 0;
}
.app-body.page-projects-listing .overlay .container.popup-feedback .field.readonly {
  display: flex;
}
.app-body.page-projects-listing .overlay .container.popup-feedback .field.readonly label {
  width: 120px;
}
.app-body.page-projects-listing .overlay .container.popup-feedback .field label {
  padding-left: 16px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .header.message.single {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 14px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header.message.single .save-custom-contact {
  animation: beep-save-custom-contact 0.5s infinite;
}
@keyframes beep-save-custom-contact {
  0% {
    color: #000000;
    /* Start color */
    transform: scale(1);
    /* Initial scale */
  }
  100% {
    color: #28a745;
    /* Change to green */
    transform: scale(1.1);
    /* Scale up */
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header.message.single .delete-custom-contact {
  animation: beep-delete-custom-contact 0.5s infinite;
}
@keyframes beep-delete-custom-contact {
  0% {
    color: #000000;
    /* Start color */
    transform: scale(1);
    /* Initial scale */
  }
  100% {
    color: #dc3545;
    /* Change to green */
    transform: scale(0.9);
    /* Scale up */
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header.message,
.app-body.page-projects-listing .overlay .container.popup-details .header.message {
  flex-direction: row;
}
.app-body.page-projects-listing .overlay .container.popup-message .header.message .text label,
.app-body.page-projects-listing .overlay .container.popup-details .header.message .text label {
  line-height: 21px;
}
.app-body.page-projects-listing .overlay .container.popup-message .header.message .text .value,
.app-body.page-projects-listing .overlay .container.popup-details .header.message .text .value {
  line-height: 21px;
}
.app-body.page-projects-listing .overlay .container.popup-message .header .tabs,
.app-body.page-projects-listing .overlay .container.popup-details .header .tabs {
  display: flex;
  justify-content: flex-start;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .header .tabs,
  .app-body.page-projects-listing .overlay .container.popup-details .header .tabs {
    justify-content: space-around;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header .tabs .tab,
.app-body.page-projects-listing .overlay .container.popup-details .header .tabs .tab {
  padding: 10px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #000000;
  border-radius: 4px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .header .tabs .tab,
  .app-body.page-projects-listing .overlay .container.popup-details .header .tabs .tab {
    font-size: 14px;
    line-height: 14px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header .tabs .tab.active,
.app-body.page-projects-listing .overlay .container.popup-details .header .tabs .tab.active {
  border-bottom: 2px solid #348cf9;
  color: #ffffff;
  background-color: #348cf9;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content,
  .app-body.page-projects-listing .overlay .container.popup-details .content {
    padding: 0 24px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .content .row,
.app-body.page-projects-listing .overlay .container.popup-details .content .row {
  padding: 8px 0;
  border-bottom: 0.5px solid #adb5bd;
  display: flex;
  align-items: center;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .row:last-of-type,
.app-body.page-projects-listing .overlay .container.popup-details .content .row:last-of-type {
  border-bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .row,
  .app-body.page-projects-listing .overlay .container.popup-details .content .row {
    padding: 16px 0;
    height: fit-content;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .content .row .bullet,
.app-body.page-projects-listing .overlay .container.popup-details .content .row .bullet {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .row .bullet,
  .app-body.page-projects-listing .overlay .container.popup-details .content .row .bullet {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .row .bullet,
  .app-body.page-projects-listing .overlay .container.popup-details .content .row .bullet {
    display: none;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .content .row .value,
.app-body.page-projects-listing .overlay .container.popup-details .content .row .value,
.app-body.page-projects-listing .overlay .container.popup-message .content .row .label,
.app-body.page-projects-listing .overlay .container.popup-details .content .row .label {
  font-size: 12px;
  line-height: 12px;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .row .label,
.app-body.page-projects-listing .overlay .container.popup-details .content .row .label {
  font-weight: 600;
  padding-left: 12px;
  width: 230px;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .row .label,
  .app-body.page-projects-listing .overlay .container.popup-details .content .row .label {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .row .label,
  .app-body.page-projects-listing .overlay .container.popup-details .content .row .label {
    flex: 0 0 50%;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .content .field,
.app-body.page-projects-listing .overlay .container.popup-details .content .field {
  margin-bottom: 20px;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .field.no-margin-bottom,
.app-body.page-projects-listing .overlay .container.popup-details .content .field.no-margin-bottom {
  margin-bottom: 0;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .field.readonly,
.app-body.page-projects-listing .overlay .container.popup-details .content .field.readonly {
  display: flex;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .field.readonly label,
.app-body.page-projects-listing .overlay .container.popup-details .content .field.readonly label {
  width: 120px;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .field label,
.app-body.page-projects-listing .overlay .container.popup-details .content .field label {
  padding-left: 16px;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .actions,
.app-body.page-projects-listing .overlay .container.popup-details .content .actions {
  display: flex;
  justify-content: flex-end;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .actions.outreach,
.app-body.page-projects-listing .overlay .container.popup-details .content .actions.outreach {
  justify-content: space-between;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .actions.outreach .pdf-action,
.app-body.page-projects-listing .overlay .container.popup-details .content .actions.outreach .pdf-action {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .actions.outreach .pdf-action .pdf-action-info,
.app-body.page-projects-listing .overlay .container.popup-details .content .actions.outreach .pdf-action .pdf-action-info {
  font-size: 10px;
  font-weight: 400;
  font-style: italic;
  line-height: 10px;
  color: #000000;
}
.app-body.page-projects-listing .overlay .container.popup-message .header .header-info {
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  margin-top: 2px;
  margin-bottom: 24px;
  padding-right: 140px;
  color: #28bbfa;
  padding-left: 28px;
  background: url('../public/icons/exclamation-circle-info.svg') no-repeat left top;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .header .header-info {
    padding-right: 20px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header .text {
  display: flex;
  gap: 30px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .header .text {
    justify-content: space-between;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header .text label {
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .header .text label {
    padding-top: 4px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .header .text .value {
  font-size: 14px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow: hidden;
  animation: expand-custom-contact-wrap 0.5s ease-in-out forwards;
}
@keyframes expand-custom-contact-wrap {
  from {
    opacity: 0.3;
    max-height: 0;
    /* Start from collapsed */
  }
  to {
    opacity: 1;
    max-height: 10000px;
    /* Set an arbitrary large max height */
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap {
    padding-left: 24px;
    padding-right: 24px;
    row-gap: 8px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .instructions {
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap {
    flex-direction: column;
    align-items: unset;
    row-gap: 8px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap.email-wrap {
    column-gap: 12px;
  }
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap.email-wrap .left {
    width: 45%;
  }
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap.email-wrap .right {
    width: 55%;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .left .form-group,
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .form-group {
  width: 100%;
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .left .form-group label,
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .form-group label {
  padding-left: 16px;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .left {
    width: 50%;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 8px;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right {
    flex-direction: column;
    align-items: unset;
    row-gap: 4px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .email-domain {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .email-domain {
    margin-top: 20.5px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-inputs-wrap .right .email-domain {
    text-align: right;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap {
    column-gap: 8px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .left .new-icon-button-wrap .new-icon-button .inner-text {
    display: none;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right {
    column-gap: 8px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right .new-icon-button-wrap:first-child .new-icon-button .inner-text {
  color: #dc3545;
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contact-actions-wrap .right .new-icon-button-wrap:first-child:hover .new-icon-button .inner-text {
  color: #ffffff;
}
.app-body.page-projects-listing .overlay .container.popup-message .custom-contact-wrap .contacts-error {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #dc3545;
}
.app-body.page-projects-listing .overlay .container.popup-message .separator {
  border-top: 0.5px solid #adb5bd;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .separator {
    margin-left: 24px;
    margin-right: 24px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .content .info {
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  margin-bottom: 24px;
  margin-top: 20px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .info {
    line-height: 16px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .content .info .terms-link {
  text-decoration: underline;
}
.app-body.page-projects-listing .overlay .container.popup-message .content .success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
  height: 380px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .success {
    flex-direction: column;
    padding: 0 14px;
    gap: 12px;
  }
}
@media (min-width: 481px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .success {
    width: 332px;
    margin: 0 auto;
  }
}
.app-body.page-projects-listing .overlay .container.popup-message .content .success img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .content .success .names {
    display: block;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-message .actions .btn {
    width: 100%;
  }
}
.app-body.page-projects-listing .overlay .container.popup-save-search {
  min-height: 160px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-save-search {
    min-height: 210px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-save-search .header {
    gap: 36px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-save-search .success-text {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 306px;
  margin: 0 auto;
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-save-search .success-text {
    flex-direction: column;
    gap: 4px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-save-search .success-text h4 {
  font-size: 20px;
  line-height: 26px;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-save-search .success-text h4 {
    max-width: 250px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-save-search .success-text img {
  width: 24px;
  height: 24px;
}
.app-body.page-projects-listing .overlay .container.popup-save-search .success-text .loader-wrapper {
  height: 50px;
}
.app-body.page-projects-listing .overlay .container.popup-save-search .success-actions {
  display: flex;
  justify-content: center;
}
.app-body.page-projects-listing .overlay .container.popup-save-search .success-actions a {
  display: block;
  width: auto;
}
.app-body.page-projects-listing .overlay .container.popup-save-search h5 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
}
.app-body.page-projects-listing .overlay .container.popup-save-search p {
  margin: 0;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-save-search p {
    padding: 0 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-save-search .form-group {
    padding: 0 24px;
  }
}
.app-body.page-projects-listing .overlay .container.popup-save-search .actions {
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-projects-listing .overlay .container.popup-save-search .errors {
    padding: 0 24px;
  }
}
.app-body.page-all-investments-listing .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  gap: 22px;
  margin-bottom: 16px;
  flex-shrink: 0;
  margin-top: 16px;
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .tabs {
    margin: 24px 0;
    overflow: auto;
  }
}
.app-body.page-all-investments-listing .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
.app-body.page-all-investments-listing .tabs .tab.no-cursor {
  cursor: default !important;
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .tabs .tab {
    width: 100%;
    white-space: nowrap;
  }
}
.app-body.page-all-investments-listing .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .tabs .tab .desktop-text {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-all-investments-listing .tabs .tab .mobile-text {
    display: none;
  }
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .table-responsive {
    margin-top: 0;
  }
}
.app-body.page-all-investments-listing .table-responsive .header {
  padding: 19px 36px;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 20px;
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .table-responsive .header {
    display: none;
  }
}
.app-body.page-all-investments-listing .table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.app-body.page-all-investments-listing .table-responsive .row {
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: grid;
  align-items: center;
  gap: 20px;
  padding: 19px 36px;
  grid-template-columns: repeat(9, 1fr);
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .table-responsive .row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-all-investments-listing .table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-all-investments-listing .table-responsive .row .item.strong .value {
  font-weight: 600;
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-all-investments-listing .table-responsive .row .item .label,
  .app-body.page-all-investments-listing .table-responsive .row .item .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-all-investments-listing .table-responsive .row .item .value {
    font-weight: 600;
  }
  .app-body.page-all-investments-listing .table-responsive .row .item .value.fund-name {
    padding-right: 20px;
  }
}
@media (min-width: 481px) {
  .app-body.page-all-investments-listing .table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-all-investments-listing .table-responsive .row .actions {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (min-width: 481px) {
  .app-body.page-all-investments-listing .table-responsive .row .actions {
    grid-column: span 2;
    flex-grow: 1;
    justify-content: flex-end;
  }
}
@media (max-width: 480px) {
  .app-body.page-all-investments-listing .table-responsive .row .actions {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
  .app-body.page-all-investments-listing .table-responsive .row .actions .action-button {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .app-body.page-all-investments-listing .table-responsive .row .actions .action-button .new-icon-button {
    justify-content: center;
  }
}
.pdf-list {
  overflow-y: clip;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  border-radius: 4px;
  padding-bottom: 2px;
}
.pdf-list::-webkit-scrollbar {
  height: 5px;
  background-color: transparent;
}
.pdf-list::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 9px;
}
.pdf-list::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.pdf-list .doc-wrap {
  min-width: 142px;
  max-width: 142px;
  width: 142px;
  height: 33px;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  background-color: #f4f5f7;
  border-radius: 4px;
  align-items: center;
}
.pdf-list .doc-wrap .pdf-icon {
  width: 16px;
  height: 16px;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
}
.pdf-list .doc-wrap .doc-info-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  flex: 1;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-top {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  max-width: 108px;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-top .doc-name {
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-top .doc-size {
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #7b7c7c;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-bottom .doc-status {
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;
  color: #7b7c7c;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-bottom .new-icon-button-wrap .new-icon-button {
  padding: 2px;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-bottom .new-icon-button-wrap .new-icon-button .img-wrap {
  width: 9px;
  height: 9px;
}
.pdf-list .doc-wrap .doc-info-wrap .doc-info-bottom .new-icon-button-wrap .new-icon-button img {
  width: 9px;
  height: 9px;
}
.app-body.page-team-feedback {
  position: relative;
  padding-bottom: 40px;
  padding-top: 120px;
  padding-right: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  padding-left: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
}
@media (max-width: 1200px) {
  .app-body.page-team-feedback {
    padding: 80px 24px 40px 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback {
    padding: 0px 24px 40px 24px;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .text-align-right-desktop {
    text-align: right;
  }
}
@media (max-width: 1200px) {
  .app-body.page-team-feedback .desktop-only {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  .app-body.page-team-feedback .mobile-only {
    display: none !important;
  }
}
.app-body.page-team-feedback .mobile-header-main {
  z-index: 1;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #ffffff;
}
@media (max-width: 1200px) {
  .app-body.page-team-feedback .mobile-header-main {
    top: 80px;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .mobile-header-main {
    top: 52px;
  }
}
.app-body.page-team-feedback .mobile-header-main .title {
  color: #348cf9;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}
.app-body.page-team-feedback .mobile-header-main .tabs-scrollable-wrap {
  overflow-x: auto;
  border-bottom: 0.5px solid #348cf9;
}
.app-body.page-team-feedback .mobile-header-main .tabs-scrollable-wrap .tabs-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.app-body.page-team-feedback .mobile-header-main .tabs-scrollable-wrap .tabs-wrap .tab {
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.app-body.page-team-feedback .mobile-header-main .tabs-scrollable-wrap .tabs-wrap .tab.active {
  background-color: #348cf9;
  cursor: default;
  color: #ffffff;
}
.app-body.page-team-feedback .no-requests-text-mobile {
  margin-top: 40px;
  font-weight: 600;
  text-align: center;
  font-size: 18px;
}
.app-body.page-team-feedback .mobile-project-entry {
  background-color: #f4f5f7;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
}
.app-body.page-team-feedback .mobile-project-entry .project-name {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}
.app-body.page-team-feedback .mobile-project-entry .project-property-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.app-body.page-team-feedback .mobile-project-entry .project-property-row .project-property-field,
.app-body.page-team-feedback .mobile-project-entry .project-property-row .project-property-value {
  color: #000000;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
}
.app-body.page-team-feedback .mobile-project-entry .project-property-row .project-property-field {
  font-weight: 600;
}
.app-body.page-team-feedback .mobile-project-entry .project-property-row .project-property-value {
  font-weight: 400;
}
.app-body.page-team-feedback .mobile-project-entry .project-actions-row .open-feedback-request-btn {
  width: 100%;
}
.app-body.page-team-feedback .mobile-project-entry .project-actions-row .open-feedback-request-btn .new-icon-button {
  width: 100%;
  justify-content: center;
}
.app-body.page-team-feedback .layout-wrap {
  width: 100%;
  height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: stretch;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap {
  background-color: #f4f5f7;
  width: 222px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .title {
  height: 50px;
  font-size: 20px;
  color: #348cf9;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  border-bottom: 0.5px solid #7b7c7c;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body {
  overflow-y: auto;
  overflow-x: clip;
  flex: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: inherit;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body::-webkit-scrollbar {
  width: 5px;
  background-color: #f4f5f7;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 2px;
  border-radius: 9px;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .separator {
  width: 190px;
  height: 0.5px;
  margin-left: 16px;
  background-color: #7b7c7c;
  margin-top: 12px;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .separator:last-of-type {
  margin-bottom: 12px;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button {
  background-color: transparent;
  width: 206px;
  margin: 8px 0 0 8px;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background: url('../public/icons/arrow-right.svg') no-repeat right center;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button:hover {
  background-color: #dee2e6;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button.selected {
  background: url('../public/icons/arrow-right-white.svg') no-repeat right center;
  background-color: #348cf9;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button.selected:hover {
  cursor: default;
  background-color: #348cf9;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button.selected .icon-title {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button.selected .head-title {
  color: #ffffff;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button.selected .head-count {
  color: #348cf9;
  background-color: #ffffff;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button .icon-title {
  margin-right: 8px;
  filter: invert(0%) sepia(0%) saturate(3521%) hue-rotate(304deg) brightness(99%) contrast(102%);
  width: 16px;
  height: 16px;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button .head-title {
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.app-body.page-team-feedback .layout-wrap .sections-wrap .scrollable-body .shared-project-button .head-count {
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #348cf9;
  border-radius: 9px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-left: 8px;
}
.app-body.page-team-feedback .layout-wrap .right-container {
  background-color: #ffffff;
  border: 0.5px solid #adb5bd;
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive {
  width: 100%;
  padding: 0 20px;
  overflow-y: auto;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .no-data {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .no-data {
    font-size: 18px;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .header {
  padding: 19px 36px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .header {
    display: none;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .header .item.project-name {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .header .item.product-portfolio-desktop {
    min-width: 112px;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .header .test {
  width: 100%;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row {
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: grid;
  align-items: center;
  gap: 20px;
  padding: 19px 36px;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.strong .value {
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.product-portfolio-desktop {
    min-width: 112px;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-name {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-name .value {
  display: flex;
  align-items: center;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-name .value .new-icon-button-wrap {
  margin-left: 5px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-name {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-name .label,
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-name .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-name .value {
    font-weight: 600;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-change-name {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 2 + 20px);
  display: flex;
  gap: 5px;
  align-items: center;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-change-name .icons {
  display: flex;
  align-items: center;
  gap: 5px;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-change-name .icons .new-icon-button-wrap {
  width: 30px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item.project-change-name {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item .label,
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item .value {
    font-weight: 600;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .actions {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .actions {
    flex-grow: 1;
    justify-content: flex-end;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .actions .btn {
    display: block;
  }
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .icon-trash {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../public/icons/trash.svg') no-repeat center center;
}
.app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .icon-trash.disabled {
  cursor: not-allowed;
  background: url('../public/icons/trash-faded.svg') no-repeat center center;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .layout-wrap .right-container .table-responsive .row .icon-trash {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details {
    margin-top: -40px;
  }
}
.app-body.page-team-feedback .feedback-details .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 14px 0 24px 0;
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details .backlink {
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .backlink {
    margin: 27px 0;
  }
}
.app-body.page-team-feedback .feedback-details .title {
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .title {
    font-size: 32px;
    line-height: 32px;
  }
}
.app-body.page-team-feedback .feedback-details .tabs {
  border-bottom: 1px solid #348cf9;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  overflow: auto;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .tabs.request-view {
    justify-content: space-evenly;
  }
  .app-body.page-team-feedback .feedback-details .tabs.request-view .tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.app-body.page-team-feedback .feedback-details .tabs .tab {
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
}
.app-body.page-team-feedback .feedback-details .tabs .tab.active {
  background-color: #348cf9;
  color: #ffffff;
  font-weight: 500;
}
.app-body.page-team-feedback .feedback-details .feedback-view-header {
  padding-bottom: 32px;
  border-bottom: 0.5px solid #000000;
  margin-bottom: 20px;
}
.app-body.page-team-feedback .feedback-details .feedback-view-header .love {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.app-body.page-team-feedback .feedback-details .feedback-view-header .love .title {
  color: #000000;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .feedback-view-header .love .title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }
}
.app-body.page-team-feedback .feedback-details .feedback-view-header .love .actions {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.app-body.page-team-feedback .feedback-details .feedback-view-header .note {
  font-size: 24px;
  line-height: 36px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .feedback-view-header .note {
    font-size: 16px;
    line-height: 24px;
  }
}
.app-body.page-team-feedback .feedback-details .feedback-view-header .comment {
  margin-top: 10px;
  width: 60%;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .feedback-view-header .comment {
    width: 100%;
  }
}
.app-body.page-team-feedback .feedback-details .feedback-view-header .comment label {
  padding-left: 15px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists {
    padding-bottom: 10px;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .no-data {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .no-data {
    font-size: 18px;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .header {
  padding: 19px 36px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .header.profiler-search-results-tab-view-header {
  grid-template-columns: repeat(9, 1fr);
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .header.profiler-search-results-tab-view-header .company-name-item {
  min-width: 132px;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .header.profiler-search-results-tab-view-header .revenue-item {
  width: 94px;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .header.profiler-search-results-tab-view-header .employees-item {
  width: 76px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .header {
    display: none;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .header .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .header .item.feedback-item {
  width: 100%;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row {
  min-height: 72px;
  border-radius: 4px;
  background-color: #f4f5f7;
  margin-bottom: 16px;
  display: grid;
  align-items: center;
  gap: 20px;
  padding: 19px 36px;
  grid-template-columns: repeat(7, 1fr);
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row * {
  overflow-wrap: break-word;
  word-break: break-word;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row.profiler-search-results-tab-entry-row {
  grid-template-columns: repeat(9, 1fr);
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.feedback-item.desktop-only {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.profiler-company-name-item {
    width: 132px;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.profiler-revenue-item {
    width: 94px;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row.profiler-search-results-tab-entry-row .item.profiler-employees-item {
    width: 76px;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row.profiler-search-results-tab-entry-row .actions.desktop-only {
  width: auto;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item {
  width: calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .project-name {
  grid-column: span 3;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .project-name .new-icon-button-wrap {
  margin-left: 5px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .label,
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value {
    width: calc(calc((calc(100dvw - 2 * 24px) - 3 * 12px) / 4) * 2 + 12px);
  }
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value {
    font-weight: 600;
  }
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value.project-name {
    padding-right: 20px;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .label {
    display: none;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value {
    padding-top: 4px;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value.right-align-desktop,
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value.text-align-right-desktop {
    justify-content: flex-end;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item .value .year {
  font-size: 10px;
  line-height: 10px;
  margin-top: -2px;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item.feedback-item {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .item.feedback-item {
    justify-content: flex-end;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .actions {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .actions {
    flex-grow: 1;
    justify-content: flex-end;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .actions .new-icon-button-wrap {
    width: 100%;
  }
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .actions .new-icon-button-wrap .new-icon-button {
    width: 100%;
    justify-content: center;
  }
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .icon-trash {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: url('../public/icons/trash.svg') no-repeat center center;
}
.app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .icon-trash.disabled {
  cursor: not-allowed;
  background: url('../public/icons/trash-faded.svg') no-repeat center center;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details section.lists .table-responsive .row .icon-trash {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
.app-body.page-team-feedback .feedback-details .mandatory-container {
  padding-top: 32px;
  margin-bottom: 40px;
}
.app-body.page-team-feedback .feedback-details .mandatory-container .mandatory-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.app-body.page-team-feedback .feedback-details .mandatory-container .fields {
  margin-top: 20px;
}
.app-body.page-team-feedback .feedback-details .mandatory-container .section-title {
  font-size: 20px;
  line-height: 16px;
  font-weight: 600;
  color: #348cf9;
}
.app-body.page-team-feedback .feedback-details .mandatory-container .fields .group-title {
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
  color: #348cf9;
  padding: 20px 0;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .mandatory-container .fields .group-title {
    font-size: 16px;
  }
}
.app-body.page-team-feedback .feedback-details .mandatory-container .fields .group {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #adb5bd;
  gap: 80px;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .mandatory-container .fields .group {
    flex-direction: column;
    gap: 16px;
  }
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details .mandatory-container .fields .group.mobile-only {
    display: none;
  }
}
.app-body.page-team-feedback .feedback-details .mandatory-container .fields .group .field {
  width: calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) * 3 + 20px * 2);
  display: flex;
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .mandatory-container .fields .group .field {
    width: 100%;
    justify-content: space-between;
    column-gap: 10px;
  }
}
.app-body.page-team-feedback .feedback-details .mandatory-container .fields .group .field .label {
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (min-width: 481px) {
  .app-body.page-team-feedback .feedback-details .mandatory-container .fields .group .field .label {
    min-width: 158px;
    flex: 0 1 158px;
    height: fit-content;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .mandatory-container .fields .group .field .label {
    width: calc(45% - 5px);
    height: auto;
  }
}
@media (max-width: 480px) {
  .app-body.page-team-feedback .feedback-details .mandatory-container .fields .group .field .value {
    width: calc(55% - 5px);
    padding-top: 2px;
  }
}
.app-body.page-team-feedback .feedback-details .actions-bottom {
  position: fixed;
  right: -200px;
  bottom: -200px;
  left: -200px;
  padding: 24px 224px 224px 224px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  background-color: #ffffff;
  box-shadow: 0px -4px 25px 0px rgba(0, 0, 0, 0.15);
}
.app-body.page-team-feedback .feedback-details .actions-bottom .send-feedback-btn {
  width: 100%;
}
.app-body.page-team-feedback .feedback-details .actions-bottom .send-feedback-btn .new-icon-button {
  width: 100%;
  justify-content: center;
}
.app-body.page-admin-dashboard {
  padding-right: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
  padding-left: calc(40px + calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard {
    padding: 80px 24px 40px 24px;
  }
}
@media (max-width: 480px) {
  .app-body.page-admin-dashboard {
    padding: 0px 24px 40px 24px;
  }
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .desktop-only {
    display: none !important;
  }
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .mobile-only {
    display: none !important;
  }
}
.app-body.page-admin-dashboard .react-tooltip {
  z-index: 1;
}
.app-body.page-admin-dashboard .status-icon {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  position: relative;
  padding: 5px;
}
.app-body.page-admin-dashboard .status-icon.green {
  background-color: #d1e7dd;
}
.app-body.page-admin-dashboard .status-icon.green .img-wrap img {
  filter: invert(55%) sepia(11%) saturate(3554%) hue-rotate(82deg) brightness(93%) contrast(75%);
}
.app-body.page-admin-dashboard .status-icon.red {
  background-color: #f2d2d6;
}
.app-body.page-admin-dashboard .status-icon.red .img-wrap img {
  filter: invert(35%) sepia(34%) saturate(7269%) hue-rotate(335deg) brightness(89%) contrast(93%);
}
.app-body.page-admin-dashboard .status-icon .img-wrap {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  position: relative;
}
.app-body.page-admin-dashboard .status-icon .img-wrap img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}
.app-body.page-admin-dashboard .chipp {
  width: 92px;
  border-radius: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-body.page-admin-dashboard .chipp.green {
  color: #28a745;
  background-color: #d1e7dd;
}
.app-body.page-admin-dashboard .chipp.blue {
  color: #348cf9;
  background-color: #b0d2fd;
}
.app-body.page-admin-dashboard .chipp.red {
  color: #dc3545;
  background-color: #f2d2d6;
}
.app-body.page-admin-dashboard .chipp.gray {
  color: #7b7c7c;
  background-color: #dee2e6;
}
.app-body.page-admin-dashboard .pagination-container {
  margin-top: 32px;
}
.app-body.page-admin-dashboard .filters-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
  position: relative;
  column-gap: 4px;
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .filters-wrap {
    margin-top: 17px;
  }
}
.app-body.page-admin-dashboard .filters-wrap .filters-closer {
  position: fixed;
  top: -200px;
  right: -200px;
  bottom: -200px;
  left: -200px;
  z-index: 3;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  width: 100%;
  border: 1px solid #348cf9;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 5px 15px 0px #00000026;
  background-color: #ffffff;
  z-index: 4;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #000000;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  margin-top: 8px;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-actions .clear-btn-override .inner-text {
  color: #dc3545;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-actions .clear-btn-override:hover .inner-text {
  color: #ffffff;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body {
  display: flex;
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body {
    flex-direction: row;
  }
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body {
    flex-direction: column;
  }
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap {
    border-left: 1px solid #adb5bd;
    padding-right: 24px;
    padding-left: 24px;
  }
  .app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap:first-child {
    border-left: none;
    padding-left: 0;
    width: 280px;
  }
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap {
    border-top: 1px solid #adb5bd;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap:first-child {
    border-top: none;
    padding-top: 0;
  }
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap .checkbox-wrap .label {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #000000;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap .checkbox-wrap .options-layout {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  column-gap: 8px;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap .checkbox-wrap .options-layout label {
  font-size: 12px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  cursor: pointer;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap .checkbox-wrap .options-layout label input {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0;
}
.app-body.page-admin-dashboard .filters-wrap .filters-container .filters-body .filter-wrap .form-group label {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #000000;
}
.app-body.page-admin-dashboard .filters-wrap-invest-vehicles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 19px;
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .filters-wrap-invest-vehicles {
    margin-top: 12px;
  }
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .filters-wrap-invest-vehicles .filter-type {
    width: 180px;
  }
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .filters-wrap-invest-vehicles .filter-type {
    width: 55%;
  }
}
.app-body.page-admin-dashboard .tabs-scrollable-wrap {
  overflow-x: auto;
  border-bottom: 0.5px solid #348cf9;
}
.app-body.page-admin-dashboard .tabs-scrollable-wrap .tabs-wrap {
  display: flex;
  flex-direction: row;
}
.app-body.page-admin-dashboard .tabs-scrollable-wrap .tabs-wrap .tab {
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
}
.app-body.page-admin-dashboard .tabs-scrollable-wrap .tabs-wrap .tab.active {
  background-color: #348cf9;
  cursor: default;
  color: #ffffff;
}
.app-body.page-admin-dashboard .no-results {
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  text-align: center;
  color: #000000;
}
.app-body.page-admin-dashboard .results-table {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 24px;
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .results-table {
    margin-top: 19px;
  }
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .results-table {
    overflow-y: auto;
    overscroll-behavior: auto;
  }
  .app-body.page-admin-dashboard .results-table::-webkit-scrollbar {
    height: 5px;
    background-color: #ffffff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .app-body.page-admin-dashboard .results-table::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
    border-radius: 9px;
  }
  .app-body.page-admin-dashboard .results-table::-webkit-scrollbar-thumb:hover {
    background-color: #7b7c7c;
  }
}
.app-body.page-admin-dashboard .results-table .table-head {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  width: max-content;
}
.app-body.page-admin-dashboard .results-table .table-head .head-item {
  width: calc((100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px) / 8);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
  padding-right: 4px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .results-table .table-head .head-item:first-child {
  padding-left: 60px;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile {
  padding: 24px 23px;
  background-color: #ffffff;
  box-shadow: 0px 5px 15px 0px #00000026;
  border-radius: 8px;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile .manage-groups-mobile .new-icon-button {
  width: 100%;
  justify-content: center;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #adb5bd;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile .row.last {
  border-bottom: none;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile .row .field {
  width: 50%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #000000;
  padding-right: 4px;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile .row .value {
  width: 50%;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile .row .value.text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #000000;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .results-table .user-item-mobile .row .value.username {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #348cf9;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-left: 4px;
}
.app-body.page-admin-dashboard .results-table .user-item {
  border-radius: 4px;
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}
.app-body.page-admin-dashboard .results-table .user-item:last-child {
  margin-bottom: 12px;
}
.app-body.page-admin-dashboard .results-table .user-item .cell {
  width: calc((100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px) / 8);
}
.app-body.page-admin-dashboard .results-table .user-item .cell.username {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 4px;
}
.app-body.page-admin-dashboard .results-table .user-item .cell.username .name {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #348cf9;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-left: 8px;
}
.app-body.page-admin-dashboard .results-table .user-item .cell.text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2px;
}
.app-body.page-admin-dashboard .results-table-activities {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 24px;
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .results-table-activities {
    margin-top: 19px;
  }
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .results-table-activities {
    overflow-y: auto;
    overscroll-behavior: auto;
  }
  .app-body.page-admin-dashboard .results-table-activities::-webkit-scrollbar {
    height: 5px;
    background-color: #ffffff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .app-body.page-admin-dashboard .results-table-activities::-webkit-scrollbar-thumb {
    background-color: #adb5bd;
    border-radius: 9px;
  }
  .app-body.page-admin-dashboard .results-table-activities::-webkit-scrollbar-thumb:hover {
    background-color: #7b7c7c;
  }
}
.app-body.page-admin-dashboard .results-table-activities .table-head {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  width: max-content;
}
.app-body.page-admin-dashboard .results-table-activities .table-head .head-item {
  width: calc((100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px) / 7);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
  padding-right: 4px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .results-table-activities .table-head .head-item:first-child {
  padding-left: 36px;
}
.app-body.page-admin-dashboard .results-table-activities .user-item-mobile {
  padding: 24px 23px;
  background-color: #ffffff;
  box-shadow: 0px 5px 15px 0px #00000026;
  border-radius: 8px;
}
.app-body.page-admin-dashboard .results-table-activities .user-item-mobile .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #adb5bd;
}
.app-body.page-admin-dashboard .results-table-activities .user-item-mobile .row.last {
  border-bottom: none;
}
.app-body.page-admin-dashboard .results-table-activities .user-item-mobile .row .field {
  width: 50%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #000000;
  padding-right: 4px;
}
.app-body.page-admin-dashboard .results-table-activities .user-item-mobile .row .value {
  width: 50%;
}
.app-body.page-admin-dashboard .results-table-activities .user-item-mobile .row .value.text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #000000;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .results-table-activities .user-item {
  border-radius: 4px;
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}
.app-body.page-admin-dashboard .results-table-activities .user-item:last-child {
  margin-bottom: 12px;
}
.app-body.page-admin-dashboard .results-table-activities .user-item .cell {
  width: calc((100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px) / 7);
}
.app-body.page-admin-dashboard .results-table-activities .user-item .cell:first-child {
  padding-left: 36px;
}
.app-body.page-admin-dashboard .results-table-activities .user-item .cell.text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
  padding-right: 2px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .results-table-vehicles {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 19px;
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .results-table-vehicles {
    margin-top: 14px;
  }
}
.app-body.page-admin-dashboard .results-table-vehicles .table-head {
  display: flex;
  flex-direction: row;
  width: max-content;
  margin-bottom: 12px;
}
.app-body.page-admin-dashboard .results-table-vehicles .table-head .head-item {
  width: calc((100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px) / 8);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
  padding-right: 4px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .results-table-vehicles .table-head .head-item:first-child {
  padding-left: 32px;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item-mobile {
  padding: 24px 23px;
  background-color: #ffffff;
  box-shadow: 0px 5px 15px 0px #00000026;
  border-radius: 8px;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item-mobile .view-details-mobile .new-icon-button {
  width: 100%;
  justify-content: center;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item-mobile .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #adb5bd;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item-mobile .row.last {
  border-bottom: none;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item-mobile .row .field {
  width: 50%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #000000;
  padding-right: 4px;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item-mobile .row .value {
  width: 50%;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item-mobile .row .value.text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #000000;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item {
  border-radius: 4px;
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #f4f5f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item:last-child {
  margin-bottom: 12px;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item .cell {
  width: calc((100vw - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px - 40px - calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) - 20px) / 8);
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item .cell:first-child {
  padding-left: 32px;
}
.app-body.page-admin-dashboard .results-table-vehicles .entry-item .cell.text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
  padding-right: 2px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info {
  background-color: #ffffff;
  margin-top: -38px;
}
.app-body.page-admin-dashboard .user-info .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 14px 0 14px 0;
  background-color: #ffffff;
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .user-info .backlink {
    width: fit-content;
  }
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .user-info .backlink {
    margin: 0 0 14px 0;
  }
}
.app-body.page-admin-dashboard .user-info .user-info-tab-content {
  display: flex;
  margin-top: 20px;
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .user-info .user-info-tab-content {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .user-info .user-info-tab-content {
    flex-direction: column;
  }
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .user-info .user-info-tab-content .section-one,
  .app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two {
    flex: 0 0 50%;
  }
}
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-one .field-wrap,
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two .field-wrap {
  margin-top: 24px;
}
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-one .field-wrap:first-child,
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two .field-wrap:first-child {
  margin-top: 0;
}
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-one .field-wrap .field-name,
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two .field-wrap .field-name {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #000000;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-one .field-wrap .field-value.text,
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two .field-wrap .field-value.text {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-one .field-wrap .field-value.with-chipp,
.app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two .field-wrap .field-value.with-chipp {
  margin-top: 2px;
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .user-info .user-info-tab-content .section-one .field-wrap .field-value.with-chipp,
  .app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two .field-wrap .field-value.with-chipp {
    margin-top: 4px;
  }
}
@media (min-width: 1201px) {
  .app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two {
    border-left: 0.5px solid #7b7c7c;
    padding-left: 20px;
  }
}
@media (max-width: 1200px) {
  .app-body.page-admin-dashboard .user-info .user-info-tab-content .section-two {
    border-top: 0.5px solid #7b7c7c;
    margin-top: 20px;
    padding-top: 20px;
  }
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attributes-table-heading-wrap-desktop {
  display: flex;
  flex-direction: row;
  padding: 19px 36px;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attributes-table-heading-wrap-desktop .title {
  flex: 0 0 33.33%;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-desktop {
  display: flex;
  flex-direction: row;
  padding: 19px 36px;
  box-shadow: 0px 5px 15px 0px #00000026;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 12px;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-desktop .text {
  flex: 0 0 33.33%;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  padding-right: 4px;
  overflow-wrap: break-word;
  word-break: break-all;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-mobile {
  padding: 14px 24px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 16px;
  box-shadow: 0px 5px 15px 0px #00000026;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-mobile .row {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 0.5px solid #adb5bd;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-mobile .row:last-child {
  border-bottom: none;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-mobile .row .attribute {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  width: 44%;
  padding-right: 4px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-mobile .row .attribute.bold {
  font-weight: 500;
}
.app-body.page-admin-dashboard .user-info .user-attributes-tab-content .attribute-item-mobile .row .attribute.value {
  width: 56%;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-table-heading-wrap-desktop {
  display: flex;
  flex-direction: row;
  padding: 19px 36px;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-table-heading-wrap-desktop .title {
  flex: 0 0 33.33%;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-table-heading-wrap-desktop .title.long {
  flex: 0 0 66.66%;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-desktop {
  display: flex;
  flex-direction: row;
  padding: 19px 36px;
  box-shadow: 0px 5px 15px 0px #00000026;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 12px;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-desktop .text {
  flex: 0 0 33.33%;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  padding-right: 4px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-desktop .text.long {
  flex: 0 0 66.66%;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-mobile {
  padding: 14px 24px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 16px;
  box-shadow: 0px 5px 15px 0px #00000026;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-mobile .row {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 0.5px solid #adb5bd;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-mobile .row:last-child {
  border-bottom: none;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-mobile .row .attribute {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  width: 44%;
  padding-right: 4px;
  overflow-wrap: break-word;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-mobile .row .attribute.bold {
  font-weight: 500;
}
.app-body.page-admin-dashboard .user-info .user-groups-tab-content .group-item-mobile .row .attribute.value {
  width: 56%;
}
.admin-dashboard-select-user-groups-popup-closer {
  position: fixed;
  top: -200px;
  bottom: -200px;
  left: -200px;
  right: -200px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.admin-dashboard-select-user-groups-popup {
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 5px 15px 0px #00000026;
  row-gap: 20px;
  padding: 24px;
}
@media (min-width: 1201px) {
  .admin-dashboard-select-user-groups-popup {
    width: 710px;
  }
}
@media (max-width: 1200px) {
  .admin-dashboard-select-user-groups-popup {
    width: calc(100% - 24px - 24px);
  }
}
.admin-dashboard-select-user-groups-popup .title {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.admin-dashboard-select-user-groups-popup .subtitle {
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
}
.admin-dashboard-select-user-groups-popup .selections-title {
  display: flex;
  flex-direction: row;
  padding-right: 14px;
  padding-left: 14px;
}
.admin-dashboard-select-user-groups-popup .selections-title .title {
  flex: 0 0 30%;
  overflow-wrap: break-word;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  padding-right: 4px;
}
@media (max-width: 1200px) {
  .admin-dashboard-select-user-groups-popup .selections-title .title {
    flex: 0 0 50%;
  }
  .admin-dashboard-select-user-groups-popup .selections-title .title.long {
    flex: 0 0 50%;
    padding-left: 6px;
  }
}
.admin-dashboard-select-user-groups-popup .selections-title .title.long {
  flex: 0 0 70%;
}
.admin-dashboard-select-user-groups-popup .selections-title .title:first-child {
  padding-left: 16px;
}
.admin-dashboard-select-user-groups-popup .selections {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: 50dvh;
  overflow-y: auto;
  margin-left: -3px;
  margin-right: -3px;
  padding-left: 3px;
  padding-right: 3px;
}
.admin-dashboard-select-user-groups-popup .selections .row {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border-radius: 4px;
  box-shadow: 0px 3px 7px 0px #00000026;
  padding: 14px;
}
.admin-dashboard-select-user-groups-popup .selections .row:last-child {
  margin-bottom: 7px;
}
.admin-dashboard-select-user-groups-popup .selections .row .name {
  flex: 0 0 30%;
  padding-right: 4px;
}
@media (max-width: 1200px) {
  .admin-dashboard-select-user-groups-popup .selections .row .name {
    flex: 0 0 50%;
  }
}
.admin-dashboard-select-user-groups-popup .selections .row .name .options-layout {
  display: flex;
  flex-direction: row;
}
.admin-dashboard-select-user-groups-popup .selections .row .name .options-layout label {
  font-size: 12px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
  overflow-wrap: break-word;
  flex-wrap: wrap;
}
.admin-dashboard-select-user-groups-popup .selections .row .name .options-layout label input {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0;
}
.admin-dashboard-select-user-groups-popup .selections .row .description {
  overflow-wrap: break-word;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  flex: 0 0 70%;
}
@media (max-width: 1200px) {
  .admin-dashboard-select-user-groups-popup .selections .row .description {
    flex: 0 0 50%;
    padding-left: 6px;
  }
}
.admin-dashboard-select-user-groups-popup .actions {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
}
@media (min-width: 1201px) {
  .admin-dashboard-select-user-groups-popup .actions {
    justify-content: flex-end;
  }
}
@media (max-width: 1200px) {
  .admin-dashboard-select-user-groups-popup .actions {
    justify-content: space-evenly;
  }
  .admin-dashboard-select-user-groups-popup .actions .new-icon-button-wrap {
    width: 100%;
  }
  .admin-dashboard-select-user-groups-popup .actions .new-icon-button-wrap .new-icon-button {
    width: inherit;
    justify-content: center;
  }
}
.app-body.page-profiler-watch-list {
  padding-bottom: 16px;
  position: relative;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .mobile-only {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .desktop-only {
    display: none !important;
  }
}
.app-body.page-profiler-watch-list .watchlist-title {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: #000000;
  margin-top: 24px;
  background-color: #ffffff;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .watchlist-title {
    position: sticky;
    z-index: 2;
    top: 80px;
  }
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .watchlist-title {
    font-size: 24px;
    line-height: 36px;
    margin-top: 16px;
  }
}
.app-body.page-profiler-watch-list .watchlist-title::before {
  display: block;
  content: ' ';
  width: 20px;
  height: 100%;
  position: absolute;
  left: -20px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
}
.app-body.page-profiler-watch-list .watchlist-title::after {
  display: block;
  content: ' ';
  width: 20px;
  height: 100%;
  position: absolute;
  right: -20px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
}
.app-body.page-profiler-watch-list .listing-layout {
  position: relative;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout {
    margin-top: 43px;
    display: flex;
    gap: 20px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results {
  flex-grow: 1;
  position: relative;
}
.app-body.page-profiler-watch-list .listing-layout .results .loader-wrapper {
  flex-grow: 0;
  height: auto;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .loader-wrapper {
    margin-top: 32px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .error-wrapper {
  flex-grow: 0;
  height: auto;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .error-wrapper {
    margin-top: 78px;
  }
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .error-wrapper {
    padding: 0;
  }
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results {
    margin-top: -20px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header {
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 16px;
  padding-left: 32px;
  border-bottom: 1px solid #348cf9;
  justify-content: space-between;
  gap: 16px;
  position: sticky;
  top: 128px;
  background-color: #ffffff;
  z-index: 2;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header::before {
  display: block;
  content: ' ';
  width: 20px;
  height: 100%;
  position: absolute;
  left: -20px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header::after {
  display: block;
  content: ' ';
  width: 20px;
  height: 100%;
  position: absolute;
  right: -20px;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .results-header {
    display: none;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .cols {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  line-height: 14px;
  gap: 8px;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .cols .col {
  width: 20%;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .cols .col.right-align {
  text-align: end;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .cols .col.company-name {
  min-width: 132px;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .cols .col.company-revenue {
  max-width: 94px;
  min-width: 94px;
  width: 94px;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .cols .col.company-employees {
  max-width: 76px;
  min-width: 76px;
  width: 76px;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .actions {
  min-width: 200px;
}
.app-body.page-profiler-watch-list .listing-layout .results .results-header .actions.watchlist {
  min-width: 176px;
  max-width: 176px;
  width: 176px;
}
.app-body.page-profiler-watch-list .listing-layout .results .item {
  border-radius: 8px;
  margin-top: 16px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 16px;
  background-color: #ffffff;
}
.app-body.page-profiler-watch-list .listing-layout .results .item.no-shadow {
  box-shadow: none;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item {
    flex-direction: column;
    padding: 24px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .item .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}
.app-body.page-profiler-watch-list .listing-layout .results .item .cols * {
  overflow-wrap: break-word;
  word-break: break-word;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .cols {
    flex-direction: column;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .cols .col {
    align-items: center;
  }
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .cols .col {
    width: 20%;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .cols .col.company-name {
    min-width: 132px;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .cols .col.company-revenue {
    max-width: 94px;
    min-width: 94px;
    width: 94px;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .cols .col.company-employees {
    max-width: 76px;
    min-width: 76px;
    width: 76px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .item .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 168px;
  gap: 16px;
}
.app-body.page-profiler-watch-list .listing-layout .results .item .actions.default-search-actions {
  min-width: 171px;
}
.app-body.page-profiler-watch-list .listing-layout .results .item .actions.watchlist {
  min-width: 144px;
  max-width: 144px;
  width: 144px;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .actions .col {
    padding: 0;
    width: fit-content;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .actions .col.view-details-btn {
    flex-grow: 1;
    flex: 1;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .actions .col.view-details-btn .new-icon-button-wrap {
    width: 100%;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .item .actions .col.view-details-btn .new-icon-button-wrap .new-icon-button {
    width: 100%;
    justify-content: center;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .item:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .col.no-mobile {
    display: none;
  }
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .col.no-desktop {
    display: none;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .item .col.mobile-badge {
  padding: 0;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .col {
    width: 100%;
    border-bottom: 0.5px solid #adb5bd;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .col.no-border {
    border-bottom: 0;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .item .col .btn {
  padding: 10px;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .label {
    display: none;
  }
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .label {
    font-weight: 500;
    color: #000000;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .item .value {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .value.right-align-desktop {
    justify-content: flex-end;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .item .value .year {
  font-size: 10px;
  line-height: 10px;
  margin-top: -2px;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .item .value {
    width: 50%;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .no-results {
  background-color: #f4f5f7;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .no-results {
    padding: 104px calc(calc((calc(100vw - 2 * 40px) - 11 * 20px) / 12) + 20px);
    margin-top: 16px;
  }
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .no-results {
    padding: 24px;
    margin-top: 16px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .no-results h5 {
  line-height: 20px;
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .no-results h5 {
    line-height: 26px;
    margin-bottom: 24px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .no-results .text-help {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 12px;
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .no-results .text-help {
    margin-bottom: 24px;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .no-results ol {
  list-style: decimal;
  padding-left: 16px;
  font-size: 14px;
  line-height: 20px;
}
.app-body.page-profiler-watch-list .listing-layout .results .no-results ol li img {
  margin-left: -16px;
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (min-width: 481px) {
  .app-body.page-profiler-watch-list .listing-layout .results .no-results ol li img {
    border-radius: 8px;
  }
}
@media (max-width: 480px) {
  .app-body.page-profiler-watch-list .listing-layout .results .no-results ol li {
    margin-bottom: 16px;
  }
  .app-body.page-profiler-watch-list .listing-layout .results .no-results ol li:last-of-type {
    margin-bottom: 0;
  }
}
.app-body.page-profiler-watch-list .listing-layout .results .no-results a {
  color: #348cf9;
  text-decoration: underline;
  font-weight: 600;
}
.app-body.page-profiler-watch-list .listing-layout .results .badge {
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
}
.app-body.page-profiler-watch-list .listing-layout .results .badge.success {
  color: #28a745;
  background-color: #c1edd9;
}
.app-body.page-profiler-watch-list .listing-layout .results .badge.warning {
  color: #ed7200;
  background-color: #ffe79e;
}
.app-body.page-profiler-watch-list .listing-layout .results .badge.info {
  color: #7b7c7c;
  background-color: #e9ecef;
}
.company-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.company-details .backlink {
  display: block;
  padding-left: 28px;
  background: url('../public/icons/arrow-left.svg') no-repeat left center;
  margin: 14px 0 14px 0;
  background-color: #ffffff;
  cursor: pointer;
}
@media (min-width: 481px) {
  .company-details .backlink {
    width: fit-content;
  }
}
@media (max-width: 480px) {
  .company-details .backlink {
    font-weight: 600;
  }
}
.company-details .company-details-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #adb5bd;
}
.company-details .company-details-header .info-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 20px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
}
.company-details .company-details-header .info-wrap .loader-wrap {
  padding-top: 9px;
}
.company-details .company-details-header .info-wrap .logo {
  height: 42px;
  width: auto;
}
.company-details .company-details-header .info-wrap .info-col {
  display: block;
}
.company-details .company-details-header .info-wrap .info-col .bold-text {
  font-weight: 600;
}
.company-details .company-details-header .info-wrap .info-col .info-link {
  color: #348cf9;
  cursor: pointer;
}
.company-details .company-details-header .info-wrap .info-col .info-link:hover {
  color: #0064df;
}
.company-details .company-details-header .info-wrap .info-col:not(:nth-child(2)) {
  margin-left: 10px;
}
.company-details .company-details-header #psrd-btn {
  margin-left: 30px;
  margin-top: 7px;
}
.company-details .company-details-header-mobile {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 19px 0;
  border-bottom: 0.5px solid #adb5bd;
}
.company-details .company-details-header-mobile .header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
}
.company-details .company-details-header-mobile .header-row.company-logo {
  align-items: flex-start;
}
.company-details .company-details-header-mobile .header-row .left-column {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.company-details .company-details-header-mobile .header-row .left-column .bold-text {
  font-weight: 600;
}
.company-details .company-details-header-mobile .header-row .left-column .text-link {
  color: #348cf9;
  cursor: pointer;
}
.company-details .company-details-header-mobile .header-row .left-column .text-link:hover {
  color: #0064df;
}
.company-details .company-details-header-mobile .header-row .loader-wrap {
  margin-top: 12px;
  margin-right: 16px;
}
.company-details .company-details-header-mobile .header-row .logo {
  height: 36px;
  width: auto;
}
.company-details .company-details-header-mobile .header-row #psrd-btn .new-icon-button {
  padding: 8px;
}
.company-details .tabs-scrollable-wrap {
  overflow-x: auto;
  margin-top: 25px;
  border-bottom: 0.5px solid #348cf9;
}
.company-details .tabs-scrollable-wrap .tabs-wrap {
  display: flex;
  flex-direction: row;
}
.company-details .tabs-scrollable-wrap .tabs-wrap .tab {
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #000000;
  white-space: nowrap;
}
.company-details .tabs-scrollable-wrap .tabs-wrap .tab.active {
  background-color: #348cf9;
  cursor: default;
  color: #ffffff;
}
.company-details .tabs-scrollable-wrap .tabs-wrap .tab.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
@-webkit-keyframes company-details-fade-in-element {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes company-details-fade-in-element {
  from {
    opacity: 0;
    -moz-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes company-details-fade-in-element {
  from {
    opacity: 0;
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes company-details-fade-in-element {
  from {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
.company-details .general-tab-cards {
  display: flex;
  flex-direction: row;
  gap: 26px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000000;
  opacity: 0;
  -webkit-animation: company-details-fade-in-element 0.5s forwards;
  -moz-animation: company-details-fade-in-element 0.5s forwards;
  -o-animation: company-details-fade-in-element 0.5s forwards;
  animation: company-details-fade-in-element 0.5s forwards;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards {
    flex-direction: column;
  }
}
.company-details .general-tab-cards .card {
  position: relative;
  width: calc(50% - 13px);
  padding: 26px 32px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card.financials {
    padding: 24px 23px;
  }
}
.company-details .general-tab-cards .card.financials #company-details-financials-info-scroll-mobile {
  position: absolute;
  top: 22px;
  right: 23px;
}
.company-details .general-tab-cards .card.financials #company-details-financials-info-scroll-mobile .new-icon-button {
  border-radius: 50%;
}
.company-details .general-tab-cards .card.financials .company-details-financials-info-scroll-mobile-tooltip {
  max-width: calc(100dvw - 24px - 24px - 23px - 23px);
  z-index: 1;
}
.company-details .general-tab-cards .card.financials .company-details-financials-info-scroll-mobile-tooltip .info-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}
.company-details .general-tab-cards .card.shareholder {
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card {
    width: 100%;
  }
}
.company-details .general-tab-cards .card .title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.company-details .general-tab-cards .card .sub-title {
  font-weight: 400;
  color: #7b7c7c;
}
.company-details .general-tab-cards .card .card-row {
  padding: 16px 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  border-bottom: 0.5px solid #dee2e6;
  align-items: center;
  color: #000000;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .card-row {
    padding: 16px 0;
    flex-direction: column;
    row-gap: 4px;
    align-items: flex-start;
  }
}
.company-details .general-tab-cards .card .card-row.description {
  align-items: flex-start;
}
.company-details .general-tab-cards .card .card-row.last {
  border-bottom: none;
  padding-bottom: 0;
}
.company-details .general-tab-cards .card .card-row .property {
  font-weight: 600;
  width: 110px;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .card-row .property {
    width: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
}
.company-details .general-tab-cards .card .card-row .property.number-of-employees {
  align-self: flex-start;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .card-row .value {
    font-size: 14px;
    line-height: 20px;
  }
}
.company-details .general-tab-cards .card .card-row .value.description {
  font-size: 14px;
  line-height: 18px;
  white-space: pre-line;
}
.company-details .general-tab-cards .card .card-row .employees-array-wrap {
  border-collapse: separate;
  border-spacing: 16px;
  margin: -16px 0 0 -16px;
}
.company-details .general-tab-cards .card .card-row .employees-array-wrap tr td:nth-child(1) {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
}
.company-details .general-tab-cards .card .card-row .employees-array-wrap tr td:nth-child(1) .number {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.company-details .general-tab-cards .card .card-row .employees-array-wrap tr td:nth-child(1) .number-details {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}
.company-details .general-tab-cards .card .card-row .employees-array-wrap tr td:nth-child(2) {
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.company-details .general-tab-cards .card .general-financials-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels {
  width: 150px;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .general-financials-layout .general-financials-labels {
    width: 130px;
  }
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels {
  padding: 16px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  column-gap: 16px;
  border-bottom: 0.5px solid #dee2e6;
  color: #000000;
  position: relative;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels::after {
  content: '';
  position: absolute;
  top: 0.5px;
  bottom: 0.5px;
  right: -5px;
  width: 5px;
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels {
    font-size: 14px;
    line-height: 20px;
  }
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels.head-labels {
  border-bottom: none;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels.last {
  border-bottom: none;
  padding-bottom: 0;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels .label {
  font-weight: 600;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels .label.head-label {
  font-weight: 400;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-labels .labels .sub-label {
  color: #7b7c7c;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values {
  width: 100%;
  overflow-x: auto;
  overscroll-behavior: auto;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels-wrap {
  display: inline-flex;
  /* update */
  min-width: 100%;
  /* added */
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8px;
  border-bottom: 0.5px solid #dee2e6;
  padding: 16px 0;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels-wrap.last {
  border-bottom: none;
  padding-bottom: 0;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels-wrap.head-labels-wrap {
  border-bottom: none;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
  text-align: right;
  width: 60px;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels {
    font-size: 14px;
    line-height: 20px;
    width: 50px;
    flex-shrink: 0;
  }
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels .label {
  font-weight: 600;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels .label.head-label {
  font-weight: 400;
}
.company-details .general-tab-cards .card .general-financials-layout .general-financials-values .labels .sub-label {
  color: #7b7c7c;
}
.company-details .general-tab-cards .card .product-portfolio-value {
  padding: 16px 24px;
  color: #000000;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  white-space: pre-line;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .product-portfolio-value {
    padding: 16px 0 0 0 ;
  }
}
.company-details .general-tab-cards .card .shareholder-flat-structure-wrap {
  flex: 1;
}
.company-details .general-tab-cards .card .shareholder-flat-structure-wrap .loader-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100;
  padding: 19px 36px;
  background-color: #f4f5f7;
  border-radius: 4px;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry {
    padding: 18px 12px;
  }
}
.company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry {
  flex-shrink: 0;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry {
    font-size: 14px;
    line-height: 20px;
  }
}
.company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(1) {
  width: 30%;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(1) {
    width: 25%;
  }
}
.company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(2) {
  width: 50%;
  font-size: 12px;
  line-height: 18px;
}
.company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(3) {
  width: 20%;
}
@media (max-width: 480px) {
  .company-details .general-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(3) {
    width: 25%;
  }
}
.company-details .product-portfolio-tab-cards {
  margin-top: 20px;
  opacity: 0;
  -webkit-animation: company-details-fade-in-element 0.5s forwards;
  -moz-animation: company-details-fade-in-element 0.5s forwards;
  -o-animation: company-details-fade-in-element 0.5s forwards;
  animation: company-details-fade-in-element 0.5s forwards;
}
.company-details .product-portfolio-tab-cards .card {
  width: 100%;
  padding: 26px 48px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 16px;
}
.company-details .product-portfolio-tab-cards .card:first-child {
  margin-top: 0;
}
.company-details .product-portfolio-tab-cards .card:last-child {
  margin-bottom: 16px;
}
@media (max-width: 480px) {
  .company-details .product-portfolio-tab-cards .card {
    margin-top: 20px;
    padding: 26px 32px;
    flex-direction: column;
    row-gap: 16px;
  }
}
.company-details .product-portfolio-tab-cards .card .property {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  width: 200px;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .company-details .product-portfolio-tab-cards .card .property {
    font-size: 14px;
    line-height: 20px;
  }
}
.company-details .product-portfolio-tab-cards .card .value {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  white-space: pre-line;
}
.company-details .product-portfolio-tab-cards .card .value ul.default {
  list-style: disc;
  margin-left: 17px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.company-details .financials-tab-content {
  opacity: 0;
  -webkit-animation: company-details-fade-in-element 0.5s forwards;
  -moz-animation: company-details-fade-in-element 0.5s forwards;
  -o-animation: company-details-fade-in-element 0.5s forwards;
  animation: company-details-fade-in-element 0.5s forwards;
  flex: 1;
}
.company-details .financials-tab-content .loader-wrapper,
.company-details .financials-tab-content .error-wrapper {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.company-details .financials-tab-content .group-head {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
  position: relative;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .group-head {
    row-gap: 8px;
    margin-bottom: 8px;
  }
}
.company-details .financials-tab-content .group-head .top-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  column-gap: 8px;
}
.company-details .financials-tab-content .group-head .top-row .lvl-filter-popup {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  border-radius: 4px;
  border: 1px solid #348cf9;
  z-index: 4;
  background-color: #ffffff;
}
.company-details .financials-tab-content .group-head .top-row .lvl-filter-popup .lvl-buttons-wrap {
  display: flex;
  flex-direction: column;
}
.company-details .financials-tab-content .group-head .top-row .lvl-filter-popup .lvl-buttons-wrap .new-icon-button-wrap {
  width: 100%;
  align-items: stretch;
}
.company-details .financials-tab-content .group-head .top-row .lvl-filter-popup .lvl-buttons-wrap .new-icon-button-wrap:first-child .new-icon-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.company-details .financials-tab-content .group-head .top-row .lvl-filter-popup .lvl-buttons-wrap .new-icon-button-wrap .new-icon-button {
  width: 100%;
  border-radius: 0;
  padding: 10px 17px;
}
.company-details .financials-tab-content .group-head .top-row .filter-action-buttons-wrap {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}
.company-details .financials-tab-content .group-head .top-row .filter-action-buttons-wrap .new-icon-button-wrap .new-icon-button:hover .inner-text {
  color: #ffffff;
}
.company-details .financials-tab-content .group-head .top-row .filter-action-buttons-wrap .new-icon-button-wrap .new-icon-button .inner-text {
  color: #dc3545;
}
.company-details .financials-tab-content .group-head .bottom-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.company-details .financials-tab-content .group-head .bottom-row .group-title {
  color: #000000;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .group-head .bottom-row .group-title {
    font-size: 18px;
    line-height: 28px;
  }
}
.company-details .financials-tab-content .group-head .mobile-scrollable-content-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 1;
}
.company-details .financials-tab-content .group-head .mobile-scrollable-content-row .company-details-financials-mobile-row-info-scroll-btn .new-icon-button {
  border-radius: 50%;
}
.company-details .financials-tab-content .group-head .mobile-scrollable-content-row .company-details-financials-mobile-row-info-scroll-tooltip {
  max-width: calc(100dvw - 24px - 24px);
}
.company-details .financials-tab-content .group-head .mobile-scrollable-content-row .company-details-financials-mobile-row-info-scroll-tooltip .info-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}
.company-details .financials-tab-content .no-financials-items {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.company-details .financials-tab-content .financials-table {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (min-width: 481px) {
  .company-details .financials-tab-content .financials-table .left-content {
    flex-shrink: 0;
  }
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .left-content {
    width: 50%;
  }
}
.company-details .financials-tab-content .financials-table .left-content .row {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
  position: relative;
}
.company-details .financials-tab-content .financials-table .left-content .row::after {
  content: '';
  position: absolute;
  top: 0.5px;
  bottom: 0.5px;
  right: -5px;
  width: 5px;
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}
.company-details .financials-tab-content .financials-table .left-content .row.lvl1 {
  border-top: 1px solid #adb5bd;
  border-bottom: 1px solid #adb5bd;
}
.company-details .financials-tab-content .financials-table .left-content .row.lvl1 .row-cell .label {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .left-content .row.lvl1 .row-cell .label {
    font-size: 16px;
    line-height: 24px;
  }
}
.company-details .financials-tab-content .financials-table .left-content .row.lvl2 .row-cell .label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .left-content .row.lvl2 .row-cell .label {
    font-size: 14px;
    line-height: 21px;
  }
}
.company-details .financials-tab-content .financials-table .left-content .row.lvl3 .row-cell .label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .left-content .row.lvl3 .row-cell .label {
    font-size: 14px;
    line-height: 21px;
  }
}
.company-details .financials-tab-content .financials-table .left-content .row.lvl4 .row-cell .label,
.company-details .financials-tab-content .financials-table .left-content .row.lvl5 .row-cell .label,
.company-details .financials-tab-content .financials-table .left-content .row.lvl6 .row-cell .label,
.company-details .financials-tab-content .financials-table .left-content .row.lvl7 .row-cell .label,
.company-details .financials-tab-content .financials-table .left-content .row.lvl8 .row-cell .label,
.company-details .financials-tab-content .financials-table .left-content .row.lvl9 .row-cell .label,
.company-details .financials-tab-content .financials-table .left-content .row.lvl10 .row-cell .label {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .left-content .row.lvl4 .row-cell .label,
  .company-details .financials-tab-content .financials-table .left-content .row.lvl5 .row-cell .label,
  .company-details .financials-tab-content .financials-table .left-content .row.lvl6 .row-cell .label,
  .company-details .financials-tab-content .financials-table .left-content .row.lvl7 .row-cell .label,
  .company-details .financials-tab-content .financials-table .left-content .row.lvl8 .row-cell .label,
  .company-details .financials-tab-content .financials-table .left-content .row.lvl9 .row-cell .label,
  .company-details .financials-tab-content .financials-table .left-content .row.lvl10 .row-cell .label {
    font-size: 12px;
    line-height: 18px;
  }
}
@media (min-width: 481px) {
  .company-details .financials-tab-content .financials-table .left-content .row .row-cell.property {
    width: 234px;
  }
}
.company-details .financials-tab-content .financials-table .left-content .row .row-cell.performance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 98px;
}
.company-details .financials-tab-content .financials-table .left-content .row .row-cell .label {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
}
.company-details .financials-tab-content .financials-table .right-content {
  overflow-x: auto;
  overscroll-behavior: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
}
@media (min-width: 481px) {
  .company-details .financials-tab-content .financials-table .right-content {
    flex: 1;
  }
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .right-content {
    width: 50%;
  }
}
.company-details .financials-tab-content .financials-table .right-content .row {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
  justify-content: space-between;
  flex-shrink: 0;
}
.company-details .financials-tab-content .financials-table .right-content .row.lvl1 {
  border-top: 1px solid #adb5bd;
  border-bottom: 1px solid #adb5bd;
}
.company-details .financials-tab-content .financials-table .right-content .row.lvl1 .row-cell .label {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .right-content .row.lvl1 .row-cell .label {
    font-size: 16px;
    line-height: 24px;
  }
}
.company-details .financials-tab-content .financials-table .right-content .row.lvl2 .row-cell .label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .right-content .row.lvl2 .row-cell .label {
    font-size: 14px;
    line-height: 21px;
  }
}
.company-details .financials-tab-content .financials-table .right-content .row.lvl3 .row-cell .label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .right-content .row.lvl3 .row-cell .label {
    font-size: 14px;
    line-height: 21px;
  }
}
.company-details .financials-tab-content .financials-table .right-content .row.lvl4 .row-cell .label,
.company-details .financials-tab-content .financials-table .right-content .row.lvl5 .row-cell .label,
.company-details .financials-tab-content .financials-table .right-content .row.lvl6 .row-cell .label,
.company-details .financials-tab-content .financials-table .right-content .row.lvl7 .row-cell .label,
.company-details .financials-tab-content .financials-table .right-content .row.lvl8 .row-cell .label,
.company-details .financials-tab-content .financials-table .right-content .row.lvl9 .row-cell .label,
.company-details .financials-tab-content .financials-table .right-content .row.lvl10 .row-cell .label {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .right-content .row.lvl4 .row-cell .label,
  .company-details .financials-tab-content .financials-table .right-content .row.lvl5 .row-cell .label,
  .company-details .financials-tab-content .financials-table .right-content .row.lvl6 .row-cell .label,
  .company-details .financials-tab-content .financials-table .right-content .row.lvl7 .row-cell .label,
  .company-details .financials-tab-content .financials-table .right-content .row.lvl8 .row-cell .label,
  .company-details .financials-tab-content .financials-table .right-content .row.lvl9 .row-cell .label,
  .company-details .financials-tab-content .financials-table .right-content .row.lvl10 .row-cell .label {
    font-size: 12px;
    line-height: 18px;
  }
}
.company-details .financials-tab-content .financials-table .right-content .row .row-cell {
  width: 157px;
  text-align: right;
  flex-shrink: 0;
}
@media (max-width: 480px) {
  .company-details .financials-tab-content .financials-table .right-content .row .row-cell {
    width: 81px;
  }
}
.company-details .financials-tab-content .financials-table .right-content .row .row-cell .label {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 400;
}
.company-details .shareholder-structure-tab-cards {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 26px;
  flex: 1;
  opacity: 0;
  -webkit-animation: company-details-fade-in-element 0.5s forwards;
  -moz-animation: company-details-fade-in-element 0.5s forwards;
  -o-animation: company-details-fade-in-element 0.5s forwards;
  animation: company-details-fade-in-element 0.5s forwards;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards {
    flex-direction: column;
    row-gap: 16px;
  }
}
.company-details .shareholder-structure-tab-cards .card {
  width: calc(50% - 13px);
  padding: 26px 32px;
  border-radius: 8px;
  border: 0.5px solid #adb5bd;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card {
    width: 100%;
    padding: 0;
    border-radius: 0;
    border: none;
  }
}
.company-details .shareholder-structure-tab-cards .card:nth-child(2),
.company-details .shareholder-structure-tab-cards .card:nth-child(3) {
  margin-top: 34px;
}
.company-details .shareholder-structure-tab-cards .card:nth-child(3) {
  padding: 26px 21px 21px 21px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card:nth-child(3) {
    padding: 0;
  }
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
  margin-bottom: 2px;
  border-radius: 0;
  border: none;
  z-index: 2;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap {
  position: absolute;
  top: 32px;
  right: 0;
  width: 380px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #348cf9;
  background-color: #ffffff;
  z-index: 1;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap {
    width: auto;
  }
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap input[type='text'] {
  background-color: #ffffff;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap label[for='shareholder_share_percent'] {
  padding-left: 16px;
  display: inline-block;
  text-indent: 0;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap div.form-group:nth-child(3) label {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: flex-start;
  cursor: default;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap div.form-group:nth-child(3) label input[type='checkbox'] {
  margin-top: 6px;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap .filter-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  column-gap: 24px;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap .filter-actions .new-icon-button-wrap {
  width: 100%;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap .filter-actions .new-icon-button-wrap .new-icon-button {
  width: 100%;
  justify-content: center;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap .fiter-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.company-details .shareholder-structure-tab-cards .card.structure-filter-wrap .filters-wrap .fiter-header .header-title {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.company-details .shareholder-structure-tab-cards .card .heading {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #000000;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card .heading {
    font-size: 16px;
    line-height: 20px;
  }
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100;
  padding: 19px 36px;
  background-color: #f4f5f7;
  border-radius: 4px;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry {
    padding: 18px 12px;
  }
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry.entry-heading {
  background-color: #ffffff;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry.entry-heading {
    padding-bottom: 0;
    margin-top: 0;
  }
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry.entry-heading .column-entry {
  font-weight: 400;
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry.entry-heading .column-entry:nth-child(2) {
  font-size: 16px;
  line-height: 24px;
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry {
  flex-shrink: 0;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry {
    font-size: 14px;
    line-height: 21px;
  }
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(1) {
  width: 30%;
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(2) {
  width: 50%;
  font-size: 12px;
  line-height: 18px;
}
.company-details .shareholder-structure-tab-cards .card .shareholder-flat-structure-wrap .entry .column-entry:nth-child(3) {
  width: 20%;
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
  flex: 1;
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend {
  background-color: #f4f5f7;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 12px 8px;
  display: flex;
  width: auto;
  flex-direction: row;
  column-gap: 4px;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend {
    padding: 16px 8px;
  }
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend .legend-row {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  justify-content: flex-start;
  color: #000000;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  padding: 8px;
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend .legend-row {
    font-size: 12px;
    line-height: 16px;
  }
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend .legend-row .icon-wrap {
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend .legend-row .icon-wrap.beneficial {
  background-color: #348cf9;
  border-radius: 8px;
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend .legend-row .icon-wrap.beneficial img {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
  width: 10px;
  height: 10px;
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .legend .legend-row .icon-wrap img {
  width: 16px;
  height: 16px;
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .mini-controls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  column-gap: 4px;
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .mini-controls .expand-msg {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #28282b;
  padding-top: 4px;
  text-shadow: 1px 1px 1px white, 2px 2px 2px rgba(105, 105, 105, 0.5);
}
.company-details .shareholder-structure-tab-cards .card .shareholders-structure-wrap .mini-controls .mini-structure-action-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
}
.company-details .shareholder-structure-tab-cards .card .over-treeWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 481px) {
  .company-details .shareholder-structure-tab-cards .card .over-treeWrapper {
    flex: 1;
  }
}
.company-details .shareholder-structure-tab-cards .card .over-treeWrapper .treeWrapper {
  position: relative;
}
@media (min-width: 481px) {
  .company-details .shareholder-structure-tab-cards .card .over-treeWrapper .treeWrapper {
    flex: 1;
  }
}
@media (max-width: 480px) {
  .company-details .shareholder-structure-tab-cards .card .over-treeWrapper .treeWrapper {
    flex-shrink: 0;
    flex-grow: 1;
    height: calc(100vh - 80px - 136px - 16px) !important;
    margin-left: -24px;
    margin-right: -24px;
  }
}
.company-details .key-contacts-wrap {
  width: 100%;
  margin-top: 20px;
  opacity: 0;
  -webkit-animation: company-details-fade-in-element 0.5s forwards;
  -moz-animation: company-details-fade-in-element 0.5s forwards;
  -o-animation: company-details-fade-in-element 0.5s forwards;
  animation: company-details-fade-in-element 0.5s forwards;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.company-details .key-contacts-wrap .no-key-contacts {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.company-details .key-contacts-wrap .key-contacts-table {
  color: #000000;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row {
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.header {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}
@media (max-width: 480px) {
  .company-details .key-contacts-wrap .key-contacts-table .contact-row.header {
    display: none;
  }
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data {
  margin-top: 16px;
  padding-top: 26px;
  padding-bottom: 26px;
  background-color: #f4f5f7;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@media (max-width: 480px) {
  .company-details .key-contacts-wrap .key-contacts-table .contact-row.data {
    display: flex;
    flex-direction: column;
    padding: 24px 23px;
  }
  .company-details .key-contacts-wrap .key-contacts-table .contact-row.data:last-child {
    margin-bottom: 20px;
  }
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .mobile-property {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 0.5px solid #adb5bd;
  text-align: start;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .mobile-property .property-column {
  width: 50%;
  color: #000000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .mobile-property .property-column:first-child {
  font-weight: 500;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .mobile-property .property-column.text-link {
  color: #348cf9;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .mobile-property .property-column.text-link:hover {
  color: #0064df;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .mobile-property:first-child {
  padding-top: 0;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .mobile-property:last-child {
  padding-bottom: 0;
  border: none;
}
@media (max-width: 480px) {
  .company-details .key-contacts-wrap .key-contacts-table .contact-row.data:nth-child(2) {
    margin-top: 0;
  }
}
@media (min-width: 481px) {
  .company-details .key-contacts-wrap .key-contacts-table .contact-row.data :first-child {
    font-weight: 600;
  }
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .text-link {
  color: #348cf9;
  cursor: pointer;
}
.company-details .key-contacts-wrap .key-contacts-table .contact-row.data .text-link:hover {
  color: #0064df;
}
@media (min-width: 481px) {
  .company-details .key-contacts-wrap .key-contacts-table .contact-row :first-child {
    width: 29%;
  }
}
@media (min-width: 481px) {
  .company-details .key-contacts-wrap .key-contacts-table .contact-row :nth-child(2) {
    width: 29%;
  }
}
@media (min-width: 481px) {
  .company-details .key-contacts-wrap .key-contacts-table .contact-row :last-child {
    flex: 1;
  }
}
.company-details .similar-companies-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  opacity: 0;
  -webkit-animation: company-details-fade-in-element 0.5s forwards;
  -moz-animation: company-details-fade-in-element 0.5s forwards;
  -o-animation: company-details-fade-in-element 0.5s forwards;
  animation: company-details-fade-in-element 0.5s forwards;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap {
    margin-top: 0;
  }
}
.company-details .similar-companies-wrap .no-results {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .error-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout {
  position: relative;
}
@media (min-width: 481px) {
  .company-details .similar-companies-wrap .similar-listing-layout {
    display: flex;
    gap: 20px;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results {
  flex-grow: 1;
  position: relative;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .results-header {
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 16px;
  padding-left: 32px;
  border-bottom: 1px solid #348cf9;
  justify-content: space-between;
  gap: 16px;
  background-color: #ffffff;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .results-header {
    display: none;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .results-header .cols {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  line-height: 14px;
  gap: 8px;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .results-header .cols .col {
  width: 20%;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .results-header .cols .col.right-align {
  text-align: end;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .results-header .cols .col.company-name {
  min-width: 104px;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .results-header .actions {
  min-width: 200px;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item {
  border-radius: 8px;
  margin-top: 16px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 16px;
  background-color: #ffffff;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item.no-shadow {
  box-shadow: none;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item {
    flex-direction: column;
    padding: 24px;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item .cols {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .cols {
    flex-direction: column;
  }
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .cols .col {
    align-items: center;
  }
}
@media (min-width: 481px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .cols .col {
    width: 20%;
  }
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .cols .col.company-name {
    min-width: 104px;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 168px;
  gap: 16px;
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item .actions.default-search-actions {
  min-width: 171px;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .actions .col {
    padding: 0;
    width: fit-content;
  }
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .actions .col.view-details-btn {
    flex-grow: 1;
    flex: 1;
  }
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .actions .col.view-details-btn .new-icon-button-wrap {
    width: 100%;
  }
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .actions .col.view-details-btn .new-icon-button-wrap .new-icon-button {
    width: 100%;
    justify-content: center;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .col.no-mobile {
    display: none;
  }
}
@media (min-width: 481px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .col.no-desktop {
    display: none;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item .col.mobile-badge {
  padding: 0;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .col {
    width: 100%;
    border-bottom: 0.5px solid #adb5bd;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
  }
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .col.no-border {
    border-bottom: 0;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item .col .btn {
  padding: 10px;
}
@media (min-width: 481px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .label {
    display: none;
  }
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .label {
    font-weight: 500;
    color: #000000;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item .value {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
@media (min-width: 481px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .value.right-align-desktop {
    justify-content: flex-end;
  }
}
.company-details .similar-companies-wrap .similar-listing-layout .results .item .value .year {
  font-size: 10px;
  line-height: 10px;
  margin-top: -2px;
}
@media (max-width: 480px) {
  .company-details .similar-companies-wrap .similar-listing-layout .results .item .value {
    width: 50%;
  }
}
.company-details .product-portfolio-updates-wrap {
  margin-top: 20px;
  opacity: 0;
  -webkit-animation: company-details-fade-in-element 0.5s forwards;
  -moz-animation: company-details-fade-in-element 0.5s forwards;
  -o-animation: company-details-fade-in-element 0.5s forwards;
  animation: company-details-fade-in-element 0.5s forwards;
}
.company-details .product-portfolio-updates-wrap .news-container {
  background-color: #ffffff;
  padding: 26px 32px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 8px;
}
.company-details .product-portfolio-updates-wrap .news-container .news-main-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #000000;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card {
  background-color: #f4f5f7;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  column-gap: 48px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #f4f5f7;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card:hover {
  border: 1px solid #348cf9;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .news-info {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .news-info .domain-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .news-info .domain-wrap .domain-logo {
  height: 14px;
  width: auto;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .news-info .domain-wrap .domain-text {
  color: #000000;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .news-info .news-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .news-info .news-date {
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  color: #000000;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .image-wrap {
  width: 103px;
  height: 78px;
  flex-shrink: 0;
}
.company-details .product-portfolio-updates-wrap .news-container .news-card .image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.company-details-node-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  padding: 4px;
  transform: rotateX(-180deg) translateX(-50%) translateY(10%);
  cursor: grab;
}
.company-details-node-wrap .name {
  color: #000000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  max-width: 150px;
  text-align: center;
  overflow-wrap: break-word;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  padding: 4px;
}
.company-details-node-wrap .bottom-wrap {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 59px;
}
.company-details-node-wrap .icon-wrap {
  margin-top: 4px;
  width: 28px;
  height: 28px;
  border: 1px solid #000000;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.company-details-node-wrap .icon-wrap img {
  width: 16px;
  height: 16px;
}
.company-details-node-wrap .icon-wrap .beneficial-count-wrap {
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #348cf9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  left: 0;
  transform: translateX(-50%);
  padding-left: 2px;
  padding-right: 2px;
}
.company-details-node-wrap .icon-wrap .beneficial-count-wrap .beneficial-count {
  font-size: 8px;
  line-height: 8px;
  font-weight: 400;
  color: #ffffff;
}
.company-details-node-wrap .share-wrap {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
}
.company-details-node-wrap .share-wrap img {
  width: 10px;
  height: 10px;
}
.company-details-node-wrap .share-wrap .ownership {
  color: #000000;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
}
.shareholder-structure-watchlist-popup-closer {
  position: fixed;
  top: -200px;
  right: -200px;
  bottom: -200px;
  left: -200px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  opacity: 0;
  pointer-events: none;
}
.shareholder-structure-watchlist-popup-closer.visible {
  opacity: 1;
  pointer-events: all;
}
.shareholder-structure-watchlist-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  z-index: 4;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  width: calc(100dvw - 48px);
  height: calc(100dvh - 48px);
  padding: 24px;
  opacity: 0;
  pointer-events: none;
}
.shareholder-structure-watchlist-popup.visible {
  opacity: 1;
  pointer-events: all;
}
.shareholder-structure-watchlist-popup .sswp-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.shareholder-structure-watchlist-popup .sswp-header .sswp-title {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #000000;
}
.shareholder-structure-watchlist-popup .sswp-header #sswp-close .new-icon-button .img-wrap {
  width: 9px;
  height: 9px;
  margin-top: 2px;
}
.shareholder-structure-watchlist-popup .sswp-header #sswp-close .new-icon-button .img-wrap img {
  width: 9px;
  height: 9px;
}
.shareholder-structure-watchlist-popup .content-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: calc(100% - 28px - 8px - 24px);
}
.shareholder-structure-watchlist-popup .content-container .legend {
  background-color: #f4f5f7;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 24px 8px;
  display: flex;
  width: 220px;
  flex-direction: column;
  column-gap: 4px;
  overflow-y: auto;
}
.shareholder-structure-watchlist-popup .content-container .legend::-webkit-scrollbar {
  width: 4px;
  background-color: #f4f5f7;
  border-radius: 8px;
}
.shareholder-structure-watchlist-popup .content-container .legend::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 9px;
}
.shareholder-structure-watchlist-popup .content-container .legend::-webkit-scrollbar-thumb:hover {
  background-color: #7b7c7c;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-radius: 8px;
  padding: 8px;
  margin-top: 24px;
  border: 1px solid #dee2e6;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap input[type='text'] {
  background-color: #ffffff;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap label[for='shareholder_share_percent'] {
  padding-left: 16px;
  display: inline-block;
  text-indent: 0;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap div.form-group:nth-child(2) label {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: flex-start;
  cursor: default;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap div.form-group:nth-child(2) label input[type='checkbox'] {
  margin-top: 6px;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap .filter-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  column-gap: 4px;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap .filter-actions .new-icon-button-wrap {
  width: 100%;
}
.shareholder-structure-watchlist-popup .content-container .legend .filters-wrap .filter-actions .new-icon-button-wrap .new-icon-button {
  width: 100%;
  justify-content: center;
}
.shareholder-structure-watchlist-popup .content-container .legend .legend-row {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  align-items: center;
  justify-content: flex-start;
  color: #000000;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  padding: 8px;
}
@media (max-width: 480px) {
  .shareholder-structure-watchlist-popup .content-container .legend .legend-row {
    font-size: 12px;
    line-height: 16px;
  }
}
.shareholder-structure-watchlist-popup .content-container .legend .legend-row .icon-wrap {
  width: 16px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.shareholder-structure-watchlist-popup .content-container .legend .legend-row .icon-wrap.beneficial {
  background-color: #348cf9;
  border-radius: 8px;
}
.shareholder-structure-watchlist-popup .content-container .legend .legend-row .icon-wrap.beneficial img {
  filter: invert(100%) sepia(0%) saturate(7487%) hue-rotate(49deg) brightness(100%) contrast(106%);
  width: 10px;
  height: 10px;
}
.shareholder-structure-watchlist-popup .content-container .legend .legend-row .icon-wrap img {
  width: 16px;
  height: 16px;
}
.shareholder-structure-watchlist-popup .content-container .treeWrapper {
  position: relative;
}
@media (min-width: 481px) {
  .shareholder-structure-watchlist-popup .content-container .treeWrapper {
    flex: 1;
    overflow: hidden;
    height: 100%;
  }
}
@media (max-width: 480px) {
  .shareholder-structure-watchlist-popup .content-container .treeWrapper {
    flex-shrink: 0;
    flex-grow: 1;
    height: calc(100vh - 80px - 136px - 16px) !important;
    margin-left: -24px;
    margin-right: -24px;
  }
}
.shareholder-structure-watchlist-popup .content-container .treeWrapper #restore-graph-btn-popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.add-remove-watchlist-popup-closer {
  position: fixed;
  top: -200px;
  right: -200px;
  bottom: -200px;
  left: -200px;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}
.add-remove-watchlist-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 60px 32px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  z-index: 4;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  max-width: 551px;
}
@media (max-width: 480px) {
  .add-remove-watchlist-popup {
    width: calc(100dvw - 24px - 24px);
    padding-bottom: 24px;
  }
}
.add-remove-watchlist-popup .title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  color: #000000;
  text-align: center;
}
@media (max-width: 480px) {
  .add-remove-watchlist-popup .title {
    margin-bottom: 24px;
  }
}
.add-remove-watchlist-popup .buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 32px;
  justify-content: space-evenly;
}
@media (max-width: 480px) {
  .add-remove-watchlist-popup .buttons-container {
    flex-direction: column;
    justify-content: stretch;
    row-gap: 24px;
  }
}
.add-remove-watchlist-popup .buttons-container .new-icon-button-wrap {
  width: 100%;
}
.add-remove-watchlist-popup .buttons-container .new-icon-button-wrap .new-icon-button {
  width: 100%;
  justify-content: center;
  padding: 10px 16px;
}
.add-remove-watchlist-popup .buttons-container .new-icon-button-wrap .new-icon-button .inner-text {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}
.force-graph-label {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.force-graph-label .icon-container {
  width: 10px;
  height: 10px;
  position: relative;
}
.force-graph-label .icon-container svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.force-graph-label .label-text {
  color: #000000;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}
